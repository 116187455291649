// Lib
import { isUndefined } from 'lodash';

// Services
import localStorageService from '../utils/services/localStorage/localStorage';

// Constants
import {
    DEBUG_DISABLE_CACHE_LOCAL_STORAGE_KEY,
    DEBUG_DISABLE_LOCAL_CACHE_RESET_LOCAL_STORAGE_KEY,
    DEBUG_LOCAL_STORAGE_KEY,
    DebugComponent,
} from './debugConstants';

export const shouldDisableCache = (): boolean =>
    localStorageService.getItem(DEBUG_DISABLE_CACHE_LOCAL_STORAGE_KEY) === 'true';

export const shouldDisableLocalCacheReset = (): boolean =>
    localStorageService.getItem(DEBUG_DISABLE_LOCAL_CACHE_RESET_LOCAL_STORAGE_KEY) === 'true';

/**
 * Gets a specific debug setting from local storage.
 */
const getLocalStorageDebugSetting = (component: string): number | string | boolean | undefined => {
    const componentKey =
        component === DebugComponent.GLOBAL
            ? // Global will use the root "milanote.debug" key
              DEBUG_LOCAL_STORAGE_KEY
            : `${DEBUG_LOCAL_STORAGE_KEY}.${component}`;

    const localStorageItem = localStorageService.getItem(componentKey);

    // Intentional use of == to check for null and undefined
    if (localStorageItem == null) return undefined;

    if (localStorageItem === 'true') return true;
    if (localStorageItem === 'false') return false;

    const debugLevel = parseInt(localStorageItem);

    if (!isNaN(debugLevel)) return debugLevel;

    return localStorageItem;
};

type DebugSettings = Record<string, number | string | boolean>;

let debugSettings: DebugSettings = {};

export const setDebugSettings = (newDebugSettings: DebugSettings): void => {
    debugSettings = newDebugSettings;
    if (debugSettings.global) console.info(`[debug] Set debug settings:`, debugSettings);
};

/**
 * Sets the debug setting for a specific component.
 */
export const setDebugValue = (component = DebugComponent.GLOBAL, value: string | number | boolean): void => {
    debugSettings[component] = value;
    if (debugSettings.global) console.info(`[debug] Settings updated:`, debugSettings);
};

/**
 * Toggles the debug setting for a specific component.
 */
export const enableDebug = (component = DebugComponent.GLOBAL): void => setDebugValue(component, true);

/**
 * Determines if the top-level debug setting is enabled.
 */
export const isGlobalDebugEnabled = (): boolean => !!debugSettings.global;

export const getDebugValue = (component: string): number | string | boolean => {
    const parts = component.split('.');

    for (let i = parts.length - 1; i >= 0; i--) {
        const part = parts.slice(0, i + 1).join('.');

        const specificDebug = debugSettings[part];

        if (!isUndefined(specificDebug)) return specificDebug;
    }

    return isGlobalDebugEnabled();
};

/**
 * Determines whether this debug component is explicitly enabled, and won't
 * use any higher-level debug settings to determine its value.
 */
export const isDebugEnabledExplicitly = (component: DebugComponent): boolean => !!debugSettings[component];

/**
 * Determines if debug is enabled for a specific component.
 * This will check the specific component first, and then ancestor settings until reaching global.
 */
export const isDebugEnabled = (component: string = DebugComponent.GLOBAL): boolean => !!getDebugValue(component);

export const getDebugSettings = (): DebugSettings => debugSettings;

/**
 * Gets the global debug setting from local storage and defaults to true if not
 * set and in development mode.
 */
const getGlobalDebugFromLocalStorage = (): boolean => {
    const globalDebug = getLocalStorageDebugSetting(DebugComponent.GLOBAL);

    if (!isUndefined(globalDebug)) return globalDebug as boolean;

    // In development mode, default to true
    return process.env.APPLICATION_MODE === 'development';
};

const DEBUG_SUB_COMPONENT_KEY_PREFIX = `${DEBUG_LOCAL_STORAGE_KEY}.`;

/**
 * Retrieves the debug settings from local storage.
 */
export const getDebugSettingsFromLocalStorage = (): DebugSettings => {
    const debugSettings: DebugSettings = {
        global: getGlobalDebugFromLocalStorage(),
    };

    const localStorageKeys = Object.keys(localStorageService);

    for (const key of localStorageKeys) {
        if (!key.startsWith(DEBUG_SUB_COMPONENT_KEY_PREFIX)) continue;

        const debugKey = key.replace(DEBUG_SUB_COMPONENT_KEY_PREFIX, '');

        const value = getLocalStorageDebugSetting(debugKey);

        if (!isUndefined(value)) {
            debugSettings[debugKey] = value;
        }
    }

    return debugSettings;
};

export const initialiseDebugSettingsFromLocalStorage = (): void => {
    const newDebugSettings = getDebugSettingsFromLocalStorage();
    setDebugSettings(newDebugSettings);
};
