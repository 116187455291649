import { SheetId, SheetReducerState, SheetState } from './sheetTypes';

export const getSheetKey = (sheetId: SheetId, instanceId?: string) =>
    instanceId ? `${sheetId}-${instanceId}` : sheetId;

export const getSheetsState = (state: unknown): SheetReducerState =>
    (state as Immutable.Map<string, object>).getIn(['app', 'sheet']) as SheetReducerState;

export const getSheetState =
    (sheetId: SheetId, instanceId?: string) =>
    (state: unknown): SheetReducerState[SheetId] => {
        const sheetKey = getSheetKey(sheetId, instanceId);
        return getSheetsState(state)[sheetKey];
    };

export const getIsSheetOpen =
    (sheetId: SheetId, instanceId?: string) =>
    (state: unknown): boolean => {
        const sheetState = getSheetState(sheetId, instanceId)(state);
        return sheetState?.active ?? false;
    };

export const getIsAnySheetOpen = (state: unknown): boolean => {
    const sheetState = getSheetsState(state);
    return Object.values(sheetState).some((sheet) => sheet?.active);
};

/*
 * Returns true if any open sheet matches the provided filter
 */
export const getAnyMatchingSheetOpen =
    (filter: (sheetState: SheetState) => boolean) =>
    (state: unknown): boolean => {
        const sheetState = getSheetsState(state);
        return Object.values(sheetState).some((sheetState: SheetState) => sheetState?.active && filter(sheetState));
    };

export const getSheetActiveSnapPoint =
    (sheetId: SheetId, instanceId?: string) =>
    (state: unknown): number => {
        const sheetState = getSheetState(sheetId, instanceId)(state);
        return sheetState?.activeSnapPoint ?? 0;
    };
