// Lib
import { Cancel } from 'axios';

// Constants
import {
    ELEMENT_ATTACHMENT_ACTION_TYPES,
    ELEMENT_ATTACHMENT_PROGRESS_RESET,
    ELEMENT_ATTACHMENT_UPLOAD_PROGRESSED,
    ELEMENT_ATTACHMENT_UPLOAD_COMPLETED,
    ELEMENT_ATTACHMENT_DOWNLOAD_PROGRESSED,
    ELEMENT_ATTACHMENT_UPLOADING,
    ELEMENT_ATTACHMENT_DOWNLOADING,
    ELEMENT_ATTACHMENT_DATA_READY,
    ELEMENT_ATTACHMENT_CANCEL_FN_SET,
    ELEMENT_ATTACHMENT_CLEAR_ERROR,
    ELEMENT_ATTACHMENT_ACCEPT_UNDO,
    ELEMENT_ATTACHMENT_DOWNLOAD_COMPLETED,
    ELEMENT_ATTACHMENT_CLEAN,
    ELEMENT_ATTACHMENT_UPLOAD_ERROR,
    ELEMENT_ATTACHMENT_CLEAR_ALL_ERRORS,
} from '../../../common/elements/elementConstants';

// TODO Review where these files are located
import imageFileLoader from '../../utils/services/image/imageFileLoader';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import getFileType from '../../../common/files/getFileType';
import { sanitiseFilename } from '../../../common/files/filenameUtils';
import { isGuestSelector, isUserSubscribed, getCurrentUser } from '../../user/currentUserSelector';
import { getTimestamp } from '../../../common/utils/timeUtil';
import { isFile, isImage } from '../../../common/elements/utils/elementTypeUtils';
import {
    inferIsFileCompatibleWithImageElement,
    isFileCompatibleWithImageElement,
    validateFile,
    validateImageFile,
} from '../../../common/files/fileValidator';
import { uploadFileToS3 } from './attachmentsService';
import { elementCountSelector } from '../../user/elementCount/elementCountSelector';
import { openErrorNotice } from '../../components/error/errorNoticeActions';
import { getPermission } from '../../../common/permissions/elementPermissionsUtil';
import { validateElementTypeSavePermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { getAclIdsSelector } from '../../utils/permissions/permissionsSelector';
import { isPreviewableFile } from '../../../common/files/filePreviewUtils';

// Actions
import { updateElement, setElementTypeAndUpdateElement } from '../actions/elementActions';

// Constants
import { IMAGE_TYPES } from '../../../common/media/mediaConstants';
import { ACCEPTED_FILE_TYPES, MAX_FREE_FILES } from '../../../common/files/fileConstants';
import { ATTACHMENT_ERROR } from '../../../common/error/errorConstants';
import { manuallyReportError } from '../../analytics/rollbarService';
import logger from '../../logger/logger';
import { ElementType } from '../../../common/elements/elementTypes';

// Uploading attachments
export const uploadingElementAttachment = ({ id, actionType = ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD }) => ({
    type:
        actionType === ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD
            ? ELEMENT_ATTACHMENT_UPLOADING
            : ELEMENT_ATTACHMENT_DOWNLOADING,
    id,
    sync: false,
    actionType,
});
export const readElementAttachment = ({ id, attachmentData }) => ({
    type: ELEMENT_ATTACHMENT_DATA_READY,
    id,
    attachmentData,
    sync: false,
});
export const elementAttachmentCancelSet = ({ id, cancelFn }) => ({
    type: ELEMENT_ATTACHMENT_CANCEL_FN_SET,
    id,
    cancelFn,
});
export const progressUploadingElementAttachment = ({
    id,
    percentageComplete,
    loaded,
    total,
    actionType = ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD,
}) => ({
    type:
        actionType === ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD
            ? ELEMENT_ATTACHMENT_UPLOAD_PROGRESSED
            : ELEMENT_ATTACHMENT_DOWNLOAD_PROGRESSED,
    id,
    percentageComplete,
    loaded,
    total,
    sync: false,
    actionType,
});
export const resetProgressElementAttachment = ({ id, actionType = ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD }) => ({
    type: ELEMENT_ATTACHMENT_PROGRESS_RESET,
    id,
    percentageComplete: 0,
    loaded: 0,
    total: 0,
    sync: false,
    actionType,
});
export const completedUploadingElementAttachment = ({ id, actionType = ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD }) => ({
    type:
        actionType === ELEMENT_ATTACHMENT_ACTION_TYPES.UPLOAD
            ? ELEMENT_ATTACHMENT_UPLOAD_COMPLETED
            : ELEMENT_ATTACHMENT_DOWNLOAD_COMPLETED,
    id,
    sync: false,
    actionType,
});
export const cleanElementAttachment = ({ id }) => ({
    type: ELEMENT_ATTACHMENT_CLEAN,
    id,
    sync: false,
});
export const errorUploadingElementAttachment = ({ id, error }) => ({
    type: ELEMENT_ATTACHMENT_UPLOAD_ERROR,
    id,
    error,
    sync: false,
});
export const clearElementAttachmentError = ({ id }) => ({
    type: ELEMENT_ATTACHMENT_CLEAR_ERROR,
    id,
    sync: false,
});
export const clearAllElementAttachmentErrors = () => ({
    type: ELEMENT_ATTACHMENT_CLEAR_ALL_ERRORS,
    sync: false,
});

// Undo
export const acceptElementAttachmentUndo = ({ id, transactionId = getNewTransactionId() }) => ({
    type: ELEMENT_ATTACHMENT_ACCEPT_UNDO,
    id,
    transactionId,
    sync: false,
});

const getFileDetails = (file) => {
    const fileTypeDetails = getFileType(file);
    return {
        ...fileTypeDetails,
        filename: sanitiseFilename(file.name),
        size: file.size,
        lastModified: file.lastModified,
    };
};

const getElements = (state) => state.get('elements');
const getElement = (state, { elementId }) => state.getIn(['elements', elementId]);

// This is shared by the element & user avatar image upload logic
export const commonImageUpload =
    ({ preUploadCb, attachmentSuffix = '' }) =>
    ({ id, file, transactionId = getNewTransactionId(), imageType, attachmentData, onlyReplaceImage }) =>
    async (dispatch, getState) => {
        const state = getState();
        const isGuest = isGuestSelector(state);
        const userIsSubscribed = isUserSubscribed(state);

        const validationError = await validateImageFile(file, { isSubscribed: userIsSubscribed });
        if (validationError) {
            dispatch(
                openErrorNotice({
                    errorNoticeId: ATTACHMENT_ERROR.GENERAL_ERROR,
                    data: {
                        validationError,
                        attachmentErrors: [validationError.message],
                    },
                }),
            );
            dispatch(cleanElementAttachment({ id: `${id}${attachmentSuffix}` }));
            throw validationError;
        }

        // First notify the application that a file upload is in progress
        if (!isGuest) dispatch(uploadingElementAttachment({ id: `${id}${attachmentSuffix}` }));

        // Handles orientations of JPEGs if necessary
        // If attachmentData is provided, it can skip the client side image processing as it's already been done
        const imageData = attachmentData || (await imageFileLoader(file));

        const { imageDataUri, size } = imageData;
        const imageFile = imageData.file;

        const fileDetails = {
            ...getFileDetails(file),
            ...size,
            dataUri: imageDataUri,
        };

        // FIXME Might need to set the transparency here
        // Read the file so we can immediately show the image to the user
        dispatch(readElementAttachment({ id: `${id}${attachmentSuffix}`, attachmentData: fileDetails }));

        // If the user is guest, don't upload the image
        let isAbleToUpload = !isGuest;

        // Must ensure the user has permission to upload an image on a board if they attempt to
        if (isAbleToUpload && imageType !== IMAGE_TYPES.AVATAR) {
            const elements = getElements(state);
            const element = getElement(state, { elementId: id });

            const aclIds = getAclIdsSelector(state);
            const userPermissions = getPermission(elements, id, aclIds);

            isAbleToUpload = validateElementTypeSavePermission(userPermissions, element);
        }

        // Stop the uploading indicator and don't begin the upload
        if (!isAbleToUpload) {
            dispatch(completedUploadingElementAttachment({ id: `${id}${attachmentSuffix}` }));
            return;
        }

        const type = imageType || IMAGE_TYPES.ELEMENT;

        const onUploadProgress = (progressEvent) =>
            dispatch(
                progressUploadingElementAttachment({
                    id: `${id}${attachmentSuffix}`,
                    loaded: progressEvent.loaded,
                    total: progressEvent.total,
                    percentageComplete: (progressEvent.loaded / progressEvent.total) * 100,
                }),
            );

        preUploadCb &&
            preUploadCb({ id, file, transactionId, imageType, imageDataUri, size, onlyReplaceImage })(
                dispatch,
                getState,
            );

        const cancelExecutorFn = (cancelFn) => {
            dispatch(elementAttachmentCancelSet({ id, cancelFn }));
        };

        try {
            const uploadUrl = await uploadFileToS3({
                file: imageFile,
                id,
                onUploadProgress,
                cancelExecutorFn,
                fileType: ACCEPTED_FILE_TYPES.IMAGE,
                imageType: type,
                user: getCurrentUser(state),
            });

            dispatch(completedUploadingElementAttachment({ id: `${id}${attachmentSuffix}` }));
            return { ...imageData, uploadUrl };
        } catch (err) {
            if (err instanceof Cancel) {
                console.warn('Upload cancelled');
                return dispatch(cleanElementAttachment({ id: `${id}${attachmentSuffix}` }));
            }

            logger.error('An error occurred while uploading the image', err, err.response);
            manuallyReportError({ errorMessage: 'An error occurred while uploading the image', error: err, getState });

            dispatch(
                openErrorNotice({
                    errorNoticeId: ATTACHMENT_ERROR.GENERAL_ERROR,
                    data: {
                        attachmentErrors: ['The image failed to upload'],
                    },
                }),
            );
            dispatch(cleanElementAttachment({ id: `${id}${attachmentSuffix}` }));

            throw err;
        }
    };

const changeToImageIfFile =
    ({ id, onlyReplaceImage }) =>
    (dispatch, getState) => {
        const state = getState();
        const element = getElement(state, { elementId: id });

        // If element type is file or not an image replacement - set type to image
        if (isFile(element) && !onlyReplaceImage)
            dispatch(setElementTypeAndUpdateElement({ id, elementType: ElementType.IMAGE_TYPE }));
    };

const commonImageElementUpload = commonImageUpload({ preUploadCb: changeToImageIfFile });

export const uploadImage =
    ({ id, file, transactionId = getNewTransactionId(), imageType, attachmentData, options = {} }) =>
    async (dispatch) => {
        try {
            const { onlyReplaceImage = false } = options;

            const result = await dispatch(
                commonImageElementUpload({
                    id,
                    file,
                    transactionId,
                    imageType,
                    attachmentData,
                    onlyReplaceImage,
                }),
            );

            if (!result) return;

            const { uploadUrl, size, transparent = false } = result;

            const changes = {
                image: {
                    ...size,
                    original: uploadUrl,
                    // Need to initialise transparency to true otherwise transparent images will
                    // have a weird flash of white
                    transparent,
                },
            };

            if (!onlyReplaceImage) {
                changes.file = getFileDetails(file);
            }

            dispatch(updateElement({ id, changes, transactionId }));
        } catch (err) {
            // The error is already handled in the common image upload
        }
    };

export const commonFileValidation =
    ({ id, file, beforeCreate }) =>
    (dispatch, getState) => {
        const state = getState();
        const isGuest = isGuestSelector(state);

        if (isGuest) {
            const message = 'Sorry, you cannot upload files as guest';
            console.error(message);

            dispatch(
                openErrorNotice({
                    errorNoticeId: ATTACHMENT_ERROR.GENERAL_ERROR,
                    data: {
                        attachmentErrors: [message],
                    },
                }),
            );
            dispatch(cleanElementAttachment({ id }));

            return message;
        }

        const userIsSubscribed = isUserSubscribed(state);
        if (!userIsSubscribed) {
            // If we're validating the # of file elements before we've created this file element
            // then we need to add 1 to the file count as that's what it will be when we've finished
            const fileCount = (elementCountSelector(state).get('FILE') || 0) + (beforeCreate ? 1 : 0);

            if (fileCount > MAX_FREE_FILES) {
                const message = 'Sorry, you can only upload ten files as free user';
                console.error(message);

                dispatch(openErrorNotice({ errorNoticeId: ATTACHMENT_ERROR.FREE_FILE_COUNT_LIMIT }));

                dispatch(cleanElementAttachment({ id }));

                return message;
            }
        }

        const validationError = validateFile(file, { isSubscribed: userIsSubscribed });
        if (validationError) {
            dispatch(
                openErrorNotice({
                    errorNoticeId: ATTACHMENT_ERROR.GENERAL_ERROR,
                    data: {
                        validationError,
                        attachmentErrors: [validationError.message],
                    },
                }),
            );
            dispatch(cleanElementAttachment({ id }));

            return validationError;
        }
    };

export const uploadFile =
    ({ id, file, transactionId = getNewTransactionId(), options = {} }) =>
    (dispatch, getState) => {
        const state = getState();

        const validationError = dispatch(commonFileValidation({ id, file, beforeCreate: false }));
        if (validationError) return Promise.reject(validationError);

        // If element type is image - set type to file
        const element = getElement(state, { elementId: id });
        if (isImage(element)) dispatch(setElementTypeAndUpdateElement({ id, elementType: ElementType.FILE_TYPE }));

        const fileDetails = getFileDetails(file);

        // Mark the file as uploading
        dispatch(uploadingElementAttachment({ id }));
        // Show the file details immediately
        dispatch(readElementAttachment({ id, attachmentData: fileDetails }));

        // Must ensure the user has permission to upload an image on a board if they attempt to
        const elements = getElements(state);
        const aclIds = getAclIdsSelector(state);

        const userPermissions = getPermission(elements, id, aclIds);
        const isAbleToUpload = validateElementTypeSavePermission(userPermissions, element);

        if (!isAbleToUpload) {
            dispatch(completedUploadingElementAttachment({ id }));
            return;
        }

        const onUploadProgress = (progressEvent) =>
            dispatch(
                progressUploadingElementAttachment({
                    id,
                    loaded: progressEvent.loaded,
                    total: progressEvent.total,
                    percentageComplete: (progressEvent.loaded / progressEvent.total) * 100,
                }),
            );

        const cancelExecutorFn = (cancelFn) => {
            dispatch(elementAttachmentCancelSet({ id, cancelFn }));
        };

        // Uploads the file to S3 and returns the URL for rendering
        return uploadFileToS3({
            file,
            onUploadProgress,
            id,
            cancelExecutorFn,
            user: getCurrentUser(state),
        })
            .then((uploadUrl) => {
                dispatch(completedUploadingElementAttachment({ id }));

                const changes = {
                    file: {
                        ...fileDetails,
                        url: uploadUrl,
                        uploadedTimestamp: getTimestamp(),
                    },
                };

                // If it's not a previewable file then we allow the preview to be shown immediately
                const isPreviewable = isPreviewableFile(file.name);
                if (!isPreviewable) {
                    changes.previewReady = true;
                }

                dispatch(updateElement({ id, changes, transactionId, silent: true }));
            })
            .catch((err) => {
                if (err instanceof Cancel) {
                    console.warn('Upload cancelled');
                    return dispatch(cleanElementAttachment({ id }));
                }

                console.error('ERROR uploading file', err);
                dispatch(
                    openErrorNotice({
                        errorNoticeId: ATTACHMENT_ERROR.GENERAL_ERROR,
                        data: {
                            attachmentErrors: ['The file failed to upload'],
                        },
                    }),
                );
                dispatch(cleanElementAttachment({ id }));
            });
    };

export const uploadAttachment =
    ({ id, file, transactionId = getNewTransactionId(), attachmentData, options }) =>
    async (dispatch) => {
        // Figure out if the file is an image or normal file
        const isImageFile = await inferIsFileCompatibleWithImageElement(file);

        return isImageFile
            ? dispatch(uploadImage({ id, file, transactionId, attachmentData, options }))
            : dispatch(uploadFile({ id, file, transactionId, attachmentData, options }));
    };

const prepareElementImageAttachment =
    ({ file, id }) =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsSubscribed = isUserSubscribed(state);

        const validationError = await validateImageFile(file, { isSubscribed: userIsSubscribed });
        if (validationError) {
            dispatch(
                openErrorNotice({
                    errorNoticeId: ATTACHMENT_ERROR.GENERAL_ERROR,
                    data: {
                        validationError,
                        attachmentErrors: [validationError.message],
                    },
                }),
            );
            dispatch(cleanElementAttachment({ id }));
            throw validationError;
        }

        const imageData = await imageFileLoader(file);
        const { imageDataUri } = imageData;

        const fileDetails = {
            ...imageData,
            ...getFileDetails(file),
        };

        fileDetails.dataUri = imageDataUri;
        // No need to have the data URI stored twice, it might be quite large
        delete fileDetails.imageDataUri;

        // Read the file so we can immediately show the image to the user
        dispatch(readElementAttachment({ id, attachmentData: fileDetails }));

        return imageData;
    };

const prepareElementFileAttachment =
    ({ file, id }) =>
    async (dispatch) => {
        const validationError = dispatch(commonFileValidation({ id, file, beforeCreate: true }));
        if (validationError) throw validationError;

        const fileDetails = getFileDetails(file);
        dispatch(readElementAttachment({ id, attachmentData: fileDetails }));
    };

/**
 * Reads the element attachment to ensure data is ready before the
 * @param attachment
 */
export const prepareElementAttachment = ({ file, id }) =>
    isFileCompatibleWithImageElement(file)
        ? prepareElementImageAttachment({ file, id })
        : prepareElementFileAttachment({ file, id });
