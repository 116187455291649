// Lib
import * as Immutable from 'immutable';

// Constants
import {
    SocketConnectionStatus,
    SOCKET_CONNECT,
    SOCKET_DISCONNECT,
    CHANNELS_UPDATED,
    SOCKET_INTERRUPTION,
    SocketReconnectionMode,
} from './socketConstants';
import {
    HYBRID_APP_DID_BECOME_ACTIVE,
    HYBRID_APP_ENTER_FOREGROUND,
} from '../../hybridApp/store/hybridAppStoreConstants';
import { ELECTRON_APP_WAKE } from '../../../common/electron/electronConstants';
import {
    VISIBLE_CONNECTION_STATUS_CHANGED,
    VisibleConnectionStatus,
} from '../../offline/visibleConnectionStatus/visibleConnectionStatusTypes';

const initialState = Immutable.Map({
    status: SocketConnectionStatus.DISCONNECTED,
    visibleConnectionStatus: VisibleConnectionStatus.connecting,
    reconnectionMode: SocketReconnectionMode.APP_LOAD,
    channels: Immutable.List(),
    boardChannels: Immutable.List(),
});

const updateChannels = (state, action) =>
    state.withMutations((mutableState) =>
        mutableState
            .update('channels', (channels) =>
                channels
                    .filter((channelId) => action.left.indexOf(channelId) === -1)
                    .concat(Immutable.List(action.joined)),
            )
            .set('boardChannels', Immutable.List(action.boardChannels)),
    );

export default (state = initialState, action) => {
    switch (action.type) {
        case SOCKET_CONNECT:
            return state.merge({
                status: SocketConnectionStatus.CONNECTED,
                connectionTime: action.timestamp,
            });
        case SOCKET_INTERRUPTION:
            return state.merge({
                status: SocketConnectionStatus.INTERRUPTED,
                reconnectionMode: SocketReconnectionMode.CONNECTION_LOST,
                interruptionTime: action.timestamp,
            });
        case SOCKET_DISCONNECT:
            return state.merge({
                status: SocketConnectionStatus.DISCONNECTED,
                reconnectionMode: SocketReconnectionMode.CONNECTION_LOST,
                disconnectionTime: action.timestamp,
            });
        case CHANNELS_UPDATED:
            return updateChannels(state, action);
        case HYBRID_APP_DID_BECOME_ACTIVE:
        case HYBRID_APP_ENTER_FOREGROUND:
        case ELECTRON_APP_WAKE:
            return state.set('reconnectionMode', SocketReconnectionMode.APP_WAKE);
        case VISIBLE_CONNECTION_STATUS_CHANGED:
            return state.set('visibleConnectionStatus', action.status);
        default:
            return state;
    }
};
