export const CAPACITOR_MN_TOKEN_PLUGIN_NAME = 'MNToken';
export const CAPACITOR_APPLE_PENCIL_PLUGIN_NAME = 'ApplePencil';
export const CAPACITOR_EDIT_ACTION_PLUGIN_NAME = 'EditAction';
export const CAPACITOR_EXPORT_PLUGIN_NAME = 'Export';
export const CAPACITOR_RICH_TEXT_TOOLBAR_PLUGIN_NAME = 'RichTextToolbar';
export const CAPACITOR_SHARE_PLUGIN_NAME = 'SharePlugin';
export const CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_APPLE_PLUGIN_NAME = 'SignInWithAppleNativeApple';
export const CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_ANDROID_PLUGIN_NAME = 'SignInWithAppleNativeAndroid';
export const CAPACITOR_SIGN_IN_WITH_GOOGLE_PLUGIN_NAME = 'SignInWithGoogle';
export const CAPACITOR_INTERCOM_PLUGIN_NAME = 'Intercom';
export const CAPACITOR_IN_APP_PURCHASES_PLUGIN_NAME = 'InAppPurchases';
export const CAPACITOR_KEYBOARD_SHORTCUT_NAME = 'KeyboardShortcut';
export const CAPACITOR_IMAGE_PRESENTER_PLUGIN = 'ImagePresenter';
export const CAPACITOR_NOTIFICATIONS_PLUGIN = 'MNNotifications';
export const CAPACITOR_SAFE_AREA_PLUGIN = 'SafeArea';
export const CAPACITOR_NAVIGATION_PLUGIN = 'Navigation';
export const CAPACITOR_TEST = 'ListenerPluginTest';
export const CAPACITOR_LIFECYCLE_PLUGIN = 'Lifecycle';
export const CAPACITOR_ELEMENT_ACTIONS_PLUGIN = 'ElementActions';
export const CAPACITOR_AMPLITUDE_PLUGIN = 'Amplitude';
export const CAPACITOR_MN_KEYBOARD_PLUGIN = 'MNKeyboard';
export const CAPACITOR_WEB_CRASH_REPORT_PLUGIN = 'WebCrashReport';
