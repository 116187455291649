import { useEffect, useState } from 'react';
import elementModalSelector from '../elementModalSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaceLoadIdleTimestamp } from '../../../app/initialisation/initialisationSelector';
import { addSelectedElements } from '../../selection/selectionActions';

const useElementModalSelectionSynchroniser = (props: ReturnType<typeof elementModalSelector>) => {
    const { elementId, isElementSelected, isElementFetched, socketConnected } = props;
    const dispatch = useDispatch();

    const workspaceLoadIdleTime = useSelector(getWorkspaceLoadIdleTimestamp);
    const [elementHasBeenSelected, setElementHasBeenSelected] = useState(false);

    useEffect(() => {
        const isPageReady = isElementFetched && socketConnected && workspaceLoadIdleTime;

        if (!elementHasBeenSelected && !isElementSelected && isPageReady) {
            dispatch(addSelectedElements({ ids: [elementId], rangeAnchors: undefined, transactionId: undefined }));
            setElementHasBeenSelected(true);
        }
    }, [
        isElementFetched,
        socketConnected,
        workspaceLoadIdleTime,
        isElementSelected,
        elementHasBeenSelected,
        elementId,
    ]);
};

export default useElementModalSelectionSynchroniser;
