import { isEmpty } from 'lodash';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { holdFocus } from './touchScreenFocusUtils';
import { animateScrollPromise } from '../../../utils/animation/animateScrollIntoView';
import { translateDOMRectIntoScrollableParentCoordinates } from '../../../utils/dom/scrollableParentUtils';
import { isPlatformIos } from '../../../platform/utils/platformDetailsUtils';
import getParentScrollableSection from '../../../utils/dom/getParentScrollableSection';
import { prop } from '../../../../common/utils/immutableHelper';
import { applyScrollExcessPadding, animateScrollToResetScrollExcessPadding } from './scrollExcessUtils';
import { getContentDomElement, getTargetScrollToElement, ScrollFocus } from './scrollToElementUtils';
import { getSnapPointSheetHeight } from '../../structural/sheet/utils/sheetHeightUtils';
import { getElementType, getLocationParentId } from '../../../../common/elements/utils/elementPropertyUtils';
import { getElementEditingTools } from '../../../../common/elements/elementRegistry';
import { isTask } from '../../../../common/elements/utils/elementTypeUtils';
import virtualKeyboardMaxHeightSingleton from '../../../utils/keyboard/virtualKeyboardMaxHeightSingleton';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../platform/platformSelector';
import { getSelectedElementIds } from '../../../element/selection/selectedElementsSelector';
import { getCurrentlyEditingId } from '../../../element/selection/currentlyEditingSelector';
import { getSheetState } from '../../structural/sheet/sheetSelectors';
import { getElement } from '../../../element/selectors/elementSelector';

// Constants
import {
    ELEMENT_EDIT_COMPLETE,
    ELEMENT_EDIT_START,
    ELEMENTS_DESELECT_ALL,
    ELEMENTS_DESELECTED,
} from '../../../../common/elements/selectionConstants';
import {
    DOCUMENT_MODAL_EDITOR_KEY,
    DOCUMENT_MODAL_TITLE_EDITOR_KEY,
} from '../../../element/document/modal/documentModalConstants';

// Types
import { UnknownAction } from 'redux';
import { ReduxStore } from '../../../types/reduxTypes';
import { MilanoteApplicationMode } from '../../../../common/platform/platformTypes';
import { SheetActionTypes, SheetUpdateActiveSnapPointAction } from '../../structural/sheet/sheetActionTypes';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { MOBILE_TOOLBAR_HEIGHT, ToolbarLevel } from '../../toolbar/MobileToolbarConstants';

type ScrollToElementOptions = {
    scrollableParent: HTMLElement;
    elementRect: Rect;
    scrollFocus: ScrollFocus | undefined;

    // The height of the obstruction at the bottom of the scrollable parent (e.g. sheet, keyboard)
    bottomObstructionHeight: number;

    // Add scroll excess padding to the bottom of the scrollable parent to allow the bottom portion of it
    // to be accessible.
    scrollExcessPaddingBottomCSS: string;
};

export const PREVENT_SCROLL_TO_ELEMENT_EDITOR_KEYS = [DOCUMENT_MODAL_TITLE_EDITOR_KEY];

const getElementScrollInfo = (
    state: any,
    elementId: string,
): { scrollableParent: HTMLElement; elementRect: Rect } | undefined => {
    const element = getElement(state, { elementId });
    const scrollToElementId = isTask(element) ? getLocationParentId(element) : elementId;

    const domElement = getContentDomElement(scrollToElementId);
    if (!domElement) return;

    const scrollableParent = getParentScrollableSection(domElement);
    if (!scrollableParent) return;

    const elementRect = translateDOMRectIntoScrollableParentCoordinates(
        scrollableParent,
        domElement.getBoundingClientRect(),
    );

    return { scrollableParent, elementRect };
};

const getScrollToElementOptionsForAction = (state: any, action: UnknownAction): ScrollToElementOptions | undefined => {
    const applicationMode = getMilanoteApplicationModeSelector(state);
    if (applicationMode !== MilanoteApplicationMode.mobile) return;

    // Scroll to element when the user starts editing an element
    if (action.type === ELEMENT_EDIT_START) {
        const elementId = action.id as string;
        const elementScrollInfo = getElementScrollInfo(state, elementId);
        if (!elementScrollInfo) return;

        const element = getElement(state, { elementId });
        const elementType = getElementType(element);

        const editingTools = getElementEditingTools(elementType);
        const toolbarHeight = isEmpty(editingTools)
            ? MOBILE_TOOLBAR_HEIGHT[ToolbarLevel.PRIMARY]
            : MOBILE_TOOLBAR_HEIGHT[ToolbarLevel.SECONDARY];

        const scrollFocus = action.editorFocusClientCoords as ScrollFocus | undefined;

        // If editing on document modal, add an extra padding to the bottom of the scrollable parent, to account for
        // the toolbar, which is only shown during editing mode
        const scrollExcessPaddingBottomCSS =
            action.editorKey === DOCUMENT_MODAL_EDITOR_KEY
                ? `calc(var(--keyboard-height) + ${toolbarHeight}px)`
                : `calc(var(--keyboard-height) - var(--safe-area-bottom)`;

        return {
            ...elementScrollInfo,
            scrollFocus,
            scrollExcessPaddingBottomCSS,
            bottomObstructionHeight: toolbarHeight + virtualKeyboardMaxHeightSingleton.getVirtualKeyboardMaxHeight(),
        };
    }

    // Scroll to element when the use opens a sheet with the scrollToElementOnOpen option
    if (action.type === SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT) {
        const { sheetId, instanceId, activeSnapPoint } = action as SheetUpdateActiveSnapPointAction;

        const sheetState = getSheetState(sheetId, instanceId)(state);
        if (!sheetState || sheetState.activeSnapPoint !== 0 || !sheetState.config?.scrollToElementOnOpen) return;

        const selectedElementId = prop(0, getSelectedElementIds(state));
        const scrollElementInfo = getElementScrollInfo(state, selectedElementId);
        if (!scrollElementInfo) return;

        return {
            ...scrollElementInfo,
            scrollFocus: undefined,
            scrollExcessPaddingBottomCSS: `calc(var(--sheet-height) - var(--workspace-safe-area-bottom))`,
            bottomObstructionHeight: getSnapPointSheetHeight(activeSnapPoint),
        };
    }
};

export default (store: ReduxStore) => (next: Function) => async (action: UnknownAction) => {
    if (action.remote) return next(action);

    const state = store.getState();

    // For some elements, we are using a different kind of editor so scrolling to the element is not applicable
    if (PREVENT_SCROLL_TO_ELEMENT_EDITOR_KEYS.includes(action.editorKey as string)) return next(action);

    // We need the focus event to happen immediately on iOS otherwise the element won't become editable
    // We don't want to do it on Android otherwise the keyboard might flash up then disappear then show again
    if (action.type === ELEMENT_EDIT_START && isPlatformIos(platformSingleton)) {
        const inputType = (action.inputType as string) || 'text';

        holdFocus({ inputType, readOnly: !!action.preventVirtualKeyboard });
    }

    // When the user edits an element and keyboard/sheet shows up, we want to:
    // 1. Apply scroll excess padding to the bottom of the scrollable element to allow the
    //   bottom portion of the scrollable element to be accessible
    // 2. Scroll to the element
    const scrollToElementOptions = getScrollToElementOptionsForAction(state, action);
    if (scrollToElementOptions) {
        const { scrollableParent, elementRect, scrollFocus, scrollExcessPaddingBottomCSS, bottomObstructionHeight } =
            scrollToElementOptions;

        await applyScrollExcessPadding(scrollableParent, scrollExcessPaddingBottomCSS);

        const targetScroll = getTargetScrollToElement(
            scrollableParent,
            elementRect,
            scrollFocus,
            bottomObstructionHeight,
        );

        await animateScrollPromise({
            scrollableParent,
            fromScrollLeft: scrollableParent.scrollLeft,
            fromScrollTop: scrollableParent.scrollTop,
            toScrollLeft: targetScroll.left,
            toScrollTop: targetScroll.top,
            interpolationFactor: 0.2,
        });
    }

    // When the user finishes editing an element, we want to animate back to the position
    // that resets the scrollExcess back to 0
    if (
        action.type === ELEMENT_EDIT_COMPLETE ||
        action.type === ELEMENTS_DESELECTED ||
        action.type === ELEMENTS_DESELECT_ALL ||
        (action.type === SheetActionTypes.SHEET_CLOSE && !getCurrentlyEditingId(state))
    ) {
        animateScrollToResetScrollExcessPadding();
    }

    next(action);
};
