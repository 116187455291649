import { produce } from 'immer';
import { ErrorModalAction, ErrorModalActionTypes, ErrorModalReducerState } from './errorModalTypes';

const initialState: ErrorModalReducerState = {
    activeErrorNoticeId: undefined,
    data: undefined,
};

export default (state: ErrorModalReducerState = initialState, action: ErrorModalAction) => {
    switch (action.type) {
        case ErrorModalActionTypes.ERROR_MODAL_OPEN:
            return produce(state, (draft) => {
                draft.activeErrorNoticeId = action.modalId;
                draft.data = action.data;
            });
        case ErrorModalActionTypes.ERROR_MODAL_CLOSE:
            return initialState;
        default:
            return state;
    }
};
