// Lib
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { enableDebugTools, setDebugToolbarEnabled } from './debugActions';

// Utils
import { isGlobalDebugEnabled, isDebugEnabledExplicitly } from './debugUtil';
import { DebugComponent } from './debugConstants';

export const DebugManager = () => {
    const dispatch = useDispatch();
    const dispatchEnableDebug = () => dispatch(enableDebugTools());
    const dispatchEnableDebugToolbar = () => dispatch(setDebugToolbarEnabled(true));

    useEffect(() => {
        window.enableDebug = dispatchEnableDebug;
        window.enableDebugToolbar = dispatchEnableDebugToolbar;

        if (isGlobalDebugEnabled()) {
            console.info('[debug] Milanote debug tools enabled');
            dispatchEnableDebug();
        }

        if (isDebugEnabledExplicitly(DebugComponent.TOOLBAR)) dispatchEnableDebugToolbar();

        return () => {
            delete window.enableDebug;
            delete window.enableDebugToolbar;
        };
    });

    return null;
};
