// Lib
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ShareFileData, SharePlugin, ShareTextData } from '../../../capacitor_plugins/share';

// Actions
import {
    createCardElementForShareExtension,
    createFileElementForShareExtension,
} from '../actions/shareExtensionActions';

// Constants
import { CAPACITOR_SHARE_PLUGIN_NAME } from '../../../capacitor_plugins/pluginConstants';

export default function () {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!Capacitor.isPluginAvailable(CAPACITOR_SHARE_PLUGIN_NAME)) return;

        const setupListeners = async () => {
            await SharePlugin.addListener('shareText', async ({ text }: ShareTextData) => {
                dispatch(createCardElementForShareExtension(text));
            });

            await SharePlugin.addListener('shareFile', async ({ uri, mimeType, fileName }: ShareFileData) => {
                dispatch(createFileElementForShareExtension(uri, mimeType, fileName));
            });

            SharePlugin.listenerSetupComplete();
        };

        setupListeners();
    }, []);
}
