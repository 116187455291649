// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../../node_module_clones/recompose';

// Utils
import { getIsGuestProp, getUserEmail } from '../../../../common/users/utils/userPropertyUtils';

// Actions
import { navigateToLogin } from '../../../reducers/navigationActions';
import { logout } from '../../../auth/authActions';

// Components
import ModalActionsList from '../../../components/modal/ModalActionsList';

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.getIn(['app', 'currentUser']),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToLogin: (event) => {
        event.preventDefault();
        dispatch(navigateToLogin());
    },
    logout: (event) => {
        event.preventDefault();
        dispatch(logout());
    },
});

const ElementAccessDeniedModal = (props) => {
    const { currentUser } = props;

    const isGuest = getIsGuestProp(currentUser);

    const guestActions = [{ text: 'Log in', func: props.navigateToLogin }];
    const loggedInActions = [{ text: 'Log in as someone else', func: props.logout }];
    const actions = isGuest ? guestActions : loggedInActions;

    const loggedInMessage = isGuest ? null : (
        <p className="details secondary">
            You&apos;re currently logged in as:
            <br />
            <strong>{getUserEmail(currentUser)}</strong>
        </p>
    );

    return (
        <div className="ElementAccessDeniedModal">
            <h2>You don&apos;t have permission to view this element</h2>
            <ModalActionsList actions={actions} />
            {loggedInMessage}
        </div>
    );
};

ElementAccessDeniedModal.propTypes = {
    requestAccess: PropTypes.func,
    navigateToLogin: PropTypes.func,
    logout: PropTypes.func,
    currentUser: PropTypes.object,
    close: PropTypes.func,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ElementAccessDeniedModal);
