import {
    SOCKET_CONNECT,
    SOCKET_DISCONNECT,
    CHANNELS_UPDATED,
    SOCKET_INTERRUPTION,
    SOCKET_FORCE_CONNECT,
    SOCKET_FORCE_DISCONNECT,
} from './socketConstants';
import { getTimestamp } from '../../../common/utils/timeUtil';

export const socketConnect = (timestamp = getTimestamp()) => ({ type: SOCKET_CONNECT, timestamp });
export const socketInterruption = (timestamp = getTimestamp()) => ({ type: SOCKET_INTERRUPTION, timestamp });
export const socketDisconnect = (timestamp = getTimestamp()) => ({ type: SOCKET_DISCONNECT, timestamp });

export const socketForceConnect = () => ({ type: SOCKET_FORCE_CONNECT });
export const socketForceDisconnect = () => ({ type: SOCKET_FORCE_DISCONNECT });

export const channelsUpdated = ({ joined, left, boardChannels }) => ({
    type: CHANNELS_UPDATED,
    joined,
    left,
    boardChannels,
});
