import React from 'react';

import SheetContainer, { CloseButton, Content, Header, Overlay, Sheet } from '../../../mobile/structural/sheet';
import useSheet from '../../../mobile/structural/sheet/hooks/useSheet';
import { SheetId } from '../../../mobile/structural/sheet/sheetTypes';

import './ElementModalErrorSheet.scss';
import NotFoundElementModal from './NotFoundElementModal';
import ElementAccessDeniedModal from './ElementAccessDeniedModal';
import NoModalComponentElementModal from './NoModalComponentElementModal';
import PageContent from '../../../components/modal/PageContent';

type ElementErrorSheetProps = {
    close: (event: any) => void;
};

const ElementErrorSheet: React.FC<ElementErrorSheetProps> = ({ children, close }) => {
    const sheetProps = useSheet(SheetId.RouteModal);

    const handleClose = () => {
        close(null);
    };

    return (
        <SheetContainer
            {...sheetProps}
            className="ElementModalErrorSheet"
            openByDefault
            defaultSnapPoint={1}
            onCloseTransitionEnd={handleClose}
        >
            <Overlay />
            <Sheet>
                <Header>
                    <CloseButton>Close</CloseButton>
                </Header>
                <Content>
                    <PageContent className="sheet-content">{children}</PageContent>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export const NotFoundElementSheet: React.FC<{
    close: () => void;
}> = ({ close }) => (
    <ElementErrorSheet close={close}>
        <NotFoundElementModal />
    </ElementErrorSheet>
);

export const ElementAccessDeniedSheet: React.FC<{
    close: () => void;
}> = ({ close }) => (
    <ElementErrorSheet close={close}>
        <ElementAccessDeniedModal />
    </ElementErrorSheet>
);

export const NoModalComponentElementSheet: React.FC<{
    close: () => void;
}> = ({ close }) => (
    <ElementErrorSheet close={close}>
        <NoModalComponentElementModal />
    </ElementErrorSheet>
);

export default ElementErrorSheet;
