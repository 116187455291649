// Lib
import { RefObject, useEffect, useState } from 'react';
import { isNil } from 'lodash';

// Utils
import { initialiseSnapPoints } from '../utils/snapPointUtils';
import {
    getSheetHeightAdjustedForClose,
    setContentHeightToMax,
    setContentHeightToVisibleSpace,
    setSheetHeight,
    setSheetHeightVariable,
} from '../utils/sheetHeightUtils';
import runOnTransitionEndWithTimeout from '../../../../utils/dom/runOnTransitionEndWithTimeout';

// Types
import { SheetProps } from '../SheetContainer';

type SheetSnapPointState = {
    snapPointsState: number[];
    addSnapPoint: (snapPoint: number) => void;
};

/**
 * Take care of snapPoint and activeSnapPoint state, and expose functions to update it
 */
const useSheetSnapPointState = (
    sheetProps: SheetProps,
    snapPoints: number[],
    sheetRef: RefObject<HTMLDivElement>,
    sheetContentRef: RefObject<HTMLDivElement>,
    activeSnapPoint: number,
    preventDismiss: boolean,
): SheetSnapPointState => {
    const { sheetId } = sheetProps;

    const [snapPointsState, setSnapPointsState] = useState<number[]>(initialiseSnapPoints(snapPoints, preventDismiss));

    const addSnapPoint = (snapPoint: number) => {
        if (snapPointsState.includes(snapPoint)) return;

        setSnapPointsState([...snapPointsState, snapPoint].sort());
    };

    useEffect(() => {
        sheetRef.current?.classList.add('sheet-transition');

        const newSheetHeight = getSheetHeightAdjustedForClose(activeSnapPoint);
        const newHeight = setSheetHeight(sheetRef, newSheetHeight);

        if (isNil(newHeight)) {
            sheetRef.current?.classList.remove('sheet-transition');
            return;
        }

        sheetRef.current?.classList.toggle('sheet-covers-screen', newSheetHeight === window.innerHeight);
        setContentHeightToMax(sheetContentRef);
        setSheetHeightVariable(sheetRef, newHeight);

        const cancelRunCallback = runOnTransitionEndWithTimeout(sheetRef, () => {
            setContentHeightToVisibleSpace(sheetId, sheetContentRef);
            sheetRef.current?.classList.remove('sheet-transition');
        });

        return cancelRunCallback;
    }, [activeSnapPoint]);

    return { snapPointsState, addSnapPoint };
};

export default useSheetSnapPointState;
