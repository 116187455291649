import { values } from 'lodash/fp';
import { markAsSeen } from './userEducationUtil';

export const ONBOARDING_EDUCATION_CODES = {
    DESKTOP_ONBOARDING: 1 << 0,
    // NOTE: The 1 << 1, 1 << 2 and 1 << 3 entries used to be used for onboarding flows,
    //  but they've now been replaced with the user's "onboarding" property
    EXPLAIN_NOTE: 1 << 4,
    EXPLAIN_LINE: 1 << 5,
    EXPLAIN_LINK: 1 << 6,
    EXPLAIN_COLUMN: 1 << 7,
    EXPLAIN_BOARD: 1 << 8,
    EXPLAIN_DOUBLE_CLICK_CARD: 1 << 9,
    // LEGACY - the more tool is no longer a part of onboarding. I'm leaving the constant so we can
    //  easily see what it used to be used for in the past.
    EXPLAIN_MORE_TOOL: 1 << 10,
    EXPLAIN_COMMENT: 1 << 11,
    EXPLAIN_BOARD_SHARING: 1 << 12,
    EXPLAIN_TEMPLATE_CREATION_SUCCESS: 1 << 13,
    EXPLAIN_IMAGE_PLACEHOLDER: 1 << 14,
    EXPLAIN_TASK_LIST: 1 << 15,
    EXPLAIN_COMMENT_THREAD: 1 << 16,
    EXPLAIN_DOCUMENT: 1 << 17,
    EXPLAIN_AUDIO: 1 << 18,
    EXPLAIN_MAP: 1 << 19,
    EXPLAIN_VIDEO: 1 << 20,
    EXPLAIN_SKETCH: 1 << 21,
    EXPLAIN_SWATCH: 1 << 22,
    EXPLAIN_TITLE: 1 << 23,
    // LEGACY - the annotation tool is no longer a part of onboarding. I'm leaving the constant so we can
    //  easily see what it used to be used for in the past.
    EXPLAIN_ANNOTATION_TOOL: 1 << 24,

    EXPLAIN_BOARD_BREADCRUMBS: 1 << 25,
    // LEGACY - the zoom new feature announcement is no longer in use. I'm leaving the constant, so we can
    // easily see what it used to be used for in the past.
    ANNOUNCE_ZOOM: 1 << 26,
    EXPLAIN_TABLE: 1 << 27,
    ANNOUNCE_TABLE: 1 << 28,
};

export const DEFAULT_USER_ONBOARDING = 'default-onboarding';
export const DEFAULT_USER_INITIAL_EDUCATION = 0;

export const HAS_SEGMENT_USER_ONBOARDING = 'has-segment-onboarding';
export const HAS_SEGMENT_USER_INITIAL_EDUCATION = 0;

export const INVITED_USER_ONBOARDING = 'invited-onboarding';
export const INVITED_USER_INITIAL_EDUCATION = ONBOARDING_EDUCATION_CODES.EXPLAIN_COMMENT;

/**
 * Legacy "mobile" onboarding flow.
 * This is, confusingly, the *desktop* onboarding flow for users who registered via the legacy mobile app.
 * TODO: Remove this when the legacy mobile app has been deprecated.
 * https://linear.app/milanote/issue/WEB-12386/remove-mobile-legacy-onboarding-flow
 */
export const MOBILE_LEGACY_USER_ONBOARDING = 'mobile-onboarding';
export const MOBILE_LEGACY_USER_INITIAL_EDUCATION = 0;

export const MOBILE_USER_ONBOARDING = 'mm-onboarding';
export const MOBILE_USER_INITIAL_EDUCATION = 0;
export const MOBILE_INVITED_USER_ONBOARDING = 'mm-invited-onboarding';

export const ONBOARDING_EDUCATION_ALL = values(ONBOARDING_EDUCATION_CODES).reduce(markAsSeen, 0);
export const TEMPLATE_USER_INITIAL_EDUCATION = ONBOARDING_EDUCATION_CODES.EXPLAIN_MORE_TOOL;

export const PUBLIC_EDIT_REGISTRATION_ONBOARDING = 'public-edit-registration-onboarding';
export const PUBLISH_FEEDBACK_REGISTRATION_ONBOARDING = 'publish-feedback-registration-onboarding';
export const PUBLIC_BOARD_REGISTRATION_INITIAL_EDUCATION = ONBOARDING_EDUCATION_CODES.EXPLAIN_COMMENT;
