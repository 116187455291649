// Constants
import { ENABLE_DEBUG_TOOLS, DEBUG_TOOL_TOGGLE, DEBUG_TOOLBAR_SET_ENABLED, DebugComponent } from './debugConstants';

// Utils
import { enableDebug, setDebugValue } from './debugUtil';

export const enableDebugTools = () => {
    enableDebug();
    return {
        type: ENABLE_DEBUG_TOOLS,
    };
};

export const toggleDebugTool = ({ enabled, toolName }) => ({
    type: DEBUG_TOOL_TOGGLE,
    enabled,
    toolName,
});

export const setDebugToolbarEnabled = (isEnabled) => {
    isEnabled && enableDebug();
    setDebugValue(DebugComponent.TOOLBAR, isEnabled);

    return {
        type: DEBUG_TOOLBAR_SET_ENABLED,
        enabled: isEnabled,
    };
};
