import { RefObject } from 'react';
import { clamp } from 'lodash';
import { DragState } from '../hooks/useSheetHandlers';
import { getInterpolatedPosition } from '../../../../utils/animation/springAnimationUtils';
import { SHEET_CLOSED_TRANSFORM_POSITION, getSheetHeightAdjustedForClose, setSheetHeight } from './sheetHeightUtils';

const NUM_VELOCITIES_TO_AVERAGE = 4;
const MIN_VELOCITY = 0.1;

const STANDARD_MAX_SPEED = 4000;
const NEAR_TOP_MAX_SPEED = 500;

const START_STIFFNESS = 500;
const END_STIFFNESS = 200;
const DAMPING = 30;

const SHEET_DRAGGING_EXTRA_SPACE = 50;
const SLOW_ANIMATION_THRESHOLD = 50;

export const INITIAL_ANIMATION_STATE = {
    initialHeight: 0,
    height: 0,
    velocity: 0,
    finalHeight: 0,
    directionUp: false,
    completeAnimation: () => {},
};

export type AnimationStartValues = {
    initialHeight: number;
    height: number;
    velocity: number;
    finalHeight: number;
    directionUp: boolean;
    completeAnimation: () => void;
};

export const saveGraphData = (dragState: RefObject<DragState>) => (velocityPxMs: number) => {
    if (!dragState.current?.graphData) return;

    const data = {
        x: performance.now() - dragState.current.touchStartTimestamp,
        y: velocityPxMs,
    };

    dragState.current.isDragging
        ? dragState.current.graphData?.speedDataDragging.push(data)
        : dragState.current.graphData?.speedDataNotDragging.push(data);
};

export const getAverageOfLastValuesInArray = (array: number[], num: number): number | undefined => {
    const sliceLength = Math.min(array.length, num);
    const numbersToAverage = array.slice(-sliceLength);

    if (numbersToAverage.length < 1) return;

    return numbersToAverage.reduce((a, b) => a + b) / num;
};

export const getAnimationStartValues = (
    sheetRef: RefObject<HTMLDivElement>,
    dragState: RefObject<DragState>,
    newActiveSnapPoint: number,
    completeAnimation: () => void,
): AnimationStartValues => {
    if (!sheetRef.current || !dragState.current) return INITIAL_ANIMATION_STATE;

    const { velocities } = dragState.current;

    const initialHeight = dragState.current.latestSheetHeight;
    const finalHeight = getSheetHeightAdjustedForClose(newActiveSnapPoint);

    const directionUp = finalHeight > initialHeight;
    const avgVelocityPxMs = getAverageOfLastValuesInArray(velocities, NUM_VELOCITIES_TO_AVERAGE) || MIN_VELOCITY;
    const velocityPxS = avgVelocityPxMs * 1000;

    return {
        initialHeight,
        height: initialHeight,
        velocity: velocityPxS,
        finalHeight,
        directionUp,
        completeAnimation,
    };
};

const getStiffnessValue = (startValue: number, endValue: number, progress: number): number =>
    startValue + progress * (endValue - startValue);

export const animateSheet =
    (
        sheetRef: RefObject<HTMLDivElement>,
        animationState: RefObject<AnimationStartValues>,
        saveGraphData?: ((velocity: number) => void) | undefined,
    ) =>
    (delta: number) => {
        if (!sheetRef.current || !animationState.current) return;

        const { initialHeight, height, velocity, finalHeight, directionUp, completeAnimation } = animationState.current;

        if (height === finalHeight) {
            completeAnimation();
            return;
        }

        if (saveGraphData) saveGraphData(velocity / 1000);

        const totalDistance = Math.abs(finalHeight - initialHeight);
        const distanceTraveled = Math.abs(height - initialHeight);
        const progress = clamp(distanceTraveled / totalDistance, 0, 1);
        const stiffnessValue = getStiffnessValue(START_STIFFNESS, END_STIFFNESS, progress);

        const [nextHeight, nextVelocity] = getInterpolatedPosition(
            delta,
            height,
            velocity,
            finalHeight,
            stiffnessValue,
            DAMPING,
        );

        // Finish animation once within 1px of final height
        const nearFinalHeight = directionUp ? height >= finalHeight - 1 : height <= finalHeight + 1;
        const hasReachedBottom = nextHeight < SHEET_CLOSED_TRANSFORM_POSITION;
        const finishAnimation = nearFinalHeight || hasReachedBottom;
        const newHeight = finishAnimation ? finalHeight : nextHeight;

        const nearTop = newHeight > window.innerHeight - SLOW_ANIMATION_THRESHOLD;
        const maxSpeed = nearTop ? NEAR_TOP_MAX_SPEED : STANDARD_MAX_SPEED;

        animationState.current.velocity = clamp(nextVelocity, -maxSpeed, maxSpeed);
        animationState.current.height = newHeight;

        // To avoid showing a gap at the bottom of the sheet when it goes too high, we clamp the height to the window height + a little extra
        // This is only done here so that the animation can keep running and looks more natural
        const clampedHeight = Math.min(newHeight, window.innerHeight + SHEET_DRAGGING_EXTRA_SPACE);

        setSheetHeight(sheetRef, clampedHeight);
    };
