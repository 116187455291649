import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuTransferIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-transfer"
        {...props}
    >
        <path
            fill="#323B4A"
            d="M21.53 13.47a.75.75 0 0 1 0 1.06l-.469.47.47.47a.75.75 0 0 1-1.061 1.06l-.47-.469-2.598 2.599a2.75 2.75 0 1 1-1.061-1.061l4.129-4.13a.75.75 0 0 1 1.06 0zm-8.03.78a.75.75 0 1 1 0 1.5H7A3.25 3.25 0 0 0 3.75 19v2a.75.75 0 1 1-1.5 0v-2A4.75 4.75 0 0 1 7 14.25h6.5zm1.5 4.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm-4-16.5a4.75 4.75 0 1 1 0 9.5 4.75 4.75 0 0 1 0-9.5zm0 1.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5z"
        />
    </svg>
);
const Memo = memo(ContextMenuTransferIcon);
export default Memo;
