// Utils
import globalLogger from '../../logger';

// Types
import { Action } from 'redux';
import { ReduxStore } from '../../types/reduxTypes';
import { BaseVisibilityState } from './baseVisibleConnectionState';
import { SOCKET_INTERRUPTION_TIMEOUT } from '../../utils/socket/socketConstants';
import { APP_BECAME_HIDDEN, APP_BECAME_VISIBLE } from '../appVisibility/appVisibilityTypes';

const logger = globalLogger.createChannel('visible-connection-status');

/**
 * Base class for states that will transition after a timeout.
 */
export abstract class BaseTimerVisibilityState extends BaseVisibilityState {
    static stateKey = 'base-timer-visibility-state';

    timeoutActionName = 'VISIBLE_CONNECTION_STATE_TIMEOUT';

    timerId: ReturnType<typeof setTimeout> | null = null;

    clearConnectingTimeout() {
        logger.info(`- clearing ${this.stateKey} timeout`);
        this.timerId && clearTimeout(this.timerId);
    }

    startConnectingTimer(store: ReduxStore) {
        this.clearConnectingTimeout();
        logger.info(`- starting ${this.stateKey} timer`);
        this.timerId = setTimeout(() => {
            logger.info(`- dispatching ${this.stateKey} timeout action`);
            store.dispatch({ type: this.timeoutActionName });
            this.timerId = null;
        }, SOCKET_INTERRUPTION_TIMEOUT);
    }

    onAction(action: Action, store: ReduxStore) {
        switch (action.type) {
            case APP_BECAME_VISIBLE:
                return this.startConnectingTimer(store);
            case APP_BECAME_HIDDEN:
                return this.clearConnectingTimeout();
        }
    }

    onEnter(store: ReduxStore) {
        super.onEnter(store);
        this.startConnectingTimer(store);
    }

    onExit(store: ReduxStore) {
        this.clearConnectingTimeout();
        super.onExit(store);
    }
}
