import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { snakeCase } from 'lodash';

// Components
import MobileColorDisplaySheet from './MobileColorDisplaySheet';
import ToolbarTool from '../../ToolbarTool';
import useSheet from '../../../../../mobile/structural/sheet/hooks/useSheet';

// Utils
import { getColorSpace, getShowColorValue } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Types
import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

// Constants
import { COLOR_DISPLAY, COLOR_SPACE } from '../../../../../../common/colors/colorConstants';

type MobileColorDisplayToolProps = {
    selectedElements: Immutable.List<ImMNElement>;
};

const MobileColorDisplayTool = (props: MobileColorDisplayToolProps): ReactElement => {
    const { selectedElements } = props;

    const firstSelectedElement = selectedElements.first();

    const showColorValue = getShowColorValue(firstSelectedElement);
    const colorSpace = getColorSpace(firstSelectedElement) || COLOR_SPACE.HEX;
    const colorDisplay = showColorValue ? colorSpace : COLOR_DISPLAY.OFF;

    const sheetProps = useSheet(SheetId.ColorDisplayTool);
    const { isSheetOpen, dispatchOpenSheet, dispatchCloseSheet } = sheetProps;

    return (
        <div className="ColorDisplayTool">
            <ToolbarTool
                {...props}
                name="Display"
                isOpen={isSheetOpen}
                onClick={dispatchOpenSheet}
                className={classNames(`color-display-tool-option-${snakeCase(colorDisplay)}`)}
            >
                <div className="color-display-contents">
                    <div className="color-display-label">{colorDisplay}</div>
                </div>
            </ToolbarTool>
            <MobileColorDisplaySheet
                {...props}
                sheetProps={sheetProps}
                colorDisplay={colorDisplay}
                dispatchCloseSheet={dispatchCloseSheet}
            />
        </div>
    );
};

export default MobileColorDisplayTool;
