// Lib
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash/fp';

// Utils
import { retrieveDeviceId } from '../../../device/deviceService';
import { authenticateWithApple, authenticateWithGoogle } from '../../authUtils';
import { isPlatformIframe, isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Actions
import { login, logout } from '../../authActions';
import { redirectLoggedInUserToWorkspace } from '../../../reducers/navigationActions';

// Components
import FullScreenModal from '../../../components/modal/FullScreenModal';
import Icon from '../../../components/icons/Icon';
import RouteLink from '../../../components/buttons/RouteLink';
import LoginForm from './LoginForm';
import AuthFormDisclaimerSection from '../../AuthFormDisclaimerSection';
import SocialSignInButton from '../../SocialSignInButton';
import AppleLogo from '../../icons/AppleLogo';
import GoogleLogo from '../../icons/GoogleLogo';
import { MobileAndTabletComponentSwitch } from '../../../mobile/utils/MobileAndTabletComponentSwitch';
import { AuthSheet } from '../splash/AuthSheet';

// Constants
import { AUTH_FLOWS } from '../../../../common/utils/authConstants';

// Styles
import '../AuthModal.scss';

const showOverlay = (route) => get(['overlay'], route) || false;
const getRegisterPath = (route) => get(['registerPath'], route) || 'register';

const getIsGuest = (state) => state.getIn(['app', 'currentUser', 'isGuest']);

const Container = MobileAndTabletComponentSwitch(FullScreenModal, AuthSheet);

// Redux connect methods
const Login = ({ location, close, route }) => {
    const isGuest = useSelector(getIsGuest);
    const platformDetails = useSelector(getPlatformDetailsSelector);

    const deviceId = useMemo(retrieveDeviceId, []);

    const dispatch = useDispatch();
    const dispatchLogin = ({ email, password, ...rest }) => dispatch(login(email, password, rest));
    const dispatchForceLogout = () => dispatch(logout());
    const navigateToWorkspace = () => dispatch(redirectLoggedInUserToWorkspace());

    useEffect(() => {
        // on iPad hybrid app, always defer to native login, so force a logout if Login is mounted
        if (isPlatformLegacyMobileApp(platformDetails)) {
            dispatchForceLogout();
            return;
        }

        if (!isGuest) {
            navigateToWorkspace();
        }
    }, []);

    if (isPlatformIframe(platformDetails)) return null;

    return (
        <Container className="AuthModal" overlay={showOverlay(route)} close={close}>
            <div className="logo">
                <Icon name="milanote-logo-medium" />
            </div>

            <div className="heading">
                <h1>Log in to Milanote</h1>
            </div>

            <div className="section">
                <LoginForm deviceId={deviceId} location={location} dispatchLogin={dispatchLogin} />
            </div>

            <div className="section">
                <div className="social-buttons">
                    <SocialSignInButton
                        className="MilanoteGoogleSignIn"
                        logo={<GoogleLogo />}
                        onClick={() => authenticateWithGoogle({ flow: AUTH_FLOWS.SIGN_IN, deviceId })}
                    >
                        Log in with Google
                    </SocialSignInButton>

                    <SocialSignInButton
                        className="MilanoteAppleSignIn"
                        logo={<AppleLogo />}
                        onClick={() => authenticateWithApple({ flow: AUTH_FLOWS.SIGN_IN, deviceId })}
                    >
                        Log in with Apple
                    </SocialSignInButton>
                </div>

                <div className="register-instead">
                    Don&apos;t have an account?{' '}
                    <RouteLink to={`/${getRegisterPath(route)}`} keepParams location={location}>
                        Sign up now
                    </RouteLink>
                </div>
            </div>

            <AuthFormDisclaimerSection />
        </Container>
    );
};

Login.propTypes = {
    route: PropTypes.object,
    location: PropTypes.object,
    close: PropTypes.func,
};

export default Login;
