// Utils
import { getTimestamp } from '../../../../../common/utils/timeUtil';

// Types
import { AsyncResourceEntity, AsyncResourceEntityErrorObject } from './reducers/asyncResourceReducerTypes';
import { ResourceStatus } from './asyncResourceConstants';

export const isAsyncEntityFetching = (resourceEntity: AsyncResourceEntity): boolean =>
    resourceEntity?.fetching || false;
export const isAsyncEntityFetched = (resourceEntity: AsyncResourceEntity): boolean => resourceEntity?.fetched || false;

export const isAsyncEntityCached = (resourceEntity: AsyncResourceEntity): boolean =>
    resourceEntity?.status === ResourceStatus.CACHED;

export const isAsyncEntityError = (resourceEntity: AsyncResourceEntity): boolean =>
    resourceEntity?.hasError && !isAsyncEntityFetching(resourceEntity);

export const getAsyncEntityError = (resourceEntity: AsyncResourceEntity): AsyncResourceEntityErrorObject | undefined =>
    resourceEntity?.error;
export const getAsyncEntityErrorTime = (resourceEntity: AsyncResourceEntity): number | undefined =>
    resourceEntity?.errorTime;

export const getAsyncEntityExpiry = (resourceEntity: AsyncResourceEntity): number | undefined => resourceEntity?.expiry;
export const getAsyncEntityFetchedTime = (resourceEntity: AsyncResourceEntity): number | undefined =>
    resourceEntity?.fetchedTime;

export const isAsyncEntityExpired = (resourceEntity: AsyncResourceEntity): boolean => {
    const expiry = getAsyncEntityExpiry(resourceEntity);
    return !!expiry && expiry < getTimestamp();
};

/**
 * Determines if the resource entity should be re-fetched.
 */
export const shouldFetchAsyncResource = (
    resourceEntity: AsyncResourceEntity,
    localCacheHydrationTimestamp: number,
): boolean => {
    if (isAsyncEntityFetching(resourceEntity)) return false;

    const fetchedTime = getAsyncEntityFetchedTime(resourceEntity);

    if (!isAsyncEntityFetched(resourceEntity) || !fetchedTime) return true;

    // If the resource was fetched before the local cache was hydrated, we should re-fetch it
    if (localCacheHydrationTimestamp && fetchedTime < localCacheHydrationTimestamp) return true;

    const expiry = getAsyncEntityExpiry(resourceEntity);

    if (!expiry) return false;

    const currentTime = getTimestamp();
    if (currentTime > expiry) return true;

    return false;
};
