// Lib
import { AnyAction } from 'redux';

// Actions
import { orchestrateReconnect } from './appServerReconnectionActions';

// Types
import { ReduxStore } from '../../types/reduxTypes';

// Constants
import { ELECTRON_APP_WAKE } from '../../../common/electron/electronConstants';
import { APP_DID_REHYDRATE_FROM_LOCAL_CACHE } from './appServerReconnectionStatusConstants';
import {
    HYBRID_APP_DID_BECOME_ACTIVE,
    HYBRID_APP_ENTER_FOREGROUND,
} from '../../hybridApp/store/hybridAppStoreConstants';
import { CAPACITOR_LIFECYCLE_EVENT, CapacitorAppStatus } from '../../capacitor/store/capacitorLifecycleTypes';

const shouldReconnect = (action: AnyAction) => {
    switch (action.type) {
        case HYBRID_APP_DID_BECOME_ACTIVE:
        case HYBRID_APP_ENTER_FOREGROUND:
        case ELECTRON_APP_WAKE:
        case APP_DID_REHYDRATE_FROM_LOCAL_CACHE:
            return true;
        case CAPACITOR_LIFECYCLE_EVENT:
            return action.status === CapacitorAppStatus.ACTIVE;
        default:
            return false;
    }
};

const handleAction = (action: AnyAction, dispatch: Function) => {
    if (shouldReconnect(action)) {
        dispatch(orchestrateReconnect());
    }
};

export default (store: ReduxStore) =>
    (next: Function) =>
    (action: AnyAction): AnyAction => {
        if (action.remote) return next(action);
        const { dispatch } = store;

        handleAction(action, dispatch);

        return next(action);
    };
