// Utils
import { isDebugEnabled, isGlobalDebugEnabled } from '../../debug/debugUtil';
import { getMobileAppModeForCurrentUser } from '../../platform/utils/platformAppModeUtils';

// Selectors
import { getGridSizeName } from './gridSizeSelector';
import { getCurrentUser } from '../../user/currentUserSelector';
import { getLegacyHybridUseCaseSelector, getMilanoteApplicationModeSelector } from '../../platform/platformSelector';

// Actions
import { updatePlatformDetails } from '../../platform/platformActions';

// Constants
import { GRID_RESIZE, GRID } from './gridConstants';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

export const setGridSize = (gridSize, sizeName) => ({ type: GRID_RESIZE, gridSize, sizeName });

const getSmallerGridSize = (sizeName) => {
    switch (sizeName) {
        case GRID.LARGE.name:
            return GRID.MEDIUM;
        case GRID.MEDIUM.name:
            return GRID.SMALL;
        case GRID.SMALL.name:
        case GRID.MOBILE.name:
        default:
            // only allow developers to access the mobile app size via keyboard shortcut
            return isDebugEnabled() ? GRID.MOBILE : GRID.SMALL;
    }
};

const getLargerGridSize = (sizeName) => {
    switch (sizeName) {
        case GRID.MOBILE.name:
            return GRID.SMALL;
        case GRID.SMALL.name:
            return GRID.MEDIUM;
        case GRID.MEDIUM.name:
        case GRID.LARGE.name:
        default:
            return GRID.LARGE;
    }
};

/**
 * Determines the correct mobile mode to show depending on the user's feature flags.
 */
// FIXME-MOBILE - Remove this when we remove the GRID.MOBILE
const switchToAppropriateMobileAppMode = () => (dispatch, getState) => {
    const state = getState();
    const currentUser = getCurrentUser(state);

    const hybridUseCase = getLegacyHybridUseCaseSelector(state);
    let appMode = getMobileAppModeForCurrentUser(currentUser, hybridUseCase);

    const currentAppMode = getMilanoteApplicationModeSelector(state);

    if (appMode === currentAppMode && isGlobalDebugEnabled()) {
        appMode =
            appMode === MilanoteApplicationMode.mobile
                ? MilanoteApplicationMode.mobileLegacy
                : MilanoteApplicationMode.mobile;
    }

    dispatch(updatePlatformDetails({ appMode }));
};

export const decreaseGridSize = () => (dispatch, getState) => {
    const state = getState();
    const { size, name } = getSmallerGridSize(getGridSizeName(state));

    dispatch(setGridSize(size, name));

    if (name === GRID.MOBILE.name) dispatch(switchToAppropriateMobileAppMode());
};

export const increaseGridSize = () => (dispatch, getState) => {
    const { size, name } = getLargerGridSize(getGridSizeName(getState()));
    dispatch(setGridSize(size, name));

    // FIXME-MOBILE - Remove this when we remove the GRID.MOBILE
    if (name === GRID.SMALL.name) {
        dispatch(updatePlatformDetails({ appMode: MilanoteApplicationMode.desktop }));
    }
};

/**
 * Switches to the large grid size and sets the application mode to desktop.
 */
export const switchToDesktopMode = () => (dispatch) => {
    dispatch(setGridSize(GRID.LARGE.size, GRID.LARGE.name));
    dispatch(updatePlatformDetails({ appMode: MilanoteApplicationMode.desktop }));
};

/**
 * Switches to the large grid size and sets the application mode to the appropriate
 * mobile view for the current user (mobile-legacy or mobile).
 */
export const switchToMobileMode = () => (dispatch, getState) => {
    dispatch(setGridSize(GRID.MOBILE.size, GRID.MOBILE.name));
    dispatch(switchToAppropriateMobileAppMode());
};
