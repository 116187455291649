import { UnknownAction } from 'redux';
import { CAPACITOR_LIFECYCLE_EVENT, CapacitorAppStatus, CapacitorLifecycleAction } from './capacitorLifecycleTypes';

type CapacitorState = {
    currentStatus: CapacitorAppStatus;
    history: { status: CapacitorAppStatus; lifecycleEvent: string; timestamp: string }[];
};

const initialState = {
    // If the app is initialised, then it's active
    currentStatus: CapacitorAppStatus.ACTIVE,
    history: [],
};

const handleCapacitorLifecycleEvent = (state: CapacitorState, action: CapacitorLifecycleAction) => ({
    currentStatus: action.status,
    history: [
        ...state.history,
        {
            status: action.status,
            lifecycleEvent: action.lifecycleEvent,
            timestamp: new Date(action.timestamp).toLocaleString(),
        },
    ].slice(-20),
});

export default (state: CapacitorState = initialState, action: UnknownAction) => {
    switch (action.type) {
        case CAPACITOR_LIFECYCLE_EVENT:
            return handleCapacitorLifecycleEvent(state, action as CapacitorLifecycleAction);
        default:
            return state;
    }
};
