/**
 * Run a callback after a transitionend event, with a fallback timeout
 */
const runOnTransitionEndWithTimeout = (
    ref: React.RefObject<HTMLElement>,
    callback: () => void,
    fallbackTimeout = 1000,
    onTimeoutFinish = () => {},
): (() => void) => {
    let timeout: NodeJS.Timeout | null = null;

    // --- Create cancel and callback functions ---

    const cancelTimeout = () => {
        if (!timeout) return;

        clearTimeout(timeout);
        timeout = null;
    };

    const transitionendCallback = () => {
        cancelTimeout();
        callback();
    };

    const cancelEventListener = () => {
        ref.current?.removeEventListener('transitionend', transitionendCallback);
    };

    // --- Add listener and timeout ---

    ref.current?.addEventListener('transitionend', transitionendCallback, { once: true });

    timeout = setTimeout(() => {
        cancelEventListener();
        onTimeoutFinish();
        callback();
    }, fallbackTimeout);

    return () => {
        cancelEventListener();
        cancelTimeout();
    };
};

export default runOnTransitionEndWithTimeout;
