// Utils
import { getMilanoteApplicationModeSelector } from '../../platform/platformSelector';
import { closeSheet, openErrorSheet } from '../../mobile/structural/sheet/sheetActions';

// Constants
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';
import { SheetId } from '../../mobile/structural/sheet/sheetTypes';
import { closeErrorModal, openErrorModal } from './modals/errorModalActions';

export const openErrorNotice =
    ({ errorNoticeId, data = undefined }: { errorNoticeId: string; data?: object }) =>
    (dispatch: Function, getState: Function) => {
        const state = getState();

        const appMode = getMilanoteApplicationModeSelector(state);

        if (appMode === MilanoteApplicationMode.mobile) {
            return dispatch(openErrorSheet(errorNoticeId, data));
        }

        return dispatch(openErrorModal(errorNoticeId, data));
    };

export const closeErrorNotice = () => (dispatch: Function, getState: Function) => {
    const state = getState();

    const appMode = getMilanoteApplicationModeSelector(state);

    if (appMode === MilanoteApplicationMode.mobile) {
        return dispatch(closeSheet(SheetId.Error));
    }

    return dispatch(closeErrorModal());
};
