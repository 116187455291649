/**
 * Fetch boards if they've been modified since the last fetch, or haven't been fetched.
 */
// Lib
import { difference, isEmpty, keyBy } from 'lodash';

// Utils
import globalLogger from '../../logger';
import { prop } from '../../../common/utils/immutableHelper';
import { updateFetchedTimeAsyncResource } from '../../utils/services/http/asyncResource/asyncResourceActions';
import {
    isAsyncEntityExpired,
    isAsyncEntityFetched,
    isAsyncEntityFetching,
} from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Selectors
import { getLocalCacheHydrationTimestamp } from '../../offline/cache/localCacheSelector';
import { selectSocketDisconnectionTime } from '../../utils/socket/socketConnectionSelector';
import { getAsyncResourceEntityState } from '../../utils/services/http/asyncResource/asyncResourceSelector';

// Services
import http from '../../utils/services/http';
import { fetchBoard, FetchBoardArgs, fetchBoards, FetchBoardsArgs } from './boardService';

// Types
import { TIMES } from '../../../common/utils/timeUtil';
import { BoardIdModified } from '../../../common/api/board/boardModifiedRouteTypes';
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { AsyncResourceEntity } from '../../utils/services/http/asyncResource/reducers/asyncResourceReducerTypes';

const logger = globalLogger.createChannel('board-refresh-service');

/**
 * Perform the REST request to retrieve the modified times for multiple boards.
 */
const fetchMultipleBoardModifiedTimesHttp = (boardIds: string[]): Promise<BoardIdModified[]> =>
    http({ url: `boards/modified`, timeout: 11000, params: { ids: boardIds.join(',') } })
        .then((response) => response.data.modifiedTimes)
        .catch((err) => {
            console.error('ERROR: fetching multiple board modified times', err, err.response);
            return Promise.reject(err);
        });

enum RefreshDecision {
    MUST_REFRESH = 'MUST_REFRESH',
    NO_REFRESH = 'NO_REFRESH',
    NEEDS_MODIFIED_TIME = 'NEEDS_MODIFIED_TIME',
}

type ShouldRefreshBoardPredicate = (
    refreshedBoardResource: AsyncResourceEntity,
    boardCreationTime: number,
    boardModifiedTime: number | undefined,
    boardFetchedTime: number,
    localCacheHydrationTimestamp?: number,
    socketDisconnectionTime?: number,
) => RefreshDecision;

/**
 * If the board has been fetched, but its last modified time on the server is more than 2 seconds after
 * the fetch time on the client, then we should refresh.
 * The board fetched time is updated on board fetch or when a child element is updated (see boardResourceTypeReducer.js)
 * A buffer here assists with the fact that the modified time of an element is set server side and will be greater than the clients action timestamp
 * This would cause unnecessary board refreshes when an element is updated / created after the board was fetched,
 * prior to the socket re-connecting
 *
 * NOTE: The 2 second buffer was previously used so that if a board was created and then quickly
 *  navigated to, it wouldn't be re-fetched, thus creating a race condition where the board's details
 *  (such as title, icon & colour) are yet to be updated in the DB.
 */
const refreshIfModifiedAfterFetchedTime = (boardModifiedTime: number, boardFetchedTime: number): RefreshDecision =>
    boardModifiedTime > boardFetchedTime + 2 * TIMES.SECOND ? RefreshDecision.MUST_REFRESH : RefreshDecision.NO_REFRESH;

/**
 * Based on the board fetch state and the server's modification time for the board, determine whether it
 * should be fetched now.
 */
const shouldRefreshBoardIfModified: ShouldRefreshBoardPredicate = (
    refreshedBoardResource,
    boardCreationTime,
    boardModifiedTime,
    boardFetchedTime,
    localCacheHydrationTimestamp,
) => {
    // If we're already fetching the resource then don't fetch it again
    if (isAsyncEntityFetching(refreshedBoardResource)) return RefreshDecision.NO_REFRESH;

    // If the board isn't already fetched, then we definitely should fetch it
    if (!isAsyncEntityFetched(refreshedBoardResource)) return RefreshDecision.MUST_REFRESH;

    // If the resources is expired then we need to re-fetch it
    if (isAsyncEntityExpired(refreshedBoardResource)) return RefreshDecision.MUST_REFRESH;

    if (!boardModifiedTime) return RefreshDecision.NEEDS_MODIFIED_TIME;

    // NOTE: Deleted logic here (see this commit) that would refresh the board if it was fetched before the
    //  local cache was hydrated. I think this was being too cautious and causing unnecessary fetches, so removed it.

    // If the board was modified before the last time the board was fetched, it doesn't need to be re-fetched
    if (boardModifiedTime < boardFetchedTime) return RefreshDecision.NO_REFRESH;

    // if local cache was hydrated after the board was fetched, and board was created before the hydration timestamp,
    // then we should refresh if the board was modified after the fetch time (i.e. don't respect the 2 minute logic
    // implemented below)
    if (localCacheHydrationTimestamp && boardCreationTime && boardCreationTime < localCacheHydrationTimestamp) {
        return refreshIfModifiedAfterFetchedTime(boardModifiedTime, boardFetchedTime);
    }

    // If we have a board creation time, and the modified time is within 2 minutes of the creation time then
    // don't worry about fetching the
    if (boardCreationTime && boardModifiedTime < boardCreationTime + 2 * TIMES.MINUTE)
        return RefreshDecision.NO_REFRESH;

    return refreshIfModifiedAfterFetchedTime(boardModifiedTime, boardFetchedTime);
};

/**
 * Determines if a board refresh should occur, but assumes that if there hasn't been a disconnection
 * then we don't need to check the modified time.
 */
const shouldRefreshBoardIfDisconnectedAndModified: ShouldRefreshBoardPredicate = (
    refreshedBoardResource,
    boardCreationTime,
    boardModifiedTime,
    boardFetchedTime,
    localCacheHydrationTimestamp,
    socketDisconnectionTime,
) => {
    const modifiedResult = shouldRefreshBoardIfModified(
        refreshedBoardResource,
        boardCreationTime,
        boardModifiedTime,
        boardFetchedTime,
        localCacheHydrationTimestamp,
        socketDisconnectionTime,
    );

    // If we haven't disconnected, just return the result of the modified check
    if (!socketDisconnectionTime) return modifiedResult;

    // If we know that we should or shouldn't fetch the board already, then do so
    if (modifiedResult !== RefreshDecision.NEEDS_MODIFIED_TIME) return modifiedResult;

    // If we fetched before a socket disconnection, check the modified time before refreshing
    if (boardFetchedTime < socketDisconnectionTime) return RefreshDecision.NEEDS_MODIFIED_TIME;

    // Otherwise, don't worry about refreshing (we expect it to be up to date,
    // so don't check the modified time)
    return RefreshDecision.NO_REFRESH;
};

const getRefreshBoardsDecision = (
    shouldRefreshPredicate: ShouldRefreshBoardPredicate,
    boardIds: string[],
    state: any,
    boardIdModifiedTimes?: BoardIdModified[],
    fetchedTimeOverride?: number,
): { boardId: string; refreshDecision: RefreshDecision }[] => {
    const localCacheHydrationTimestamp = getLocalCacheHydrationTimestamp(state);
    const socketDisconnectionTime = selectSocketDisconnectionTime(state);
    const boardIdModifiedTimesMap = keyBy(boardIdModifiedTimes, 'id');

    return boardIds.map((boardId) => {
        const refreshedBoardResourceEntity = getAsyncResourceEntityState(state, ResourceTypes.boards, boardId);
        const boardFetchedTime = fetchedTimeOverride || prop('fetchedTime', refreshedBoardResourceEntity);

        // If the board has been created on this client it will have a 'creationTime' property on the board
        // resources object (see boardResourceReducer.js).
        // In this case the board should theoretically be in-sync if the modification time was within a few
        // minutes of the creation time
        const boardCreationTime = prop('creationTime', refreshedBoardResourceEntity);

        const boardModifiedTime = boardIdModifiedTimesMap[boardId]?.modifiedTime;

        const refreshDecision = shouldRefreshPredicate(
            refreshedBoardResourceEntity,
            boardCreationTime,
            boardModifiedTime,
            boardFetchedTime,
            localCacheHydrationTimestamp,
            socketDisconnectionTime,
        );

        return { boardId, refreshDecision };
    });
};

/**
 * Determines the board IDs to refresh by:
 * - Checking to see if we should automatically refresh them or not, based on the
 *  currently known state of the board and the last time it was fetched, or
 * - Fetches the modified time of the board from the server and then compares that
 *  to the current board's fetched time.
 *
 * This prevents unnecessarily fetching the modified times for some boards, as if
 *  the modified time isn't cached on the server it might be expensive to fetch.
 */
const getBoardIdsToRefresh = async (
    shouldRefreshPredicate: ShouldRefreshBoardPredicate,
    boardIds: string[],
    state: any,
    fetchedTimeOverride?: number,
): Promise<{
    boardIdsToRefresh: string[];
    boardIdsToUpdateFetchedTimes: string[];
}> => {
    // First of all see if we know whether to refresh the board or not without fetching
    //  the modified time from the server
    const initialRefreshDecisions = getRefreshBoardsDecision(
        shouldRefreshPredicate,
        boardIds,
        state,
        undefined,
        fetchedTimeOverride,
    );
    const immediateFetchBoardIds = initialRefreshDecisions
        .filter(({ refreshDecision }) => refreshDecision === RefreshDecision.MUST_REFRESH)
        .map(({ boardId }) => boardId);
    const needsModifiedTimeBoardIds = initialRefreshDecisions
        .filter(({ refreshDecision }) => refreshDecision === RefreshDecision.NEEDS_MODIFIED_TIME)
        .map(({ boardId }) => boardId);

    if (isEmpty(needsModifiedTimeBoardIds)) {
        return {
            boardIdsToRefresh: immediateFetchBoardIds,
            boardIdsToUpdateFetchedTimes: [],
        };
    }

    // For boards that we need to fetch the modified time for, do so now
    const modifiedTimes = await fetchMultipleBoardModifiedTimesHttp(needsModifiedTimeBoardIds);
    const modifiedTimeRefreshDecisions = getRefreshBoardsDecision(
        shouldRefreshPredicate,
        needsModifiedTimeBoardIds,
        state,
        modifiedTimes,
        fetchedTimeOverride,
    );
    const modifiedTimeFetchBoardIds = modifiedTimeRefreshDecisions
        .filter(
            ({ refreshDecision }) =>
                refreshDecision === RefreshDecision.MUST_REFRESH ||
                refreshDecision === RefreshDecision.NEEDS_MODIFIED_TIME,
        )
        .map(({ boardId }) => boardId);

    const modifiedTimeBoardIds = modifiedTimes.map((modifiedTimeEntry) => modifiedTimeEntry?.id);
    const boardIdsToUpdateFetchedTimes = difference(modifiedTimeBoardIds, modifiedTimeFetchBoardIds);

    return {
        boardIdsToRefresh: [...immediateFetchBoardIds, ...modifiedTimeFetchBoardIds],
        boardIdsToUpdateFetchedTimes,
    };
};

export interface RefreshBoardIfModifiedArgs extends FetchBoardArgs {
    beforeRefresh?: Function;
    afterRefresh?: Function;
    afterTimestamp?: number;
}

/**
 * Refreshes a board if it has been updated since its last fetch time, or
 * it hasn't been fetched yet.
 */
export const refreshIfModified =
    (args: RefreshBoardIfModifiedArgs) =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        const { beforeRefresh, afterRefresh, afterTimestamp } = args;

        if (!args.boardId) {
            logger.error('No boardId provided to refreshIfModified', args);
            return;
        }

        const state = getState();

        try {
            const { boardIdsToUpdateFetchedTimes } = await getBoardIdsToRefresh(
                shouldRefreshBoardIfModified,
                [args.boardId],
                state,
                afterTimestamp,
            );

            if (!isEmpty(boardIdsToUpdateFetchedTimes)) {
                dispatch(updateFetchedTimeAsyncResource(ResourceTypes.boards, boardIdsToUpdateFetchedTimes));
                return;
            }

            beforeRefresh?.();
            const fetchPromise = dispatch(fetchBoard({ ...args, force: true }));
            afterRefresh?.();
            return fetchPromise;
        } catch (err) {
            // If we're unable to retrieve the modified time, just force the refresh
            beforeRefresh?.();
            const fetchPromise = dispatch(fetchBoard({ ...args, force: true }));
            afterRefresh?.();
            return fetchPromise;
        }
    };

/**
 * Allows a predicate function to determine the logic to use in order to refresh multiple boards.
 *
 * Updates the fetched time for any boards that needed their modified times retrieved,
 * but didn't need to be fetched. This will prevent future modified time checks for these boards.
 */
const createRefreshMultipleBoardsIfFn =
    (shouldRefreshPredicate: ShouldRefreshBoardPredicate) =>
    (args: FetchBoardsArgs) =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        const { boardIds } = args;

        let boardIdsToFetch: string[];
        const state = getState();

        try {
            const { boardIdsToRefresh, boardIdsToUpdateFetchedTimes } = await getBoardIdsToRefresh(
                shouldRefreshPredicate,
                boardIds,
                state,
            );
            boardIdsToFetch = boardIdsToRefresh;

            // Update the fetched times for the elements to be the current date so we don't need to keep checking
            // them in the current session
            if (!isEmpty(boardIdsToUpdateFetchedTimes)) {
                dispatch(updateFetchedTimeAsyncResource(ResourceTypes.boards, boardIdsToUpdateFetchedTimes));
            }
        } catch (err) {
            logger.error('Failed to retrieve multiple board modified times', err);
            // On error, just fetch all the boards
            boardIdsToFetch = boardIds;
        }

        if (isEmpty(boardIdsToFetch)) return;

        dispatch(fetchBoards({ ...args, boardIds: boardIdsToFetch, force: true }));
    };

/**
 * Refreshes multiple boards if any have been updated since their last fetch time.
 */
export const refreshMultipleBoardsIfModified = createRefreshMultipleBoardsIfFn(shouldRefreshBoardIfModified);

/**
 * Refreshes boards if they've been modified since the last fetch, haven't been fetched, or
 * if there's been a socket disconnection, and they haven't been fetched since.
 */
export const refreshBoardsIfDisconnectedAndModified = createRefreshMultipleBoardsIfFn(
    shouldRefreshBoardIfDisconnectedAndModified,
);
