// Lib
import React from 'react';
import classNames from 'classnames';

// Styles
import './PageContent.scss';

const PageContent: React.FC<{
    className?: string;
    styled?: boolean;
}> = ({ children, className, styled = true }) => (
    <div className={classNames('PageContent', className, { StyledPageContent: styled })}>{children}</div>
);

export default PageContent;
