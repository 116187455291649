// Lib
import { intersection, difference } from 'lodash/fp';

// Selectors
import { activePopupSelector } from './popupOpenSelector';

// Utils
import { isEmpty } from '../../../common/utils/immutableHelper';

// Constants
import { POPUP_OPEN, POPUP_CLOSE, POPUP_TOGGLE, POPUPS_BY_SECTION } from './popupConstants';
import { DRAG_START } from '../../reducers/draggingConstants';

const DEFAULT_POPUP_PREDICATE = (state, action) =>
    // If the next event is a DRAG_START then it's very likely that the dragged item is inside the popup
    // (as another event would come first otherwise)
    // Otherwise close the popup on any other action
    action.type === DRAG_START;

export const openPopup = (popupId, predicateFn = DEFAULT_POPUP_PREDICATE) => ({
    type: POPUP_OPEN,
    popupId,
    predicateFn,
});

export const closePopup = (popupId, closeAll = true) => ({
    type: POPUP_CLOSE,
    popupId,
    closeAll,
});

export const closeMultiplePopups = (popupIds) => ({
    type: POPUP_CLOSE,
    popupIds,
});

/**
 * Dispatches a POPUP_CLOSE action if the popup is currently open.
 */
export const closePopupIfOpen = (popupId) => (dispatch, getState) => {
    if (!popupId) return;

    const state = getState();
    const activePopups = activePopupSelector(state);

    // No need to do anything as there's no open popups
    if (!activePopups || !activePopups.size) return;

    if (!activePopups.has(popupId)) return;

    dispatch(closePopup(popupId));
};

export const closeAllPopupsIfRequired = () => (dispatch, getState) => {
    const state = getState();
    const activePopups = activePopupSelector(state);

    // No need to do anything as there's no open popups
    if (!activePopups || !activePopups.size) return;

    dispatch(closePopup('', true));
};

export const closePopupsInSection =
    ({ section, excludedIds = [] }) =>
    (dispatch, getState) => {
        let popupsToClose = POPUPS_BY_SECTION[section];

        if (isEmpty(popupsToClose)) return;

        const state = getState();
        const activePopupState = activePopupSelector(state);
        const activePopups = activePopupState.keySeq().toArray();

        popupsToClose = difference(intersection(activePopups, popupsToClose), excludedIds);

        // No need to do anything as there's no open popups
        if (isEmpty(popupsToClose)) return;

        dispatch(closeMultiplePopups(popupsToClose));
    };

export const togglePopup = (popupId, predicateFn = DEFAULT_POPUP_PREDICATE) => ({
    type: POPUP_TOGGLE,
    popupId,
    predicateFn,
});

export const closePopupsMatching =
    ({ predicateFn }) =>
    (dispatch, getState) => {
        const state = getState();
        const activePopupState = activePopupSelector(state);
        const activePopups = activePopupState.keySeq().toArray();

        const popupsToClose = activePopups.filter(predicateFn);

        // No need to do anything as there's no open popups
        if (isEmpty(popupsToClose)) return;

        dispatch(closeMultiplePopups(popupsToClose));
    };
