// Lib
import HttpStatus from 'http-status-codes';
import { get, isEmpty } from 'lodash/fp';
import { keys, mapValues } from 'lodash';

// Services
import { http } from '../utils/services/http';
import { getCurrentUserToken, setCurrentUserToken } from '../auth/authService';
import {
    fetchCurrentUserAppInitialisationData,
    fetchCurrentUserInitialCustomTemplatesHttp,
    fetchCurrentUserInitialQuickSearchBoardSummariesHttp,
    fetchMe,
    updateCurrentUserEmail,
    updateMe,
} from './userService';

// Actions
import { simpleLogout } from '../auth/authLogoutActions';
import { loadElements } from '../element/actions/elementActions';
import { navigateToLogin } from '../reducers/navigationActions';
import { updateUser } from '../../common/users/userActions';
import { trackUsers, loadUsers } from './userActions';
import { setElementCount } from './elementCount/elementCountActions';
import { appSetInitialised } from '../app/initialisation/initialisationActions';
import { fetchCurrentUserSubscription } from './currentUserSubscriptionActions';
import { boardSummariesLoad } from '../element/board/summary/boardSummariesActions';
import { boardHierarchiesLoad } from '../element/board/hierarchy/boardHierarchiesActions';
import { currentBoardIdSet } from '../reducers/currentBoardId/currentBoardIdActions';

// Selectors
import { activePopupSelector } from '../components/popupPanel/popupOpenSelector';
import { getCurrentUserId } from './currentUserSelector';
import {
    getAclIdsSelector,
    getClosestPermissionIdForElementIdSelector,
} from '../utils/permissions/permissionsSelector';
import { getCurrentBoardId, getIsCurrentBoardIdInitialised } from '../reducers/currentBoardId/currentBoardIdSelector';
import { isBoardPreviewEnabledSelector } from '../workspace/boardPreview/boardPreviewSelector';

// Utils
import logger from '../logger/logger';
import { getDeviceId } from '../device/deviceService';
import { shouldDisableCache } from '../debug/debugUtil';
import { getTimestamp, TIMES } from '../../common/utils/timeUtil';
import { isElementId } from '../../common/uid/idValidator';
import { getElements } from '../element/selectors/elementSelector';
import { canRead } from '../../common/permissions/permissionUtil';
import { getPermission } from '../../common/permissions/elementPermissionsUtil';
import { commonImageUpload } from '../element/attachments/attachmentActions';
import { getNewTransactionId } from '../utils/undoRedo/undoRedoTransactionManager';
import { loadBoardNotificationPreferences } from '../notifications/preferences/boardNotificationPreferencesActions';
import { manuallyReportError } from '../analytics/rollbarService';
import { buildBoardSummaries } from '../../common/elements/utils/boardSummaryUtils';
import { isGuest } from './userUtil';
import { getUserId } from '../../common/users/utils/userPropertyUtils';

// Constants
import {
    CURRENT_USER_SET,
    CURRENT_USER_GET_DETAILS,
    CURRENT_USER_GET_FAILURE,
    CURRENT_USER_SET_GUEST,
} from './currentUserConstants';

import {
    USER_NAVIGATE,
    GUEST_NAVIGATE,
    USER_CHECKED_NOTIFICATIONS,
    USER_CHECKED_QUICK_NOTES,
} from '../../common/users/userConstants';
import { IMAGE_TYPES, IMAGE_TYPE_CONFIGS } from '../../common/media/mediaConstants';
import { ROLLBAR_LEVELS } from '../analytics/rollbarConstants';
import { METHODS } from '../../common/utils/http/httpConstants';
import { PopupIds } from '../components/popupPanel/popupConstants';
import { UserNavigationOperation } from '../../common/users/userNavigationTypes';
import { getRootType } from '../../common/elements/utils/elementPropertyUtils';
import { WORKSPACE_SECTIONS as ROOT_TYPES } from '../workspace/workspaceConstants';

export const setCurrentUser = (user) => ({
    type: CURRENT_USER_SET,
    user,
});

export const updateCurrentUser =
    ({ changes, userId = undefined, personal = false, sync = true }) =>
    (dispatch, getState) =>
        dispatch(
            updateUser({
                userId: userId || getState().getIn(['app', 'currentUser', '_id']),
                changes,
                personal,
                sync,
            }),
        );

export const userCheckedNotifications = ({ userId, timestamp = getTimestamp() }) => ({
    type: USER_CHECKED_NOTIFICATIONS,
    timestamp,
    userId,
    sync: true,
});

export const userCheckedQuickNotes = ({ userId, timestamp = getTimestamp() }) => ({
    type: USER_CHECKED_QUICK_NOTES,
    timestamp,
    userId,
    sync: true,
});

export const setCurrentUserContentLimitExceededFlag = (exceeded) =>
    updateCurrentUser({
        changes: {
            contentLimit: {
                exceeded,
            },
        },
    });

export const userNavigate =
    ({ userId, newBoardId, previousBoardId, sync = true, operation, navigationSource }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        if (!isElementId(newBoardId)) return;

        // If the search popup is open, don't track the navigation until the search popup is closed
        const activePopups = activePopupSelector(state);
        if (activePopups && activePopups.has(PopupIds.SEARCH)) return;

        const element = elements.get(newBoardId);
        const isQuickNotes = getRootType(element) === ROOT_TYPES.QUICK_NOTES;

        const aclIds = getAclIdsSelector(state);
        const userPermission = getPermission(elements, newBoardId, aclIds);

        const persist = !isQuickNotes && canRead(userPermission);

        const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId: newBoardId });

        dispatch({
            type: USER_NAVIGATE,
            newBoardId,
            previousBoardId,
            permissionId,
            permission: userPermission,
            timestamp: getTimestamp(),
            userId,
            persist,
            sync,
            navigationSource,
            monitoring: {
                operation,
            },
        });
    };

export const currentUserNavigate =
    ({ newBoardId, previousBoardId, operation }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);
        return dispatch(userNavigate({ userId: currentUserId, newBoardId, previousBoardId, operation }));
    };

export const guestNavigate =
    ({ newBoardId, previousBoardId }) =>
    async (dispatch, getState) => {
        if (!isElementId(newBoardId)) return;

        const state = getState();

        const deviceId = getDeviceId();
        if (!deviceId) return;

        const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId: newBoardId });

        const action = {
            type: GUEST_NAVIGATE,
            newBoardId,
            previousBoardId,
            deviceId,
            permissionId,
            timestamp: getTimestamp(),
        };

        dispatch(action);

        // FIXME-SOCKETS Won't need this once guests create a socket connection
        await http({
            url: 'actions',
            method: METHODS.POST,
            data: { action },
        });
    };

const sendUserNavigate =
    (user, boardId, navigationSource = 'web') =>
    (dispatch, getState) => {
        const state = getState();

        const currentBoardId = boardId || getCurrentBoardId(state);

        if (isGuest(user)) {
            return dispatch(guestNavigate({ newBoardId: currentBoardId }));
        }
        return dispatch(
            userNavigate({
                userId: getUserId(user),
                newBoardId: currentBoardId,
                operation: UserNavigationOperation.GENERAL,
                navigationSource,
            }),
        );
    };

export const fetchCurrentUser = () => (dispatch, getState) => {
    dispatch({ type: CURRENT_USER_GET_DETAILS });

    const currentUserToken = getCurrentUserToken();

    // TODO review - might need to implement a redirect
    if (!currentUserToken) {
        console.error('Attempting to retrieve a user without a user token.');
        return;
    }

    fetchMe()
        .then((response) => {
            const state = getState();
            const { user, elements, boardNotificationPreferences } = response.data;

            // If the current board id is not set yet at this stage and we're not using the swift hybrid app,
            // restore the last viewed board (if any), otherwise go to root board
            let boardId = null;
            if (!getIsCurrentBoardIdInitialised(state) && !isBoardPreviewEnabledSelector(state)) {
                boardId = user.lastViewedBoard || user.rootBoardId;
                dispatch(
                    currentBoardIdSet({
                        boardId,
                        restored: !!user.lastViewedBoard,
                    }),
                );
            }

            dispatch(setCurrentUser(user));
            dispatch(appSetInitialised({ currentUser: true }));
            dispatch(sendUserNavigate(user, boardId));

            dispatch(loadElements(elements));
            dispatch(loadBoardNotificationPreferences({ boardNotificationPreferences }));
        })
        .catch((err) => {
            // If the result is unauthorised, the user's token is no longer valid, so we should clear it
            // and log them out
            if (err.response?.status === HttpStatus.UNAUTHORIZED) {
                logger.error("The current user's token is no longer valid. Logging out this user.");
                dispatch(simpleLogout());
                return;
            }

            console.error(err, err.response);
            dispatch({ type: CURRENT_USER_GET_FAILURE });
            dispatch(navigateToLogin());
        });
};

export const initialiseAppForCurrentUser = (getTimezone) => async (dispatch, getState) => {
    // Only get the timezone if we're initialising a browser page (not a puppeteer preview page, etc)
    const timezone = getTimezone ? getTimezone() : undefined;
    const disableCache = shouldDisableCache();

    const response = await fetchCurrentUserAppInitialisationData({ timezone, disableCache });

    const { elements, remoteUserIds, elementCount, boardHierarchies } = response.data;

    const userIds = keys(remoteUserIds);
    const userShareCounts = mapValues(remoteUserIds, (shareCount) => ({
        shareCount,
    }));

    dispatch(loadElements(elements));
    dispatch(trackUsers(userIds));
    dispatch(loadUsers(userShareCounts));
    boardHierarchies && dispatch(boardHierarchiesLoad(boardHierarchies));

    !!elementCount && dispatch(setElementCount(elementCount));

    return response.data;
};

/**
 * Fetches the board summaries relevant for quick search initialisation.
 */
export const fetchCurrentUserInitialQuickSearchBoardSummaries = () => async (dispatch, getState) => {
    try {
        const response = await fetchCurrentUserInitialQuickSearchBoardSummariesHttp();

        const { boardSummaries } = response.data;

        dispatch(boardSummariesLoad(boardSummaries));
    } catch (e) {
        logger.error('Failed to retrieve quick notes board summaries', e);
    }
};

/**
 * Fetches the custom templates within this user's tree.
 */
export const fetchCurrentUserInitialCustomTemplates = () => async (dispatch, getState) => {
    try {
        const response = await fetchCurrentUserInitialCustomTemplatesHttp();

        const { elements } = response.data;

        const elementsArray = Object.values(elements);

        if (!isEmpty(elementsArray)) {
            dispatch(loadElements(elementsArray));
            const boardSummaries = buildBoardSummaries(elementsArray);
            dispatch(boardSummariesLoad(boardSummaries));
            dispatch(appSetInitialised({ customTemplates: true }));
        }
    } catch (e) {
        logger.error('Failed to retrieve current user custom templates', e);
    }
};

// TODO-CHECKOUT: Finish what is required here — do we still need this action?
export const refreshCurrentUser = () => async (dispatch) => {
    dispatch(fetchCurrentUserSubscription());
};

export const updateCurrentUserHttp = (userChanges) => async (dispatch) => {
    const updatedUser = await updateMe({ userChanges });

    // NOTE: An action from the server will be received as well, but this ensures that the client is updated
    //  in case there's any socket message delivery issues
    dispatch(
        updateCurrentUser({
            userId: updatedUser._id,
            changes: updatedUser,
            sync: false,
        }),
    );
};

export const updateCurrentUserEmailHttp =
    ({ email, password }) =>
    async (dispatch) => {
        const { token, ...updatedUser } = await updateCurrentUserEmail({ email, password });

        if (token) {
            setCurrentUserToken(token);
        }

        // NOTE: An action from the server will be received as well, but this ensures that the client is updated
        //  in case there's any socket message delivery issues
        dispatch(
            updateCurrentUser({
                userId: updatedUser._id,
                changes: updatedUser,
                sync: false,
            }),
        );

        return updatedUser;
    };

export const setCurrentUserGuest = () => ({ type: CURRENT_USER_SET_GUEST });

const commonImageAvatarUpload = commonImageUpload({});

const CLEARED_IMAGE_SIZE_URLS = IMAGE_TYPE_CONFIGS[IMAGE_TYPES.AVATAR].sizes.reduce(
    (acc, size) => ({
        ...acc,
        [size.name]: null,
    }),
    {},
);

export const uploadCurrentUserAvatar =
    ({ userId, file, transactionId = getNewTransactionId() }) =>
    (dispatch) => {
        dispatch(commonImageAvatarUpload({ id: userId, file, transactionId, imageType: IMAGE_TYPES.AVATAR }))
            .then(({ uploadUrl, size }) => {
                dispatch(
                    updateCurrentUser({
                        userId,
                        changes: {
                            image: {
                                ...CLEARED_IMAGE_SIZE_URLS,
                                ...size,
                                original: uploadUrl,
                            },
                        },
                    }),
                );
            })
            // The error is already handled in the common image upload
            .catch((err) => null);
    };

export const fetchCurrentUserReferrals = () => (dispatch, getState) =>
    http({ url: 'users/me/referrals' })
        .then(({ data }) => {
            const userId = getState().getIn(['app', 'currentUser', '_id']);
            const { users, referrals } = data;

            if (users) dispatch(loadUsers(users));

            dispatch(
                updateCurrentUser({
                    userId,
                    changes: {
                        referrals,
                    },
                    sync: false,
                }),
            );
        })
        .catch((err) => null); // not a big deal if this fails

export const fetchCurrentUserElementCounts = () => async (dispatch, getState) => {
    try {
        const response = await http({ url: 'users/me/counts', timeout: 2 * TIMES.MINUTE });
        const { data } = response;

        const counts = get('counts', data);
        const contentLimit = get('contentLimit', data);

        if (counts) {
            dispatch(setElementCount(counts));
            dispatch(appSetInitialised({ elementCounts: true }));
            dispatch(updateCurrentUser({ changes: { contentLimit }, sync: false }));
        }

        return data;
    } catch (error) {
        manuallyReportError({
            errorMessage: 'Failed to retrieve user counts',
            error,
            sensitive: false,
            level: ROLLBAR_LEVELS.ERROR,
        });
    }

    return null;
};
