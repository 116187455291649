// Lib
import { UnknownAction } from 'redux';

// Types
import { APP_BECAME_HIDDEN, APP_BECAME_VISIBLE } from './appVisibilityTypes';

type AppVisibilityState = {
    isAppVisible: boolean;
};

const initialState: AppVisibilityState = {
    isAppVisible: true,
};

/**
 * Tracks whether the app is currently visible to the user (e.g. in the foreground) or not.
 *
 * NOTE: On desktop, if the view is completely covered by another window it will be considered hidden.
 */
const appVisibilityReducer = (state = initialState, action: UnknownAction): AppVisibilityState => {
    switch (action.type) {
        case APP_BECAME_VISIBLE:
            return { isAppVisible: true };
        case APP_BECAME_HIDDEN:
            return { isAppVisible: false };
        default:
            return state;
    }
};

export default appVisibilityReducer;
