// Lib
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';

// Utils
import { WebCrashReportPlugin } from '../../../capacitor_plugins/webCrashReport';
import { manuallyReportError } from '../../analytics/rollbarService';
import logger from '../../logger';

// Types
import { CAPACITOR_WEB_CRASH_REPORT_PLUGIN } from '../../../capacitor_plugins/pluginConstants';

const useWebCrashReporting = () => {
    useEffect(() => {
        if (!Capacitor.isPluginAvailable(CAPACITOR_WEB_CRASH_REPORT_PLUGIN)) return;

        (async () => {
            const webCrashData = await WebCrashReportPlugin.getLatestUnreportedWebCrash();
            if (webCrashData?.timestamp == null) return;

            // TODO: React to the web view crash reporting. Remove the placeholder alert.
            // NOTE:
            // - The report does NOT come immediately after a crash.
            // - The report comes in AFTER the web app is reloaded after a crash.
            logger.error(`Recovered from crash`);
            manuallyReportError({
                errorMessage: `Device ran out of resources while viewing a board`,
                custom: { timestamp: webCrashData.timestamp },
            });
        })();
    }, []);
};

export default useWebCrashReporting;
