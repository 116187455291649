import { RefObject } from 'react';
import { isNil } from 'lodash';
import { DragState } from '../hooks/useSheetHandlers';
import safeAreaSingleton from '../../../utils/safeAreaSingleton';
import sheetConfig from '../sheetConfig';
import { SheetId } from '../sheetTypes';

// Instead of animating to 0, animate a bit lower to allow for the shadow to naturally fade out
export const SHEET_CLOSED_TRANSFORM_POSITION = -15;

/**
 * Get the height of the area that the sheet can be snapped to.
 * Note that this value is used for calculating the snap height, but the sheet can be dragged past this point.
 */
export const getSheetSnapPointWindowHeight = () => window.innerHeight - safeAreaSingleton.getSafeArea().top;

export const getSnapPointSheetHeight = (snapPoint: number): number => snapPoint * getSheetSnapPointWindowHeight();

/**
 * Set the height of the sheet. Returns new height if the height was changed.
 */
export const setSheetHeight = (sheetRef: RefObject<HTMLDivElement>, height: number | undefined): number | undefined => {
    if (!sheetRef.current || isNil(height)) return;

    // Round to 1 decimal place to avoid subpixel rendering
    const roundedHeight = Math.round(height * 10) / 10;
    const transformStyle = `translateY(${-roundedHeight}px)`;
    if (sheetRef.current.style.transform === transformStyle) return;

    sheetRef.current.style.transform = transformStyle;
    return height;
};

const getNewSheetTop = (dragState: RefObject<DragState>, highestSnapPosition: number): number | undefined => {
    if (!dragState.current) return;

    const { currentY, sheetTouchOffset } = dragState.current;

    const newSheetTop = currentY - sheetTouchOffset;
    // if the sheet has gone past the top snap point, only use half the distance between finger and highest snap point
    const slowDragAdjustment = newSheetTop < highestSnapPosition ? (highestSnapPosition - newSheetTop) / 2 : 0;
    const intendedSheetTop = newSheetTop + slowDragAdjustment;
    return Math.max(intendedSheetTop, 0);
};

export const getNewSheetHeight = (dragState: RefObject<DragState>, highestSnapPosition: number): number | undefined => {
    const newSheetTop = getNewSheetTop(dragState, highestSnapPosition);
    if (isNil(newSheetTop)) return;

    return window.innerHeight - newSheetTop;
};

/*
 * Get the sheet height for a given snap point, with an adjustment that puts it a bit lower if the sheet is closing
 * This is to nicely animate the shadow out
 */
export const getSheetHeightAdjustedForClose = (snapPoint: number) =>
    snapPoint === 0 ? SHEET_CLOSED_TRANSFORM_POSITION : getSnapPointSheetHeight(snapPoint);

export const setContentHeightToMax = (sheetContentRef: RefObject<HTMLDivElement>): void => {
    if (!sheetContentRef.current) return;

    sheetContentRef.current.style.height = '100%';
};

export const setContentHeightToVisibleSpace = (sheetId: SheetId, sheetContentRef: RefObject<HTMLDivElement>): void => {
    if (!sheetContentRef.current || !window.visualViewport) return;

    const newContentHeight = window.innerHeight - sheetContentRef.current?.getBoundingClientRect().top;

    // If the sheet contains an element editor, the sheet content will be updated by mobileEditingMiddleware,
    // in order to make sure that scroll to element logic works correctly. So don't need to update the height here.
    if (sheetConfig[sheetId]?.containsElementEditor) {
        sheetContentRef.current.style.height = `${newContentHeight}px`;
        return;
    }

    sheetContentRef.current.style.height = `calc(${newContentHeight}px - var(--current-keyboard-height))`;
};

export const setSheetHeightVariable = (sheetRef: RefObject<HTMLDivElement>, newHeight: number) => {
    sheetRef.current?.style.setProperty('--sheet-height', `${newHeight}px`);
};
