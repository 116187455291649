// Lib
import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Immutable from 'immutable';
import classNames from 'classnames';

// Utils
import { getUserId } from '../../../../common/users/utils/userPropertyUtils';
import {
    collapseItemsToFit,
    getAdjustedToolbarGridSize,
} from '../../../workspace/toolbar/utils/toolbarToolListCollapseUtils';

// Selectors
import { getCurrentUser } from '../../../user/currentUserSelector';
import { getGridSizeName } from '../../../utils/grid/gridSizeSelector';
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

// Components
import ToolbarMeasure from '../../../workspace/toolbar/components/ToolbarMeasure';
import ToolbarItemsList from '../../../workspace/toolbar/components/ToolbarItemsList';
import { useVirtualKeyboardHeightContext } from '../../../utils/keyboard/VirtualKeyboardHeightContext';

// Constants
import { TOOL_SETS } from '../../../workspace/toolbar/config/toolbarToolConfig';
import { DOCUMENT_MODAL_EDITOR_KEY } from './documentModalConstants';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';

// Styles
import './DocumentModalSidebar.scss';

const DOCUMENT_MODAL_TOOLBAR_ITEMS = [...TOOL_SETS.TEXT_EDITING_INLINE_AND_BLOCK];

interface DocumentModalSidebarProps {
    elementId: string;
    element: ImMNElement;

    isEditable: boolean;
    isEditingDocument: boolean;

    permissions: number;

    dispatchStartEditingDocument: () => void;
}

const DocumentModalSidebar = (props: DocumentModalSidebarProps): ReactElement => {
    const { elementId, isEditable, isEditingDocument, permissions, dispatchStartEditingDocument } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);
    const [availableHeight, setAvailableHeight] = useState(0);

    const virtualKeyboardHeight = useVirtualKeyboardHeightContext();

    const gridSizeName = useSelector(getGridSizeName);

    const currentUser = useSelector(getCurrentUser);
    const currentUserId = getUserId(currentUser);

    // adjust the toolbar grid size based on the available height
    const adjustedGridSize = getAdjustedToolbarGridSize(gridSizeName, availableHeight);

    const renderedToolbarItems = collapseItemsToFit(
        DOCUMENT_MODAL_TOOLBAR_ITEMS,
        availableHeight - virtualKeyboardHeight,
        adjustedGridSize.name,
    );

    const hideSidebarContent = !isEditable || isPlatformLegacyMobileApp(platformDetails);

    const classes = classNames('DocumentModalSidebar', {
        'hide-sidebar-content': hideSidebarContent,
        disabled: !isEditingDocument,
    });

    return (
        <div className={classes} onClick={!isEditingDocument ? dispatchStartEditingDocument : undefined}>
            <ToolbarMeasure setAvailableHeight={setAvailableHeight} />

            {isEditable && (
                <div className="DocumentModalTools">
                    <ToolbarItemsList
                        getComponent={(item) => item.component}
                        toolListId={`${elementId}-document-modal`}
                        items={renderedToolbarItems}
                        currentUser={currentUser}
                        currentUserId={currentUserId}
                        currentBoardId={elementId}
                        gridSize={adjustedGridSize.size}
                        gridSizeName={adjustedGridSize.name}
                        permissions={permissions}
                        selectedElementIds={Immutable.List([elementId])}
                        editorKey={DOCUMENT_MODAL_EDITOR_KEY}
                        hasEditorSelection={false}
                    />
                </div>
            )}
        </div>
    );
};

export default DocumentModalSidebar;
