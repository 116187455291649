import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Components
import SheetContainer, { Content, Overlay, Sheet } from '../../../../../mobile/structural/sheet';
import ColorDisplayToolOption from './ColorDisplayToolOption';

// Utils
import { updateElementsColorDisplay } from './colorDisplayActions';
import { updateCurrentUser } from '../../../../../user/currentUserActions';
import { UseSheetReturnType } from '../../../../../mobile/structural/sheet/hooks/useSheet';
import { ColorObject } from '../../../../../../common/colors/colorObjectUtil';

// Constants
import { COLOR_DISPLAY } from '../../../../../../common/colors/colorConstants';

// Types
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

// Styles
import './MobileColorDisplaySheet.scss';

type MobileColorDisplaySheetProps = {
    sheetProps: UseSheetReturnType;
    selectedElements: Immutable.List<ImMNElement>;
    colorDisplay: ColorObject['space'];
    dispatchCloseSheet: () => void;
};

const MobileColorDisplaySheet = (props: MobileColorDisplaySheetProps): ReactElement => {
    const { sheetProps, selectedElements, colorDisplay, dispatchCloseSheet } = props;

    const dispatch = useDispatch();

    const dispatchConvertToColorDisplay = (colorDisplay: string) => {
        dispatch(
            updateElementsColorDisplay({
                elements: selectedElements,
                colorDisplay,
            }),
        );
        dispatch(
            updateCurrentUser({
                changes: { settings: { preferredColorDisplay: colorDisplay } },
            }),
        );
        dispatchCloseSheet();
    };

    return (
        <SheetContainer className="MobileColorDisplaySheet" {...sheetProps}>
            <Overlay />
            <Sheet>
                <Content>
                    <div className="color-display-content">
                        <ColorDisplayToolOption
                            colorDisplay={COLOR_DISPLAY.HEX}
                            onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.HEX)}
                            isActive={colorDisplay === COLOR_DISPLAY.HEX}
                        />
                        <ColorDisplayToolOption
                            colorDisplay={COLOR_DISPLAY.RGB}
                            onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.RGB)}
                            isActive={colorDisplay === COLOR_DISPLAY.RGB}
                        />
                        <ColorDisplayToolOption
                            colorDisplay={COLOR_DISPLAY.HSL}
                            onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.HSL)}
                            isActive={colorDisplay === COLOR_DISPLAY.HSL}
                        />
                        <ColorDisplayToolOption
                            colorDisplay={COLOR_DISPLAY.OFF}
                            onClick={() => dispatchConvertToColorDisplay(COLOR_DISPLAY.OFF)}
                            isActive={colorDisplay === COLOR_DISPLAY.OFF}
                        />
                    </div>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileColorDisplaySheet;
