import { Capacitor } from '@capacitor/core';
import { IntercomPlugin } from '../../capacitor_plugins/intercom';

// Constants
import { INTERCOM_SHOW_MESSAGES, INTERCOM_SHOW_HELP_CENTER } from './analyticsConstants';
import { CAPACITOR_INTERCOM_PLUGIN_NAME } from '../../capacitor_plugins/pluginConstants';

export const intercomShowMessages = () => (dispatch) => {
    if (Capacitor.isPluginAvailable(CAPACITOR_INTERCOM_PLUGIN_NAME)) {
        IntercomPlugin.presentChat();
    } else {
        dispatch({ type: INTERCOM_SHOW_MESSAGES });
    }
};
export const intercomShowHelpCenter = () => ({ type: INTERCOM_SHOW_HELP_CENTER });
