import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appBecameHidden, appBecameVisible } from './appVisibilityActions';

/**
 * Passes visibility change events to Redux, so that reconnection logic can be triggered.
 *
 * We don't want to show a disconnection spinner every time the app is being restored from the background.
 */
const useAppVisibilityEvents = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const abortController = new AbortController();

        const handleVisibilityChange = () => {
            document.hidden ? dispatch(appBecameHidden()) : dispatch(appBecameVisible());
        };

        window.addEventListener('pageshow', handleVisibilityChange, { signal: abortController.signal });
        document.addEventListener('visibilitychange', handleVisibilityChange, { signal: abortController.signal });

        return () => {
            abortController.abort();
        };
    }, []);
};

export default useAppVisibilityEvents;
