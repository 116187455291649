import * as elementRegistry from '../../../common/elements/elementRegistry';

import ElementAccessDeniedModal from './errorStates/ElementAccessDeniedModal';
import NotFoundElementModal from './errorStates/NotFoundElementModal';
import NoModalComponentElementModal from './errorStates/NoModalComponentElementModal';
import {
    ElementAccessDeniedSheet,
    NoModalComponentElementSheet,
    NotFoundElementSheet,
} from './errorStates/ElementModalErrorSheet';

import { isSkeleton } from '../../../common/elements/utils/elementTypeUtils';
import { getElementType } from '../../../common/elements/utils/elementPropertyUtils';

import { ImMNElement } from '../../../common/elements/elementModelTypes';

export const getModalDisplayElementComponent = (element: ImMNElement) => {
    if (!element) return { DisplayElement: NotFoundElementModal, isError: true };

    if (isSkeleton(element)) return { DisplayElement: ElementAccessDeniedModal, isError: true };

    const DisplayElement = elementRegistry.getElementModalComponent(getElementType(element));
    if (!DisplayElement) return { DisplayElement: NoModalComponentElementModal, isError: true };

    return { DisplayElement, isError: false };
};

export const getModalSheetDisplayElementComponent = (element: ImMNElement) => {
    if (!element) return { DisplayElement: NotFoundElementSheet, isError: true };

    if (isSkeleton(element)) return { DisplayElement: ElementAccessDeniedSheet, isError: true };

    const DisplayElement = elementRegistry.getElementModalSheetComponent(getElementType(element));
    if (!DisplayElement) return { DisplayElement: NoModalComponentElementSheet, isError: true };

    return { DisplayElement, isError: false };
};
