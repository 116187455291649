// Lib
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { isPlatformModernMobileApp } from '../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Actions
import { capacitorLifecycleAction } from '../store/capacitorLifecycleActions';

// Types
import { CapacitorAppStatus } from '../store/capacitorLifecycleTypes';

/**
 * This hook attaches App lifecycle events to the redux store.
 */
const useAttachAppLifecycle = () => {
    const dispatch = useDispatch();

    const platformDetails = useSelector(getPlatformDetailsSelector);

    useEffect(() => {
        // We only want to attach these listeners in the capacitor apps (by default the web
        //  will have the plugin available too)
        if (!isPlatformModernMobileApp(platformDetails)) return;

        if (!Capacitor.isPluginAvailable('App')) return;

        App.addListener('appStateChange', ({ isActive }) =>
            isActive
                ? dispatch(capacitorLifecycleAction(CapacitorAppStatus.ACTIVE, 'appStateChange'))
                : dispatch(capacitorLifecycleAction(CapacitorAppStatus.INACTIVE, 'appStateChange')),
        );
        App.addListener('pause', () => dispatch(capacitorLifecycleAction(CapacitorAppStatus.BACKGROUND, 'pause')));
        // NOTE: Not 100% sure if this is accurate - but seems to be the best fit
        App.addListener('resume', () => dispatch(capacitorLifecycleAction(CapacitorAppStatus.ACTIVE, 'resume')));
    }, []);
};

export default useAttachAppLifecycle;
