import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuRemoveIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-remove"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M18 2.25A3.75 3.75 0 0 1 21.75 6v12A3.75 3.75 0 0 1 18 21.75H6A3.75 3.75 0 0 1 2.25 18V6A3.75 3.75 0 0 1 6 2.25h12zm0 1.5H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75zM7.47 7.47a.75.75 0 0 1 1.06 0L12 10.939l3.47-3.47a.75.75 0 0 1 1.06 1.061L13.061 12l3.47 3.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-1.06 0L12 13.061l-3.47 3.47a.75.75 0 0 1-1.06-1.061L10.939 12l-3.47-3.47a.75.75 0 0 1-.072-.976z"
        />
    </svg>
);
const Memo = memo(ContextMenuRemoveIcon);
export default Memo;
