// Lib
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { elementModalElementIdSet, elementModalElementIdClear } from '../elementModalActions';

const useElementModalElementIdSynchroniser = (elementId: string) => {
    const dispatch = useDispatch();

    // whenever the elementId changes, update the current element id in the store
    useEffect(() => {
        if (!elementId) return;
        dispatch(elementModalElementIdSet(elementId));
    }, [elementId]);

    // clear the current element id when the component is unmounted
    useEffect(() => {
        return () => {
            dispatch(elementModalElementIdClear());
        };
    }, []);
};

export default useElementModalElementIdSynchroniser;
