import { Extension } from '@tiptap/core';
import { TiptapContent } from '../tiptapTypes';
import { isTiptapEditorEmpty } from '../utils/isTiptapEditorEmpty';

export interface SubmitOnDoneOptions {
    /**
     * The command to execute when the user presses the "Done" key.
     */
    onSubmit: (textContent: TiptapContent) => void;
    allowEmpty?: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        submitOnDone: {
            submitOnDone: () => ReturnType;
        };
    }
}

/**
 * Adds a command `submitOnDone` to the editor.
 * This is used in conjunction with the MobileToolbarEditingDoneButton component
 * to trigger the submitOnDone command when the done button is pressed.
 */
export const SubmitOnDone = Extension.create<SubmitOnDoneOptions>({
    name: 'submitOnDone',

    addCommands() {
        return {
            submitOnDone:
                () =>
                ({ editor }) => {
                    const { allowEmpty, onSubmit } = this.options;

                    if (!allowEmpty && isTiptapEditorEmpty(editor)) return false;

                    const currentContent = editor.getJSON();
                    onSubmit(currentContent as TiptapContent);
                    return true;
                },
        };
    },
});
