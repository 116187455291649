// Lib
import React from 'react';
import classNames from 'classnames';

// Components
import FullScreenModal from './FullScreenModal';

// Icons
import Icon from '../icons/Icon';

// Styles
import './FullScreenErrorModal.scss';

type Props = {
    children: React.ReactNode;
    overlay?: boolean;
    close?: () => void;
    className?: string;
};

const FullScreenErrorModal: React.FC<Props> = ({ children, className, overlay, close }) => (
    <FullScreenModal className={classNames('FullScreenErrorModal', className)} overlay={overlay} close={close}>
        <div className="logo">
            <Icon name="error-alert" />
        </div>
        {children}
    </FullScreenModal>
);

export default FullScreenErrorModal;
