import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import usePrevious from '../../../utils/react/usePrevious';
import {
    isAsyncEntityFetching,
    shouldFetchAsyncResource,
} from '../../../utils/services/http/asyncResource/asyncResourceUtils';

import { fetchElementModalElementAndAncestors, refreshParentBoardIfModified } from '../elementModalActions';
import { AsyncResourceEntity } from '../../../utils/services/http/asyncResource/reducers/asyncResourceReducerTypes';

const useElementModalElementFetcher = ({
    elementId,
    urlPermissionId,
    currentElementResourceEntity,
    localCacheHydrationTimestamp,
    socketConnected,
}: {
    elementId: string;
    urlPermissionId: string;
    currentElementResourceEntity: AsyncResourceEntity;
    localCacheHydrationTimestamp: number;
    socketConnected: boolean;
}) => {
    const dispatch = useDispatch();

    const prevSocketConnected = usePrevious(socketConnected);

    // on mount, ensure the element and its ancestors are fetched
    useEffect(() => {
        if (!elementId) return;

        if (shouldFetchAsyncResource(currentElementResourceEntity, localCacheHydrationTimestamp)) {
            dispatch(fetchElementModalElementAndAncestors(elementId, urlPermissionId));
        }
    }, []);

    // If the socket has reconnected, fetch the parent board if it has been modified.
    // This is to cover for cases where the currentBoardId is not accurately set for a modal page.
    useEffect(() => {
        if (!elementId) return;

        const socketHasReconnected = socketConnected && !prevSocketConnected;

        if (!isAsyncEntityFetching(currentElementResourceEntity) && socketHasReconnected) {
            dispatch(refreshParentBoardIfModified(elementId));
        }
    }, [elementId, currentElementResourceEntity, socketConnected]);
};

export default useElementModalElementFetcher;
