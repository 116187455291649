// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../../../../../node_module_clones/recompose';

// Utils
import editorStoreConnect from '../../../../../../components/editor/store/components/editorStoreConnect';
import editorGetCurrentBlock from '../../../../../../components/editor/customRichUtils/editorState/editorGetCurrentBlock';

// Actions
import { closePopup } from '../../../../../../components/popupPanel/popupActions';

// Hooks
import useClosePopupOnUnmount from '../../../../../../components/popupPanel/useClosePopupOnUnmount';

// Selectors
import { popupOpenSelector } from '../../../../../../components/popupPanel/popupOpenSelector';
import { getEditorState } from '../../../../../../components/editor/store/reducers/editorStoreSelector';

// Components
import TextStyleRichTextToolPopupContent from './TextStyleRichTextToolPopup';
import PopupTrigger from '../../../../../../components/popupPanel/PopupTrigger';
import TextStyleRichTextTool from './TextStyleRichTextTool';
import ToolbarPopup from '../../../toolbarPopup/ToolbarPopup';

// Constants
import { STYLE_COMMANDS } from '../../../../../../components/editor/richText/richTextConstants';
import { PopupIds } from '../../../../../../components/popupPanel/popupConstants';

const mapEditorStateToProps = (state) => {
    const editorState = getEditorState(state);

    if (!editorState) return { currentBlockType: STYLE_COMMANDS.UNSTYLED };

    const currentBlock = editorGetCurrentBlock(editorState);
    const blockType = currentBlock?.getType() || STYLE_COMMANDS.UNSTYLED;

    return { currentBlockType: blockType };
};

const mapStateToProps = (state) => ({
    isPopupOpen: popupOpenSelector(PopupIds.BLOCK_STYLE)(state),
});

const mapDispatchToProps = (dispatch) => ({
    closePopup: () => dispatch(closePopup(PopupIds.BLOCK_STYLE)),
});

const TextStyleRichTextToolContainer = (props) => {
    const { item, isPopupOpen, disabled, currentBlockType, showBlockStyleTools } = props;

    const popupArrowColor = currentBlockType === STYLE_COMMANDS.LARGE_HEADING ? 'var(--popup-arrow-color)' : undefined;
    const popupId = item?.uid || item?.id || PopupIds.BLOCK_STYLE;

    useClosePopupOnUnmount(popupId);

    return (
        <div className="TextStyleRichTextToolContainer">
            <PopupTrigger popupId={popupId} disabled={disabled} preventFocus>
                {(popupIsOpen) => <TextStyleRichTextTool isOpen={popupIsOpen} name="Text style" disabled={disabled} />}
            </PopupTrigger>
            <ToolbarPopup
                {...props}
                arrowColor={popupArrowColor}
                popupId={popupId}
                buttonSelector=".TextStyleRichTextTool .icon"
                className="TextStyleRichTextPopup"
                popupIsOpen={isPopupOpen}
            >
                <TextStyleRichTextToolPopupContent {...props} showBlockStyleTools={showBlockStyleTools} />
            </ToolbarPopup>
        </div>
    );
};

TextStyleRichTextToolContainer.propTypes = {
    item: PropTypes.object,
    editorKey: PropTypes.string,
    isPopupOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    showBlockStyleTools: PropTypes.bool,
    dispatchClosePopup: PropTypes.func,
    currentBlockType: PropTypes.string,
    currentCellSelections: PropTypes.object,
    gridSize: PropTypes.number,
    realGridSize: PropTypes.number,
};

const enhancer = compose(editorStoreConnect(mapEditorStateToProps), connect(mapStateToProps, mapDispatchToProps));

export default enhancer(TextStyleRichTextToolContainer);
