// Lib
import { concat, compact, isEmpty } from 'lodash';
import { keyBy } from 'lodash/fp';

// Service
import * as boardSharingService from './boardSharingService';

// Selectors
import {
    getClosestPermissionIdForElementIdSelector,
    getPermissionIdTokenSelector,
} from '../../utils/permissions/permissionsSelector';
import { userLanguagePreferenceSelector } from '../../user/currentUserSelector';

// Actions
import { updateElementACL } from '../../../common/elements/elementActions';
import { loadUsers } from '../../user/userActions';
import { boardPermissionLoad } from '../../utils/permissions/permissionsActions';

// Errors
import { manuallyReportError } from '../../analytics/rollbarService';

// Constants
import { ELEMENT_UPDATE_PERMISSIONS, ELEMENT_UPDATE_PERMISSIONS_ERROR } from './boardSharingConstants';

const keyById = keyBy('_id');

const createBoardPermissionsUpdateAction =
    (updateServiceFn) =>
    ({ boardId, addUserIds = [], addNewUserEmails = [], updateUserIds = [], removeUserIds = [], ...rest }) =>
    async (dispatch, getState) => {
        dispatch({
            type: ELEMENT_UPDATE_PERMISSIONS,
            id: boardId,
            addUserIds,
            addNewUserEmails,
            updateUserIds,
            removeUserIds,
        });

        try {
            const state = getState();
            const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId: boardId });
            const permissionToken = getPermissionIdTokenSelector(state, { permissionId });
            const locale = userLanguagePreferenceSelector(state);

            const response = await updateServiceFn({
                boardId,
                addUserIds,
                addNewUserEmails,
                updateUserIds,
                removeUserIds,
                permissionToken,
                locale,
                ...rest,
            });

            const { element, addedUsers } = response.data;

            if (!isEmpty(addedUsers)) {
                const addedUsersMap = keyById(addedUsers);
                dispatch(loadUsers(addedUsersMap));
            }

            dispatch(
                updateElementACL({
                    id: element.id,
                    acl: element.acl,
                    addUserIds,
                    addNewUserEmails,
                    updateUserIds,
                    removeUserIds,
                    addedUsers,
                    sync: false,
                }),
            );

            return response.data;
        } catch (error) {
            const errorMessage = error.message;

            const userIds = compact(concat(addUserIds, updateUserIds, removeUserIds, addNewUserEmails));

            manuallyReportError({
                errorMessage,
                error,
            });

            dispatch({
                type: ELEMENT_UPDATE_PERMISSIONS_ERROR,
                id: boardId,
                error: errorMessage,
                userIds,
            });

            throw error;
        }
    };

// Throttled route
export const addUsersToBoard = createBoardPermissionsUpdateAction(boardSharingService.addBoardPermissions);
const putBoardOwnerUpdate = createBoardPermissionsUpdateAction(boardSharingService.changeBoardOwner);
export const removeUsersFromBoard = createBoardPermissionsUpdateAction(boardSharingService.updateBoardPermissions);
export const updateUserPermissionsForBoard = createBoardPermissionsUpdateAction(
    boardSharingService.updateBoardPermissions,
);

export const publishBoard = createBoardPermissionsUpdateAction(boardSharingService.publishBoard);
export const unpublishBoard = createBoardPermissionsUpdateAction(boardSharingService.unpublishBoard);

export const enableBoardPublicEditing = createBoardPermissionsUpdateAction(
    boardSharingService.enableBoardPublicEditing,
);
export const disableBoardPublicEditing = createBoardPermissionsUpdateAction(
    boardSharingService.disableBoardPublicEditing,
);

export const transferBoardOwnership =
    ({ boardId, newOwnerId }) =>
    (dispatch) =>
        dispatch(putBoardOwnerUpdate({ boardId, updateUserIds: [newOwnerId] }));

export const resetBoardPermissionLink =
    ({ boardId, permissionId }) =>
    async (dispatch) => {
        const response = await boardSharingService.resetBoardPermissionLinkHttp({ boardId, permissionId });
        const { permission } = response.data;
        return dispatch(boardPermissionLoad({ permissions: [permission] }));
    };
