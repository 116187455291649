// Types
import { Action } from 'redux';
import { SOCKET_CONNECT } from '../../utils/socket/socketConstants';
import { BaseTimerVisibilityState } from './baseTimerVisibilityState';
import { VisibleConnectionStatus } from './visibleConnectionStatusTypes';
import { LOGIN_SUCCESS, REGISTER_SUCCESS } from '../../auth/authConstants';

/**
 * The socket isn't connected - a "Trying to reconnect" status message will be shown.
 *
 * NOTE: This state uses a timeout to transition to the disconnected state.
 */
export class InterruptedState extends BaseTimerVisibilityState {
    static stateKey = VisibleConnectionStatus.interrupted;

    nextState(action: Action) {
        switch (action.type) {
            case SOCKET_CONNECT:
                return VisibleConnectionStatus.connected;
            case this.timeoutActionName:
                return VisibleConnectionStatus.disconnected;
            case LOGIN_SUCCESS:
            case REGISTER_SUCCESS:
                return VisibleConnectionStatus.connecting;
            default:
                return undefined;
        }
    }
}
