// Lib
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// Utils
import { getHeaderAvoidanceInsets } from '../../components/tooltips/tooltipInsetUtils';

// Selectors
import { isPopupIdOpenSelector } from '../../components/popupPanel/popupOpenSelector';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getCurrentBoard } from '../selectors/currentBoardSelector';

// Actions
import { closePopup } from '../../components/popupPanel/popupActions';

// Hooks
import { useVirtualKeyboardHeightContext } from '../../utils/keyboard/VirtualKeyboardHeightContext';

// Components
import PopupPanelMouseManager from '../../components/popupPanel/PopupPanelMouseManager';
import FloatingPanel from '../../components/tooltips/FloatingPanel';
import DesktopReactionPopupContent from '../../components/emoji/popup/DesktopReactionPopupContent';

// Constants
import { TooltipPositions } from '../../components/tooltips/tooltipConstants';

type ElementReactionPopupProps = {
    className: string;
    popupId: string;
    elementIds: string[];
};

const ElementReactionPopupContent: React.FC<ElementReactionPopupProps> = (props) => {
    const { className, popupId } = props;

    const gridSize = useSelector(getGridSize);
    const currentBoard = useSelector(getCurrentBoard);

    const dispatch = useDispatch();
    const dispatchClosePopup = useCallback(() => dispatch(closePopup(popupId)), [popupId]);

    const virtualKeyboardHeight = useVirtualKeyboardHeightContext();

    return (
        <FloatingPanel
            selector={`.popup-trigger-${popupId}`}
            className={classNames('ReactionPopup', className, 'light')}
            arrowColor="var(--ui-modal-background-primary)"
            arrowStrokeColor="var(--ui-background-primary)"
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={0}
            arrowStyle={{ transform: 'translate(0px, 1px)' }}
            distance={15}
            offset={2}
            position={TooltipPositions.RIGHT}
            gridSize={gridSize}
            responsive
            pollPosition
            insets={getHeaderAvoidanceInsets(gridSize, currentBoard, virtualKeyboardHeight)}
        >
            <PopupPanelMouseManager
                {...props}
                gridSize={gridSize}
                visible
                popupId={popupId}
                closePopup={dispatchClosePopup}
            >
                <div className="popup-content">
                    <DesktopReactionPopupContent {...props} gridSize={gridSize} closePopup={dispatchClosePopup} />
                </div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

const ElementReactionPopup: React.FC<ElementReactionPopupProps> = (props) => {
    // @ts-ignore The selector does take two args
    const isPopupOpen = useSelector((state) => isPopupIdOpenSelector(state, props));

    if (!isPopupOpen) return null;

    return <ElementReactionPopupContent {...props} />;
};

export default ElementReactionPopup;
