// Utils
import { prop } from '../../../common/utils/immutableHelper';

// Constants
import { SocketConnectionStatus, SocketReconnectionMode, SocketState } from './socketConstants';

export { isSocketConnectionInitialised } from '../../app/initialisation/initialisationSelector';
export { selectVisibleConnectionStatus } from '../../offline/visibleConnectionStatus/visibleConnectionStatusSelector';

export const selectSocketConnection = (state: any): SocketState => state.getIn(['app', 'socketConnection']);
export const selectSocketConnectionTime = (state: any): number =>
    state.getIn(['app', 'socketConnection', 'connectionTime']);
export const selectSocketInterruptionTime = (state: any): number =>
    state.getIn(['app', 'socketConnection', 'interruptionTime']);
export const selectSocketDisconnectionTime = (state: any): number =>
    state.getIn(['app', 'socketConnection', 'disconnectionTime']);
export const selectSocketConnectionStatus = (state: any): SocketConnectionStatus =>
    state.getIn(['app', 'socketConnection', 'status']);
export const selectSocketReconnectionMode = (state: any): SocketReconnectionMode =>
    state.getIn(['app', 'socketConnection', 'reconnectionMode']);

export const selectIsSocketConnected = (state: any): boolean =>
    selectSocketConnectionStatus(state) === SocketConnectionStatus.CONNECTED;
export const selectIsSocketDisconnected = (state: any): boolean =>
    selectSocketConnectionStatus(state) === SocketConnectionStatus.DISCONNECTED;

// Property selectors
export const isSocketDisconnected = (socketConnection: SocketState): boolean =>
    prop('status', socketConnection) === SocketConnectionStatus.DISCONNECTED;
