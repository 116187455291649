import { TIMES } from '../../../common/utils/timeUtil';

export enum SocketConnectionStatus {
    CONNECTED = 'CONNECTED',
    INTERRUPTED = 'INTERRUPTED',
    DISCONNECTED = 'DISCONNECTED',
}

export enum SocketReconnectionMode {
    APP_LOAD = 'APP_LOAD',
    CONNECTION_LOST = 'CONNECTION_LOST',
    REHYDRATION = 'REHYDRATION',
    APP_WAKE = 'APP_WAKE',
}

export type SocketState = {
    status: SocketConnectionStatus;
    reconnectionMode: SocketReconnectionMode;
    connectionTime?: number;
    interruptionTime?: number;
    disconnectionTime?: number;
    channels: string[];
    boardChannels: string[];
};

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_INTERRUPTION = 'SOCKET_INTERRUPTION';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';

export const SOCKET_FORCE_CONNECT = 'SOCKET_FORCE_CONNECT';
export const SOCKET_FORCE_DISCONNECT = 'SOCKET_FORCE_DISCONNECT';

export const CHANNELS_UPDATED = 'CHANNELS_UPDATED';

export enum SocketError {
    CONNECTION_FAILURE = 'CONNECTION_FAILURE',
    ACTION_FAILURE = 'ACTION_FAILURE',
    ACTION_LIMIT_EXCEEDED = 'ACTION_LIMIT_EXCEEDED',
}

export const SOCKET_INTERRUPTION_TIMEOUT = 10 * TIMES.SECOND;
