// Lib
import React, { useCallback } from 'react';

// Utils
import { stopPropagationOnly } from '../../utils/domUtil';

// Hooks
import usePreventTouchScroll from '../../utils/dom/usePreventTouchScroll';

// Components
import controlPointDragSource from './controlPointDragSource';
import ControlPointDragHandle from './ControlPointDragHandle';

// Types
import { Point } from '../../../common/maths/geometry/pointTypes';
import { ImMNElement } from '../../../common/elements/elementModelTypes';

const ControlPointDragHandleDragSource = controlPointDragSource(ControlPointDragHandle);

interface ConnectedControlPointDragHandleProps {
    controlPoint: Point;

    show: boolean;

    updateLineControlPoint: (args: { control: Point | null }) => void;
    dispatchPointDragStart: () => void;
    toggleLineVisibility: () => void;

    gridSize: number;
    element: ImMNElement;
    elementId: string;
}

const ConnectedControlPointDragHandle: React.FC<ConnectedControlPointDragHandleProps> = (props) => {
    const {
        elementId,
        element,
        updateLineControlPoint,
        toggleLineVisibility,
        dispatchPointDragStart,
        controlPoint,
        show,
    } = props;

    const { onPointerDown } = usePreventTouchScroll();

    const onDoubleClick = useCallback(
        (event: MouseEvent) => {
            updateLineControlPoint({ control: null });

            event.stopPropagation();
            event.preventDefault();
        },
        [updateLineControlPoint],
    );

    if (!show) return null;

    return (
        <ControlPointDragHandleDragSource
            elementId={elementId}
            element={element}
            toggleLineVisibility={toggleLineVisibility}
            dispatchPointDragStart={dispatchPointDragStart}
            updateLineControlPoint={updateLineControlPoint}
            // @ts-ignore - PropTypes of controlPointDragSource doesn't get passed through props of sub-component
            isHitArea
            show
            onPointerDown={onPointerDown}
            onMouseDown={stopPropagationOnly}
            onDoubleClick={onDoubleClick}
            pos={controlPoint}
        />
    );
};

export default ConnectedControlPointDragHandle;
