// Lib
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { closePopupIfOpen } from './popupActions';

/**
 * Will close a popup with the given id when this component unmounts.
 */
const useClosePopupOnUnmount = (popupId: string) => {
    const dispatch = useDispatch();

    useEffect(
        () => () => {
            dispatch(closePopupIfOpen(popupId));
        },
        [],
    );
};

export default useClosePopupOnUnmount;
