import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEditingSelector } from '../../selectors/elementSelector';
import { startEditingElement } from '../../selection/selectionActions';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { DOCUMENT_MODAL_EDITOR_KEY } from './documentModalConstants';
import { Point } from '../../../../common/maths/geometry/pointTypes';
import { getSpecificEditorId } from '../../utils/elementEditorUtils';

const useTipTapDocumentEditingProps = (elementId: string, element: ImMNElement) => {
    const defaultEditorKey = DOCUMENT_MODAL_EDITOR_KEY;
    const defaultEditorId = getSpecificEditorId(elementId, defaultEditorKey);

    const dispatch = useDispatch();

    const { isEditing, currentEditorKey, currentEditorId } = useSelector((state) =>
        // @ts-ignore The types of createShallowSelector must not be quite right
        isEditingSelector()(state, { elementId, element }),
    );

    const dispatchStartEditing = useCallback(
        ({
            editorId,
            editorKey,
            editorFocusClientCoords,
        }: {
            editorId: string;
            editorKey: string;
            editorFocusClientCoords?: Point;
        }): void => {
            dispatch(
                startEditingElement({
                    id: elementId,
                    editorId,
                    editorKey,
                    transactionId: undefined,
                    canUndo: true,
                    editorFocusClientCoords,
                }),
            );
        },
        [dispatch, elementId],
    );

    const dispatchStartEditingDocument = useCallback((): void => {
        dispatchStartEditing({
            editorId: defaultEditorId,
            editorKey: defaultEditorKey,
            editorFocusClientCoords: { x: 0, y: 0 },
        });
    }, [dispatchStartEditing, defaultEditorKey]);

    return {
        editorId: defaultEditorId,
        editorKey: defaultEditorKey,
        isEditing,
        currentEditorKey,
        currentEditorId,
        dispatchStartEditing,
        dispatchStartEditingDocument,
    };
};

export default useTipTapDocumentEditingProps;
