import { produce } from 'immer';
import { LOCATION_CHANGE, LocationChangeAction } from 'react-router-redux';

import { getSheetKey } from './sheetSelectors';

import { SheetAction, SheetActionTypes } from './sheetActionTypes';
import { SheetReducerState } from './sheetTypes';

const INITIAL_STATE: SheetReducerState = {};

export default (state = INITIAL_STATE, action: SheetAction | LocationChangeAction) => {
    switch (action.type) {
        case SheetActionTypes.SHEET_OPEN:
            return produce(state, (draft) => {
                const { sheetId, instanceId, ...rest } = action;

                const sheetKey = getSheetKey(sheetId, instanceId);
                if (state[sheetKey]?.active === true) return;

                if (!sheetId) return;

                draft[sheetKey] = {
                    active: true,
                    sheetId,
                    activeSnapPoint: 0,
                    ...rest,
                };
            });
        case SheetActionTypes.SHEET_CLOSE:
            return produce(state, (draft) => {
                const { sheetId, instanceId } = action;

                const sheetKey = getSheetKey(sheetId, instanceId);
                delete draft[sheetKey];
            });
        case SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT:
            return produce(state, (draft) => {
                const { sheetId, instanceId } = action;

                const sheetKey = getSheetKey(sheetId, instanceId);
                const sheet = draft[sheetKey];
                if (!sheet) return;

                sheet.activeSnapPoint = action.activeSnapPoint;
            });
        case LOCATION_CHANGE:
            return produce(state, (draft) => {
                Object.keys(draft).forEach((sheetKey) => {
                    const sheet = draft[sheetKey];
                    if (!sheet?.config?.stayActiveOnLocationChange) {
                        delete draft[sheetKey];
                    }
                });
            });
        default:
            return state;
    }
};
