// Lib
import { createSelector } from 'reselect';
import { min } from 'lodash/fp';

// Utils
import {
    getNotificationActorIdsAsArray,
    getNotificationCommentThreadIdsAsArray,
    getNotificationElementId,
    getNotificationShareActorId,
    getNotificationTimestamp,
    isCommentNotification,
} from '../../common/notifications/notificationModelUtils';
import { getClosestUpBoardId } from '../../common/elements/utils/elementTraversalUtils';
import { getPathToTopLevelSharedBoard } from '../../common/permissions/elementPermissionsUtil';

// Selectors
import { getElements } from '../element/selectors/elementsSelector';
import { getUsers } from '../user/usersSelector';
import { getCurrentBoardId } from '../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentUserId } from '../user/currentUserSelector';
import { getAsyncResourceEntityState } from '../utils/services/http/asyncResource/asyncResourceSelector';
import {
    getAsyncEntityErrorTime,
    getAsyncEntityFetchedTime,
    isAsyncEntityError,
    isAsyncEntityFetched,
    isAsyncEntityFetching,
} from '../utils/services/http/asyncResource/asyncResourceUtils';

// Constants
import { ResourceTypes } from '../utils/services/http/asyncResource/asyncResourceConstants';

export const orderNotificationsByDescendingTimestamp = (notificationA, notificationB) =>
    getNotificationTimestamp(notificationB) - getNotificationTimestamp(notificationA);

export const isFetchingNotificationsSelector = (state) =>
    isAsyncEntityFetching(getAsyncResourceEntityState(state, ResourceTypes.notifications));
export const isFetchedNotificationsSelector = (state) =>
    isAsyncEntityFetched(getAsyncResourceEntityState(state, ResourceTypes.notifications));
export const hasNotificationsFetchErrorSelector = (state) =>
    isAsyncEntityError(getAsyncResourceEntityState(state, ResourceTypes.notifications));
export const getNotificationsFetchedTimestamp = (state) =>
    getAsyncEntityFetchedTime(getAsyncResourceEntityState(state, ResourceTypes.notifications));
export const getNotificationsErrorTimestamp = (state) =>
    getAsyncEntityErrorTime(getAsyncResourceEntityState(state, ResourceTypes.notifications));

export const getNotificationsSelector = (state) => state.getIn(['notifications', 'data']);
export const getHasMoreNotificationsSelector = (state) => state.getIn(['notifications', 'hasMore']);

const getNotificationTimes = (state) => {
    const notifications = getNotificationsSelector(state);
    return notifications.map(getNotificationTimestamp).toArray();
};

export const getOldestNotificationTime = (state) => min(getNotificationTimes(state));

export const enhancedNotificationSelector = createSelector(
    (state, ownProps) => ownProps.notification,
    getElements,
    getUsers,
    getCurrentBoardId,
    getCurrentUserId,
    (notification, elements, users, currentBoardId, currentUserId) => {
        if (!notification) return {};

        const returnObj = {
            notification,
            currentBoardId,
        };

        let elementId = getNotificationElementId(notification);

        if (isCommentNotification(notification)) {
            const commentThreadIds = getNotificationCommentThreadIdsAsArray(notification);

            // If we only have one comment thread ID then use its new location if its been moved
            if (commentThreadIds.size === 1) {
                const commentThreadId = commentThreadIds.first();
                const closestBoardId = getClosestUpBoardId(elements, commentThreadId);

                if (!!closestBoardId) {
                    elementId = closestBoardId;
                }
            }
        }

        // Get the element and its ancestors
        if (elementId) {
            const [element, ...ancestors] = getPathToTopLevelSharedBoard(elements, elementId, currentUserId);

            returnObj.element = element;
            returnObj.ancestors = ancestors;
        }

        const actorIds = getNotificationActorIdsAsArray(notification);

        actorIds.length && (returnObj.actors = actorIds.map((actorId) => users.get(actorId)));

        const shareActorId = getNotificationShareActorId(notification);
        shareActorId && (returnObj.shareActor = users.get(shareActorId));

        return returnObj;
    },
);
