import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { propIn } from '../../common/utils/immutableHelper';
import { Capacitor } from '@capacitor/core';

const selectAppTheme = propIn(['app', 'theme', 'theme']);

const milanoteToCapacitorTheme: Record<string, string> = {
    ['light-theme']: 'LIGHT',
    ['dark-theme']: 'DARK',
};

const setKeyboardTheme = async (theme: string) => {
    if (!Capacitor.isPluginAvailable('Keyboard')) return;
    const { Keyboard } = await import('@capacitor/keyboard'); // can't import this on web or it'll throw an error

    // Type is actually Keyboard.KeyboardStyle but TS can't handle dynamic imports
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Keyboard.setStyle({ style: theme as any });
};

const setStatusBarTheme = async (theme: string) => {
    if (!Capacitor.isPluginAvailable('StatusBar')) return;
    const { StatusBar } = await import('@capacitor/status-bar'); // can't import this on web or it'll throw an error

    // Like above -- this one is of type StatusBar.Style
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    StatusBar.setStyle({ style: theme as any });
};

const useNativeThemeManager = () => {
    const theme = useSelector(selectAppTheme);
    useEffect(() => {
        const capacitorTheme = milanoteToCapacitorTheme[theme];

        setKeyboardTheme(capacitorTheme);
        setStatusBarTheme(capacitorTheme);
    }, [theme]);
};

export default useNativeThemeManager;
