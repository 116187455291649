// Utils
import {
    enableAmplitudeTracking as enableAmplitudeTrackingJS,
    setAmplitudeUser as setAmplitudeUserJS,
    clearAmplitudeUser as clearAmplitudeUserJS,
    sendAmplitudeUserProperties as sendAmplitudeUserPropertiesJS,
    sendAmplitudeEvent as sendAmplitudeEventJS,
    initialiseAmplitude as initialiseAmplitudeJS,
    initialiseAmplitudeData as initialiseAmplitudeDataJS,
    getAmplitudeDeviceId as getAmplitudeDeviceIdJS,
} from './amplitudeServiceJS';

import {
    setAmplitudeUser as setAmplitudeUserSwift,
    clearAmplitudeUser as clearAmplitudeUserSwift,
    sendAmplitudeUserProperties as sendAmplitudeUserPropertiesSwift,
    sendAmplitudeEvent as sendAmplitudeEventSwift,
    initialiseAmplitude as initialiseAmplitudeSwift,
} from './amplitudeServiceSwift';

import {
    setAmplitudeUser as setAmplitudeUserCapacitor,
    clearAmplitudeUder as clearAmplitudeUserCapacitor,
    sendAmplitudeUserProperties as sendAmplitudeUserPropertiesCapacitor,
    sendAmplitudeEvent as sendAmplitudeEventCapacitor,
} from './amplitudeServiceCapacitor';

import getClientConfig from '../utils/getClientConfig';
import { Capacitor } from '@capacitor/core';
import { CAPACITOR_AMPLITUDE_PLUGIN } from '../../capacitor_plugins/pluginConstants';

const passToSwift = () => getClientConfig().analytics?.passToSwift;
const passToCapacitor = () => Capacitor.isPluginAvailable(CAPACITOR_AMPLITUDE_PLUGIN);

const noOp = () => {};

export const getAmplitudeDeviceId = passToSwift() ? noOp : getAmplitudeDeviceIdJS;
export const enableAmplitudeTracking = passToSwift() ? noOp : enableAmplitudeTrackingJS;

export const setAmplitudeUser = passToSwift()
    ? setAmplitudeUserSwift
    : passToCapacitor()
    ? setAmplitudeUserCapacitor
    : setAmplitudeUserJS;

export const clearAmplitudeUser = passToSwift()
    ? clearAmplitudeUserSwift
    : passToCapacitor()
    ? clearAmplitudeUserCapacitor
    : clearAmplitudeUserJS;

export const sendAmplitudeUserProperties = passToSwift()
    ? sendAmplitudeUserPropertiesSwift
    : passToCapacitor()
    ? sendAmplitudeUserPropertiesCapacitor
    : sendAmplitudeUserPropertiesJS;

export const sendAmplitudeEvent = passToSwift()
    ? sendAmplitudeEventSwift
    : passToCapacitor()
    ? sendAmplitudeEventCapacitor
    : sendAmplitudeEventJS;

export const initialiseAmplitude = passToSwift() ? initialiseAmplitudeSwift : initialiseAmplitudeJS;
export const initialiseAmplitudeData = passToSwift() ? noOp : initialiseAmplitudeDataJS;
