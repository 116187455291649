// Lib
import React, { useRef, useState } from 'react';

// Hooks
import useSheetInitialisation from './useSheetInitialisation';
import useSheetHandlers, { SheetHandlers } from './useSheetHandlers';
import useSheetSnapPointState from './useSheetSnapPointState';
import useSheetObstructionHandler from './useSheetObstructionHandler';

// Types
import { SheetProps } from '../SheetContainer';

export const MAX_SUFFIX = 'max';

export interface SheetContextState extends SheetHandlers {
    sheetContentRef: React.RefObject<HTMLDivElement>;
    sheetRef: React.RefObject<HTMLDivElement>;
    snapPointsState: number[];
    isSheetMounted: boolean;
    setIsSheetMounted: (isMounted: boolean) => void;
    dispatchCloseSheet: () => void;
}

const useSheetContextState = (sheetProps: SheetProps): SheetContextState => {
    const {
        sheetRef,
        sheetContentRef,
        sheetKey,
        snapPoints = [],
        isSheetOpen,
        dispatchCloseSheet,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        preventDismiss = false,
    } = sheetProps;

    const [isSheetMounted, setIsSheetMounted] = useState(false);

    const { snapPointsState, addSnapPoint } = useSheetSnapPointState(
        sheetProps,
        snapPoints,
        sheetRef,
        sheetContentRef,
        activeSnapPoint,
        preventDismiss,
    );

    // Managed by useSheetHandlers
    const cancelInProgressDragAnimationRef = useRef<(() => void) | null>(null);

    // Managed by useSheetInitialisation
    const cancelInProgressCloseTransitionRef = useRef<(() => void) | null>(null);

    // Managed by useSheetInitialisation > setInitialSheetHeight
    const cancelInitialHeightAnimationFrameRef = useRef<(() => void) | null>(null);

    const cancelInProgressAnimations = () => {
        cancelInProgressCloseTransitionRef.current?.();

        // Cancel any in-progress drag animations that run from when sheet drag ends until it reaches the snap point
        cancelInProgressDragAnimationRef.current?.();

        cancelInitialHeightAnimationFrameRef.current?.();
    };

    const sheetInitialised = useSheetInitialisation(
        sheetProps,
        addSnapPoint,
        isSheetMounted,
        setIsSheetMounted,
        cancelInProgressCloseTransitionRef,
        cancelInitialHeightAnimationFrameRef,
        cancelInProgressAnimations,
    );

    const handlers = useSheetHandlers(
        sheetProps,
        sheetInitialised,
        snapPointsState,
        cancelInProgressDragAnimationRef,
        setIsSheetMounted,
    );

    useSheetObstructionHandler(sheetKey, isSheetOpen, snapPoints, dispatchUpdateActiveSnapPoint);

    return {
        ...handlers,
        sheetContentRef,
        sheetRef,
        snapPointsState,
        dispatchCloseSheet,
        isSheetMounted,
        setIsSheetMounted,
    };
};

export default useSheetContextState;
