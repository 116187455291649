// Lib
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getLineWeight } from '../../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { updateSelectedElements } from '../../../../../element/actions/elementActions';

// Selectors
import { getSelectedElements } from '../../../../../element/selection/selectedElementsSelector';

// Components
import Icon from '../../../../../components/icons/Icon';
import Tool from '../../Tool';

// Constants
import { LINE_WEIGHT } from '../../../../../../common/lines/lineConstants';

type LineWeightToolOptionProps = {
    lineWeight: number;
    currentLineWeight: number;
    iconName: string;
    onClick: () => void;
};

const LineWeightToolOption = ({ lineWeight, currentLineWeight, iconName, onClick }: LineWeightToolOptionProps) => {
    return (
        <Tool onClick={onClick} isActive={currentLineWeight === lineWeight}>
            <Icon name={iconName} />
        </Tool>
    );
};

type LineWeightPopupContentProps = {
    close: () => void;
};

const LineWeightPopupContent = (props: LineWeightPopupContentProps) => {
    const selectedElements = useSelector(getSelectedElements);
    const selectedElement = selectedElements.first();
    const lineWeight = getLineWeight(selectedElement);

    const dispatch = useDispatch();

    const dispatchUpdateSelectedElements = ({ changes }: { changes: { lineWeight: number } }) =>
        dispatch(updateSelectedElements({ changes }));

    const setLineWeight = (newLineWeight: number) => {
        dispatchUpdateSelectedElements({
            changes: {
                lineWeight: newLineWeight,
            },
        });

        if (props.close) {
            props.close();
        }
    };

    return (
        <>
            <LineWeightToolOption
                lineWeight={LINE_WEIGHT.S}
                currentLineWeight={lineWeight}
                iconName="toolbar-line-weight-1-px"
                onClick={() => setLineWeight(LINE_WEIGHT.S)}
            />
            <LineWeightToolOption
                lineWeight={LINE_WEIGHT.M}
                currentLineWeight={lineWeight}
                iconName="toolbar-line-weight-2-px"
                onClick={() => setLineWeight(LINE_WEIGHT.M)}
            />
            <LineWeightToolOption
                lineWeight={LINE_WEIGHT.L}
                currentLineWeight={lineWeight}
                iconName="toolbar-line-weight-4-px"
                onClick={() => setLineWeight(LINE_WEIGHT.L)}
            />
        </>
    );
};

export default LineWeightPopupContent;
