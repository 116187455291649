import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { constant, identity } from 'lodash';
import { createStructuredSelector } from 'reselect';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { isInList } from '../../../common/inList/inListUtils';
import { toCssPx } from '../../utils/cssUtil';
import { gridPointsToPixels } from '../../utils/grid/gridUtils';

// Actions
import { startEditingElement } from '../selection/selectionActions';

// Components
import ColorSwatch from './ColorSwatch';
import ColorSwatchPicker from './ColorSwatchPicker';
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';
import elementSaveMediaResizeDecorator from '../resizing/elementSaveMediaResizeDecorator';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import colorSwatchDoubleClickResizeDecorator from './colorSwatchDoubleClickResizeDecorator';
import ImageColorPaletteObserver from '../../components/images/imageColorExtraction/ImageColorPaletteObserver';

// Constants
import {
    COLOR_SWATCH_MIN_HEIGHT_GRID,
    COLOR_SWATCH_MIN_WIDTH_GRID,
    COLOR_SWATCH_COLOR_PICKER_EDITOR_KEY,
    COLOR_SWATCH_CONTAINER_IN_LIST_PADDING_GU,
} from '../../../common/colorSwatches/colorSwatchesConstants';

import './ColorSwatchContainer.scss';

const mapStateToProps = createStructuredSelector({
    gridSize: getGridSize,
});

const mapDispatchToProps = (dispatch, { element }) => ({
    dispatchStartEditingColorPicker: () =>
        dispatch(
            startEditingElement({
                id: getElementId(element),
                editorId: `${getElementId(element)}-${COLOR_SWATCH_COLOR_PICKER_EDITOR_KEY}`,
                editorKey: COLOR_SWATCH_COLOR_PICKER_EDITOR_KEY,
                preventVirtualKeyboard: true,
            }),
        ),
});

@connect(mapStateToProps, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys', 'canDrop'])
@captionContainerDecorator
@elementResizeDecorator({
    getMinWidth: constant(COLOR_SWATCH_MIN_WIDTH_GRID),
    enableHeight: true,
})
@elementSaveMediaResizeDecorator({
    getMinWidth: constant(COLOR_SWATCH_MIN_WIDTH_GRID),
    getMinHeight: constant(COLOR_SWATCH_MIN_HEIGHT_GRID),
    getIsEnabled: () => true,
})
@colorSwatchDoubleClickResizeDecorator
class ColorSwatchContainer extends React.Component {
    setElementSize = (size) => {
        const { setElementSize } = this.props;
        return setElementSize(size);
    };

    handleKey = (event) => {
        if (!this.props.isSelected) return;
        this.props.captionKeyHandler(event, this.props);
    };

    render() {
        const {
            connectLineEdgeDropTarget,
            canConnectLineEdge,
            dispatchStartEditingColorPicker,
            currentEditorId,
            element,
            inList,
            gridSize,
        } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        const isElementInList = isInList(inList);
        const elementContainerPaddingPx = isElementInList
            ? gridPointsToPixels(COLOR_SWATCH_CONTAINER_IN_LIST_PADDING_GU, gridSize)
            : 0;

        const style = {
            '--color-swatch-container-padding': toCssPx(elementContainerPaddingPx),
        };

        return lineEdgeConnector(
            <div className="ColorSwatchContainer" style={style}>
                {isElementInList && <div className="background" />}
                <ImageColorPaletteObserver element={element} />
                <ColorSwatchPicker currentEditorId={currentEditorId} element={element} />
                <ColorSwatch
                    {...this.props}
                    handleKey={this.handleKey}
                    setElementSize={this.setElementSize}
                    elementContainerPaddingPx={elementContainerPaddingPx}
                    cropToGrid={!this.props.isResizing && !isElementInList}
                    onSwatchDoubleClick={dispatchStartEditingColorPicker}
                />
            </div>,
        );
    }
}

ColorSwatchContainer.propTypes = {
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    isSelected: PropTypes.bool,
    captionKeyHandler: PropTypes.func,
    inList: PropTypes.string,
    gridSize: PropTypes.number,
    setElementSize: PropTypes.func,
    boardSection: PropTypes.string,
    canConnectLineEdge: PropTypes.bool,
    isResizing: PropTypes.bool,
    dispatchStartEditingColorPicker: PropTypes.func,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
};

export default ColorSwatchContainer;
