// Constants
import { ErrorModalActionTypes, ErrorModalCloseAction, ErrorModalOpenAction } from './errorModalTypes';

export const openErrorModal = (modalId: string, data?: object): ErrorModalOpenAction => ({
    type: ErrorModalActionTypes.ERROR_MODAL_OPEN,
    modalId,
    data,
});

export const closeErrorModal = (): ErrorModalCloseAction => ({
    type: ErrorModalActionTypes.ERROR_MODAL_CLOSE,
});
