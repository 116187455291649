import React, { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

// Utils
import { isPlatformAndroid } from '../../platform/utils/platformDetailsUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Components
import ElementColorPicker from '../../components/colors/ElementColorPicker';

// Constants
import { COLOR_SWATCH_COLOR_PICKER_EDITOR_KEY } from '../../../common/colorSwatches/colorSwatchesConstants';

// Utils
import { getSpecificEditorId } from '../utils/elementEditorUtils';
import { OpenColorPickerEvent } from '../../workspace/toolbar/components/selectionTools/colorTools/OpenColorPickerEvent';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { PlatformDetails } from '../../../common/platform/platformTypes';

const shouldShowColorPicker = (platformDetails: PlatformDetails) =>
    // Currently (September 2023) the Android app displays an old Android picker that doesn't look good.
    // This might be re-enabled in the future once we find a way to replace the picker.
    !isPlatformAndroid(platformDetails);

type ColorSwatchPickerProps = {
    currentEditorId: string;
    element: ImMNElement;
};

const ColorSwatchPicker = ({ currentEditorId, element }: ColorSwatchPickerProps): ReactElement | null => {
    const platformDetails = useSelector(getPlatformDetailsSelector);

    const isEditing =
        currentEditorId === getSpecificEditorId(getElementId(element), COLOR_SWATCH_COLOR_PICKER_EDITOR_KEY);
    const isPickingColor = shouldShowColorPicker(platformDetails) && isEditing;
    const eventTarget = useMemo(() => new EventTarget(), []);

    useEffect(() => {
        if (!isPickingColor) return;
        eventTarget.dispatchEvent(new OpenColorPickerEvent());
    }, [isPickingColor, eventTarget]);

    if (!isPickingColor) return null;

    return <ElementColorPicker openerEventTarget={eventTarget} elements={List([element])} />;
};

export default ColorSwatchPicker;
