import React, { useContext } from 'react';

const VirtualKeyboardHeightContext = React.createContext<number>(0);

export const useVirtualKeyboardHeightContext = () => useContext(VirtualKeyboardHeightContext);

const VirtualKeyboardSetHeightContext = React.createContext<(height: number) => void>(() => {});
export const useVirtualKeyboardSetHeightContext = () => useContext(VirtualKeyboardSetHeightContext);

export const VirtualKeyboardHeightProvider: React.FC = ({ children }) => {
    const [virtualKeyboardHeight, setVirtualKeyboardHeight] = React.useState(0);

    return (
        <VirtualKeyboardSetHeightContext.Provider value={setVirtualKeyboardHeight}>
            <VirtualKeyboardHeightContext.Provider value={virtualKeyboardHeight}>
                {children}
            </VirtualKeyboardHeightContext.Provider>
        </VirtualKeyboardSetHeightContext.Provider>
    );
};
