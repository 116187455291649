import { useEffect } from 'react';

/*
 * Hook to allow CSS to adjust for fractional pixel ratios.
 *
 * (note that CSS has -webkit-device-pixel-ratio, but it's nonstandard, and also just a media query)
 */
const usePixelRatioAdjustment = () =>
    useEffect(() => {
        // Some devices have a fractional pixel ratio, which can make borders render weird.
        const fpart = window.devicePixelRatio - Math.floor(window.devicePixelRatio);

        // How far short of a whole pixel are we? (eg with a pixel ratio of 1.75, we're 0.25 short of a whole pixel)
        const adjustment = fpart > 0 ? 1 - fpart : 0;

        // Set the CSS property so that styles can make adjustments
        document.body.style.setProperty('--pixel-ratio-adjustment', `${adjustment}px`);
    }, []);

export default usePixelRatioAdjustment;
