// Types
import { Action } from 'redux';
import { BaseTimerVisibilityState } from './baseTimerVisibilityState';
import { VisibleConnectionStatus } from './visibleConnectionStatusTypes';
import { SOCKET_CONNECT, SOCKET_DISCONNECT, SOCKET_INTERRUPTION } from '../../utils/socket/socketConstants';

/**
 * The socket isn't currently connected, but it's attempting to connect.
 * There won't be any visual indicators shown - however if we don't connect within 10 seconds,
 * we'll move to the disconnected state.
 *
 * NOTE: This state uses a timeout to transition to the disconnected state.
 */
export class ConnectingState extends BaseTimerVisibilityState {
    static stateKey = VisibleConnectionStatus.connecting;

    nextState(action: Action) {
        switch (action.type) {
            case SOCKET_CONNECT:
                return VisibleConnectionStatus.connected;
            case SOCKET_INTERRUPTION:
            case SOCKET_DISCONNECT:
                return VisibleConnectionStatus.interrupted;
            case this.timeoutActionName:
                return VisibleConnectionStatus.disconnected;
            default:
                return undefined;
        }
    }
}
