// States
import { ConnectedState } from './connectedVisibleConnectionState';
import { ConnectingState } from './connectingVisibleConnectionState';
import { InterruptedState } from './interruptedVisibleConnectionState';
import { DisconnectedState } from './disconnectedVisibleConnectionState';

// Selectors
import { selectIsSocketConnected } from '../../utils/socket/socketConnectionSelector';

// Types
import { UnknownAction } from 'redux';
import { ReduxStore } from '../../types/reduxTypes';
import { StateMachine } from '../../../common/utils/lib/stateMachine';

/**
 * Middleware that will prevent the reconnection spinner from unnecessarily showing
 * when the app is being restored from the background.
 */
export default (store: ReduxStore) => {
    const state = store.getState();
    const isSocketConnected = selectIsSocketConnected(state);

    // Electron apps that aren't starting fresh, might begin with a connection
    //  in this case we want to start with the connected state
    const InitialStateClass = isSocketConnected ? ConnectedState : ConnectingState;

    const visibilityStateMachine = new StateMachine(
        [ConnectingState, ConnectedState, InterruptedState, DisconnectedState],
        InitialStateClass,
    );
    return (next: Function) =>
        (action: UnknownAction): UnknownAction => {
            visibilityStateMachine.onAction(action, store);
            return next(action);
        };
};
