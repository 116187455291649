// Utils
import { isLocationRootBoard } from '../../../common/elements/utils/elementLocationUtils';
import { asRect } from '../../../common/maths/geometry/rect';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';

export const getHeaderAvoidanceInsets = (
    gridSize: number,
    currentBoard: ImMNElement,
    virtualKeyboardHeight: number = 0,
): Rect =>
    asRect({
        top: gridSize * (isLocationRootBoard(currentBoard) ? 5.3 : 10),
        right: gridSize,
        bottom: gridSize * 2 + virtualKeyboardHeight,
        left: gridSize,
    });
