import React, { useEffect, useState } from 'react';
import setInitialSheetHeight from '../utils/setInitialSheetHeight';
import { SheetProps } from '../SheetContainer';
import runOnTransitionEndWithTimeout from '../../../../utils/dom/runOnTransitionEndWithTimeout';

const SHEET_CLOSE_TIMEOUT = 1000;

/**
 * When sheet is opened, update height to the default snap point, and update the sheetInitialised value to show when it's done
 */
const useSheetInitialisation = (
    sheetProps: SheetProps,
    addSnapPoint: (snapPoint: number) => void,
    isSheetMounted: boolean,
    setIsSheetMounted: (isMounted: boolean) => void,

    cancelInProgressCloseTransitionRef: React.MutableRefObject<(() => void) | null>,
    cancelInitialHeightAnimationFrameRef: React.MutableRefObject<(() => void) | null>,
    cancelInProgressAnimations: () => void,
): boolean => {
    const {
        sheetRef,
        sheetContentRef,
        defaultSnapPoint,
        isSheetOpen,
        sheetId,
        onOpenTransitionEnd,
        onCloseTransitionStart,
        onCloseTransitionEnd,
        dispatchUpdateActiveSnapPoint,
    } = sheetProps;

    const [sheetInitialised, setSheetInitialised] = useState<boolean>(false);

    const isSheetOpenAndMounted = isSheetOpen && isSheetMounted;

    /**
     * When the isSheetOpen is set to false, animate the sheet to the closed position and then unmount it
     */
    useEffect(() => {
        if (isSheetOpen) return;

        if (!isSheetMounted || !sheetRef.current) return;

        cancelInProgressAnimations();
        onCloseTransitionStart?.();

        const completeSheetClose = () => {
            cancelInProgressCloseTransitionRef.current = null;
            setIsSheetMounted(false);
            onCloseTransitionEnd?.();
        };

        const onTimeoutFinish = () => {
            // This is used as a fallback in the case that there is no transitionend event fired
            // There is one scenario in which we know the timeout will fire - if the open/toolbar button is clicked
            // twice very quickly (first click opens, and second closes because the click is on the overlay)
            // then the sheet hasn't moved and therefore there will be no transition
            console.warn(
                `SHEET: Warning: ${sheetId} sheet close transitionend event did not fire in time, timeout fallback ran instead`,
            );
            cancelInProgressCloseTransitionRef.current?.();
        };

        // When isSheetOpen is set to false, the snap point state is changed to 0 at the same time.
        // This will trigger the useEffect in useSheetSnapPointState, which will transition the sheet to the snap point 0 position
        // at this point in time the transition hasn't begun, so the following event listener will work in relation to that transition to 0
        const cancelRunCallback = runOnTransitionEndWithTimeout(
            sheetRef,
            completeSheetClose,
            SHEET_CLOSE_TIMEOUT,
            onTimeoutFinish,
        );

        cancelInProgressCloseTransitionRef.current = () => {
            if (!sheetRef.current) return;

            cancelRunCallback();
            cancelInProgressCloseTransitionRef.current = null;
        };

        return cancelRunCallback;
    }, [isSheetOpen]);

    useEffect(() => {
        if (isSheetOpenAndMounted) {
            setInitialSheetHeight(
                sheetRef,
                defaultSnapPoint,
                addSnapPoint,
                dispatchUpdateActiveSnapPoint,
                setSheetInitialised,
                cancelInitialHeightAnimationFrameRef,
                cancelInProgressAnimations,
                sheetContentRef,
                onOpenTransitionEnd,
            );
        } else {
            setSheetInitialised(false);
        }
    }, [isSheetOpenAndMounted]);

    return sheetInitialised;
};

export default useSheetInitialisation;
