import MoreTool from '../../components/moreTool/MoreTool';

// Types
import { MoreToolsSettings, ToolbarItemConfig } from '../../toolbarTypes';

import { COLLAPSE_ORDER } from '../toolbarToolConfigConstants';
import ToolSeparator from '../../components/ToolSeparator';
import { MilanoteApplicationMode } from '../../../../../common/platform/platformTypes';

export const MetaToolIds = {
    MORE: 'META_MORE',
    DIVIDER: 'META_DIVIDER',
    HIDE_TOOLBAR: 'META_HIDE_TOOLBAR',
    FAKE_TOOL: 'FAKE_TOOL',
};

const META_MORE: ToolbarItemConfig = {
    id: MetaToolIds.MORE,
    name: 'More',
    height: {
        large: 16,
        medium: 16,
        small: 14,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    component: MoreTool,
    toolSettings: {
        groupSize: 3,
        items: [],
        popupId: '',
    } as MoreToolsSettings,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const META_DIVIDER: ToolbarItemConfig = {
    id: MetaToolIds.DIVIDER,
    name: 'Divider',
    height: {
        large: 1,
        medium: 1,
        small: 1,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    collapseBehavior: 'hide',
    component: ToolSeparator,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

// This tool is used in cases where we want to render a tool that does nothing,
// for example in the UTILS toolbar, no tools are actually rendered, but we need to ensure there's a distinct tool set
// between the TABS and UTILS toolbars to ensure the toolbar is neatly transitioned when the toolbar type changes
const META_FAKE_TOOL: ToolbarItemConfig = {
    id: MetaToolIds.FAKE_TOOL,
    name: 'Fake Tool',
    height: {
        large: 0,
        medium: 0,
        small: 0,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    collapseBehavior: 'hide',
    component: () => null,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
    availabilityPredicate: () => true,
    permissionPredicate: () => 0,
};

// This tools is used in scenarios when we specifically want to hide the toolbar completely
const META_HIDE_TOOLBAR: ToolbarItemConfig = {
    id: MetaToolIds.HIDE_TOOLBAR,
    name: 'Empty Tool',
    height: {
        large: 0,
        medium: 0,
        small: 0,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    collapseBehavior: 'hide',
    component: () => null,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

export default {
    META_MORE,
    META_DIVIDER,
    META_FAKE_TOOL,
    META_HIDE_TOOLBAR,
};
