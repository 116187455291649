import sheetConfig from './sheetConfig';

import { ErrorSheetOpenAction, SheetActionTypes, SheetCloseAction, SheetOpenAction } from './sheetActionTypes';
import { SheetId } from './sheetTypes';

export const openSheet = (sheetId: SheetId, instanceId?: string): SheetOpenAction => ({
    type: SheetActionTypes.SHEET_OPEN,
    sheetId,
    instanceId,
    config: sheetConfig[sheetId],
});

export const openErrorSheet = (activeErrorNoticeId: string, data: any): ErrorSheetOpenAction => {
    const sheetId = SheetId.Error;
    return {
        type: SheetActionTypes.SHEET_OPEN,
        sheetId,
        config: sheetConfig[sheetId],
        activeErrorNoticeId,
        data,
    };
};

export const closeSheet = (sheetId: SheetId, instanceId?: string): SheetCloseAction => ({
    type: SheetActionTypes.SHEET_CLOSE,
    sheetId,
    instanceId,
});

export const updateSheetActiveSnapPoint = (activeSnapPoint: number, sheetId: SheetId, instanceId?: string) => ({
    type: SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT,
    sheetId,
    instanceId,
    activeSnapPoint,
});
