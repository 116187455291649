// Lib
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import classNames from 'classnames';

import SheetContainer, { Content, Overlay, Sheet } from '../../../mobile/structural/sheet';
import useSheet from '../../../mobile/structural/sheet/hooks/useSheet';
import { SheetId } from '../../../mobile/structural/sheet/sheetTypes';

const ROOT_PATH = '/';

export const AuthSheet: React.FC<{ className: string; close: () => void }> = ({ children, className, close }) => {
    const sheetProps = useSheet(SheetId.Auth);

    const dispatch = useDispatch();

    const onCloseTransitionEnd = useCallback(() => {
        // When the sheet is opened above a public board (published or editable link), then the
        // close property will be a function that navigates back to the previous location
        close ? close() : dispatch(push(ROOT_PATH));
    }, []);

    return (
        <SheetContainer
            {...sheetProps}
            openByDefault
            defaultSnapPoint={1}
            snapPoints={[1]}
            onCloseTransitionEnd={onCloseTransitionEnd}
        >
            <Overlay />
            <Sheet className="AuthSheetContainer AuthModal">
                <Content>
                    <div className="PageContent modal-content StyledPageContent">
                        <div className={classNames('AuthSheetContents', 'AuthModal', className)}>{children}</div>
                    </div>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};
