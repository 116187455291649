// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Utils
import { trackAttachmentDownload } from '../../element/attachments/attachmentsService';
import { isPlatformElectronMac, isPlatformLegacyMobileApp } from '../../platform/utils/platformDetailsUtils';

const DownloadOpenLinkInBrowserButton = ({ children, divider, className, url }) => {
    const onClick = () => {
        trackAttachmentDownload({ url });
    };

    const platformDetails = useSelector(getPlatformDetailsSelector);

    if (isPlatformElectronMac(platformDetails) || isPlatformLegacyMobileApp(platformDetails)) return null;

    return (
        <>
            {divider}

            <a
                href={url}
                onClick={onClick}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('DownloadOpenLinkButton', className)}
            >
                {children}
            </a>
        </>
    );
};

DownloadOpenLinkInBrowserButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    divider: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    className: PropTypes.string,
    url: PropTypes.string,
};

export default DownloadOpenLinkInBrowserButton;
