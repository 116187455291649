// Services
import globalLogger from '../../logger';
import { fetchAllUserActivity } from '../../user/userService';
import { refreshIfModified } from '../../element/board/boardRefreshService';
import { checkAppServerConnection } from '../appServerConnectionUtil';

// Selectors
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { selectSocketInterruptionTime, selectIsSocketConnected } from '../../utils/socket/socketConnectionSelector';

// Constants
import { APP_END_CURRENT_BOARD_REFRESH, APP_START_CURRENT_BOARD_REFRESH } from './appServerReconnectionStatusConstants';

export const appStartCurrentBoardRefresh = () => ({ type: APP_START_CURRENT_BOARD_REFRESH });
export const appEndCurrentBoardRefresh = () => ({ type: APP_END_CURRENT_BOARD_REFRESH });

let orchestratingReconnection = false;

const logger = globalLogger.createChannel('app-server-reconnection');

/**
 * When returning to the application, refresh the current board if it's been modified since we last visited.
 */
export const orchestrateReconnect =
    () =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        logger.info('Orchestrating reconnection');

        // We don't want to orchestrate reconnection twice because it can result in strange behaviour with the
        // reconnecting spinner and unnecessary board refreshes
        if (orchestratingReconnection) {
            logger.info('Already orchestrating reconnection - abandoning reconnection');
            return;
        }

        orchestratingReconnection = true;

        const canConnectToAppServer = await checkAppServerConnection();

        if (!canConnectToAppServer) {
            logger.info('Unable to connect to app server - abandoning reconnection');
            orchestratingReconnection = false;
            return;
        }

        const state = getState();

        // If we are already connected, then we don't need to orchestrate reconnection, but we need
        // to make sure the socket warning will be enabled
        if (selectIsSocketConnected(state)) {
            logger.info('Socket is already connected - no need to trigger reconnection');
            orchestratingReconnection = false;
            return;
        }

        logger.info('Performing reconnection - fetching user activity and refreshing current board');

        const socketInterruptionTime = selectSocketInterruptionTime(state);
        const currentBoardId = getCurrentBoardIdFromState(state);

        const beforeRefresh = () => dispatch(appStartCurrentBoardRefresh());
        const afterRefresh = () => dispatch(appEndCurrentBoardRefresh());

        dispatch(fetchAllUserActivity());

        // This appears to occur when rehydrating from the local cache, as the current board ID is part of the
        //  LOCAL_CACHE_HYDRATION_OMIT constant
        if (!currentBoardId) {
            logger.warn('No current board ID - not triggering board refresh');
            orchestratingReconnection = false;
            return;
        }

        return dispatch(
            refreshIfModified({
                beforeRefresh,
                afterRefresh,
                boardId: currentBoardId,
                loadAncestors: true,
                // Only show the progress spinner if it was updated since the socket disconnection time
                afterTimestamp: socketInterruptionTime,
            }),
        ).finally(() => {
            orchestratingReconnection = false;
        });
    };
