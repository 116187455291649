// Lib
import { createSelector } from 'reselect';

// Utils
import { isPlatformIframe, isPlatformPhoneOrMobileMode } from '../../../platform/utils/platformDetailsUtils';
import { canGiveFeedback, canRead, isFullAccess } from '../../../../common/permissions/permissionUtil';

// Selectors
import { isGuestSelector } from '../../../user/currentUserSelector';
import {
    currentBoardSpecificUserPermissionsSelector,
    currentBoardUnconstrainedUserPermissions,
} from '../../../utils/permissions/elementPermissionsSelector';
import { isPublishPreviewModeEnabledSelector } from '../../preview/previewSelector';
import { isPresentationModeEnabledSelector } from '../../presentation/presentationSelector';
import { getLocationQueryParam } from '../../../app/routingSelector';
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

const USER_MODES = {
    // User is viewing Milanote on an iFrame
    IFRAME: 'IFRAME',

    // User is a guest on a mobile device
    GUEST_MOBILE: 'GUEST_MOBILE',

    // User is the owner of board/one of the editors listed in the ACL
    PHYSICAL_EDITOR: 'PHYSICAL_EDITOR',

    // User is an editor who have used the public editable link
    PERMISSION_LINK_EDITOR: 'PERMISSION_LINK_EDITOR',

    // User is a guest who accessed the board via the editable link
    GUEST_PERMISSION_LINK_EDITOR: 'GUEST_PERMISSION_LINK_EDITOR',

    // User is logged in and is using the published link
    PUBLISHED_LINK: 'PUBLISHED_LINK',

    // User is a guest who is using the published link
    GUEST_PUBLISHED_LINK: 'GUEST_PUBLISHED_LINK',

    // User is in presentation mode
    PRESENTATION_LINK: 'PRESENTATION_LINK',

    // User is in the password protected screen, will be updated to PUBLISHED_LINK/GUEST_PUBLISHED_LINK once
    // user has entered correct password
    PASSWORD_PROTECTED: 'PASSWORD_PROTECTED',
};

export const getCurrentBoardHeaderUserMode = createSelector(
    isGuestSelector,
    isPublishPreviewModeEnabledSelector,
    isPresentationModeEnabledSelector,
    currentBoardUnconstrainedUserPermissions,
    currentBoardSpecificUserPermissionsSelector,
    getPlatformDetailsSelector,
    (isGuest, isPublishPreviewMode, isPresentationMode, userPermission, specificUserPermission, platformDetails) => {
        if (isPlatformIframe(platformDetails)) return USER_MODES.IFRAME;

        if (!canRead(userPermission)) return USER_MODES.PASSWORD_PROTECTED;

        // In publish preview mode, show the board as a guest who's using the published link
        if (isPublishPreviewMode) return USER_MODES.GUEST_PUBLISHED_LINK;

        // In presentation mode
        if (isPresentationMode) return USER_MODES.PRESENTATION_LINK;

        if (isGuest) {
            if (isPlatformPhoneOrMobileMode(platformDetails)) return USER_MODES.GUEST_MOBILE;
            if (isFullAccess(userPermission)) return USER_MODES.GUEST_PERMISSION_LINK_EDITOR;
            return USER_MODES.GUEST_PUBLISHED_LINK;
        }

        if (isFullAccess(specificUserPermission)) return USER_MODES.PHYSICAL_EDITOR;
        if (isFullAccess(userPermission)) return USER_MODES.PERMISSION_LINK_EDITOR;

        return USER_MODES.PUBLISHED_LINK;
    },
);

export const showShareUtilSelector = createSelector(getCurrentBoardHeaderUserMode, (userMode) => {
    switch (userMode) {
        case USER_MODES.PHYSICAL_EDITOR:
        case USER_MODES.PERMISSION_LINK_EDITOR:
            return true;
        default:
            return false;
    }
});

export const showExportUtilSelector = createSelector(getCurrentBoardHeaderUserMode, (userMode) => {
    switch (userMode) {
        case USER_MODES.PHYSICAL_EDITOR:
        case USER_MODES.PERMISSION_LINK_EDITOR:
        case USER_MODES.PUBLISHED_LINK:
            return true;
        default:
            return false;
    }
});

export const showViewUtilSelector = createSelector(getCurrentBoardHeaderUserMode, (userMode) => {
    switch (userMode) {
        case USER_MODES.PHYSICAL_EDITOR:
        case USER_MODES.PERMISSION_LINK_EDITOR:
        case USER_MODES.PUBLISHED_LINK:
        case USER_MODES.PRESENTATION_LINK:
            return true;
        default:
            return false;
    }
});

export const showWriteStatusUtilSelector = createSelector(getCurrentBoardHeaderUserMode, (userMode) => {
    switch (userMode) {
        case USER_MODES.PUBLISHED_LINK:
        case USER_MODES.GUEST_PUBLISHED_LINK:
        case USER_MODES.GUEST_PERMISSION_LINK_EDITOR:
            return true;
        default:
            return false;
    }
});

export const showMadeWithMilanoteLinkSelector = createSelector(getCurrentBoardHeaderUserMode, (userMode) => {
    switch (userMode) {
        case USER_MODES.IFRAME:
        case USER_MODES.PASSWORD_PROTECTED:
        case USER_MODES.GUEST_PERMISSION_LINK_EDITOR:
        case USER_MODES.GUEST_PUBLISHED_LINK:
            return true;
        default:
            return false;
    }
});

export const showSignUpToMilanoteButtonSelector = createSelector(
    getCurrentBoardHeaderUserMode,
    getLocationQueryParam('utm_medium'),
    currentBoardUnconstrainedUserPermissions,
    (userMode, utmMedium, userPermissions) => {
        if (userMode !== USER_MODES.GUEST_PUBLISHED_LINK) return false;

        return !!utmMedium && !canGiveFeedback(userPermissions);
    },
);

export const showCurrentBoardMenuSelector = createSelector(getCurrentBoardHeaderUserMode, (userMode) => {
    switch (userMode) {
        case USER_MODES.PERMISSION_LINK_EDITOR:
        case USER_MODES.PUBLISHED_LINK:
        case USER_MODES.GUEST_PERMISSION_LINK_EDITOR:
        case USER_MODES.GUEST_PUBLISHED_LINK:
            return true;
        default:
            return false;
    }
});

export const showMobileGuestSettingsSelector = createSelector(
    getCurrentBoardHeaderUserMode,
    (userMode) => userMode === USER_MODES.GUEST_MOBILE,
);
