// Action Types

export enum ErrorModalActionTypes {
    ERROR_MODAL_OPEN = 'ERROR_MODAL_OPEN',
    ERROR_MODAL_CLOSE = 'ERROR_MODAL_CLOSE',
}

export type ErrorModalOpenAction = {
    type: ErrorModalActionTypes.ERROR_MODAL_OPEN;
    modalId: string;
    data: any;
};

export type ErrorModalCloseAction = {
    type: ErrorModalActionTypes.ERROR_MODAL_CLOSE;
};

export type ErrorModalAction = ErrorModalOpenAction | ErrorModalCloseAction;

// Reducer Types

export type ErrorModalReducerState = {
    activeErrorNoticeId: string | undefined;
    data: any;
};
