// Lib
import React from 'react';
import classNames from 'classnames';
import { identity } from 'lodash';
import { getEmptyImage } from 'react-dnd-html5-backend';

// Types
import { Point } from '../../../common/maths/geometry/pointTypes';

// Styles
import './DragHandle.scss';

const HALF_BORDER_WIDTH = 0.25;

declare global {
    interface Window {
        lines: {
            handleSize: number;
        };
    }
}

const getHandleStyle = ({ x, y }: Point) => ({
    left: x,
    top: y,
});

type DragHandleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    className?: string;
    style?: React.CSSProperties;
    show: boolean;
    pos: { x: number; y: number };
    isHitArea: boolean;
    handleMode: string;
    domElementId: string;

    connectDragSource?: (element: React.ReactElement) => React.ReactElement;
    connectDragPreview?: (element: React.ReactElement) => React.ReactElement;
};

const DragHandle = (props: DragHandleProps) => {
    const {
        show,
        className,
        style,
        connectDragSource,
        connectDragPreview,
        isHitArea,
        handleMode,
        domElementId,
        pos,
        ...divProps
    } = props;

    React.useEffect(() => {
        // @ts-ignore - As far as I'm aware this is correct per the docs, so not sure why the types are off
        connectDragPreview && connectDragPreview(getEmptyImage());
    }, []);

    if (!show) return null;

    const positionStyle = pos ? getHandleStyle(pos) : null;

    const handleStyles = {
        ...positionStyle,
        ...style,
    };

    const radius = global.window.lines.handleSize / 2;

    const wrapperFn = connectDragSource || identity;
    return wrapperFn(
        <div
            {...divProps}
            className={classNames('DragHandle', className, handleMode, { 'hit-area': isHitArea })}
            style={handleStyles}
            id={domElementId}
        >
            <svg
                className="handle"
                version="1.1"
                width={global.window.lines.handleSize}
                height={global.window.lines.handleSize}
                viewBox={`0 0 ${global.window.lines.handleSize} ${global.window.lines.handleSize}`}
            >
                <circle cx={radius} cy={radius} r={radius - HALF_BORDER_WIDTH} />
            </svg>
        </div>,
    );
};

export default DragHandle;
