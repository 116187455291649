import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Measure from '../../../../node_module_clones/react-measure/src/Measure';

import './ToolbarMeasure.scss';

const ToolbarMeasure = ({ setAvailableHeight }) => {
    const onMeasure = useCallback(({ height }) => setAvailableHeight(height), []);

    return (
        <Measure whitelist={['height']} onMeasure={onMeasure}>
            <div className="ToolbarMeasure" />
        </Measure>
    );
};

ToolbarMeasure.propTypes = {
    setAvailableHeight: PropTypes.func,
};

export default ToolbarMeasure;
