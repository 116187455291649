// Lib
import { useEffect, useRef } from 'react';

// Utils
import { preventDefaultOnly } from '../domUtil';
import getParentScrollableSection from './getParentScrollableSection';

/**
 * Prevents the touch scroll event from bubbling up to the parent element when a pointer down
 * event occurs (wherever the pointer down event is attached to).
 *
 * This prevents unwanted scrolls from occurring, e.g. It prevents the canvas from scrolling
 * while attempting to drag a line's control points.
 */
export default function usePreventTouchScroll() {
    const touchMoveEventControlRef = useRef<AbortController | null>(null);

    const onPointerDown = (event: MouseEvent) => {
        const scrollableParentEl = getParentScrollableSection(event.target as HTMLElement);

        if (!scrollableParentEl) return;

        touchMoveEventControlRef.current?.abort(); // clear previous controller if it exists

        touchMoveEventControlRef.current = new AbortController();

        scrollableParentEl.addEventListener('touchmove', preventDefaultOnly, {
            passive: false,
            signal: touchMoveEventControlRef.current.signal,
        });

        document.addEventListener('pointerup', () => touchMoveEventControlRef.current?.abort(), {
            signal: touchMoveEventControlRef.current.signal,
        });
    };

    // Remove the touch move event listener when the component unmounts
    useEffect(
        () => () => {
            touchMoveEventControlRef.current?.abort();
        },
        [],
    );

    return { onPointerDown };
}
