import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const BoardStatusFeedbackIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon board-status-feedback"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                fill="#2F63F7"
                d="M2.5 12.5V6.25A2.5 2.5 0 0 1 5 3.75h10a2.5 2.5 0 0 1 2.5 2.5v6.25A2.5 2.5 0 0 1 15 15h-2.5L10 17.5 7.5 15H5a2.5 2.5 0 0 1-2.5-2.5z"
            />
            <path
                fill="#F4F7FE"
                d="M5.625 6.875h8.75a.625.625 0 1 1 0 1.25h-8.75a.625.625 0 1 1 0-1.25zm0 3.125h7.5a.625.625 0 1 1 0 1.25h-7.5a.625.625 0 1 1 0-1.25z"
            />
        </g>
    </svg>
);
const Memo = memo(BoardStatusFeedbackIcon);
export default Memo;
