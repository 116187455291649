// Utils
import delay from '../../common/utils/lib/delay';
import { buildAppRegisterOverlayUrl } from '../auth/modals/register/registrationUtils';
import { canGiveFeedback, isFullAccess } from '../../common/permissions/permissionUtil';
import { triggerBackgroundPulseCssAnimation } from '../scss/mixins/scssBackgroundPulseUtil';

// Selectors
import { isGuestSelector } from '../user/currentUserSelector';
import { getIsModalOpen } from '../components/modal/activeModalSelector';
import { getLocationQuery, getUrlPermissionId } from '../app/routingSelector';
import { getMilanoteApplicationModeSelector } from '../platform/platformSelector';
import { getCurrentBoardIdFromState } from './currentBoardId/currentBoardIdSelector';
import { currentBoardUnconstrainedUserPermissions } from '../utils/permissions/elementPermissionsSelector';

// Actions
import { navigateToUrl } from './navigationActions';

// Constants
import { REGISTRATION_ENTRY_POINT } from '../../common/users/userConstants';
import {
    PUBLIC_EDIT_REGISTRATION_ONBOARDING,
    PUBLISH_FEEDBACK_REGISTRATION_ONBOARDING,
} from '../../common/users/userEducationConstants';
import { MilanoteApplicationMode } from '../../common/platform/platformTypes';

// This is used to stop the registration form from showing when a guest closes the comment popup
let disableGuestRegistration = false;

/**
 * Shows the registration modal that sits over the current board if the user is a guest.
 *
 * NOTE: On mobile - we trigger the flash animation on the toast instead of showing
 *  the registration modal. The user will need to click the toast to open the registration modal.
 */
export const showGuestAppRegistrationForm = () => async (dispatch, getState) => {
    let state = getState();

    if (disableGuestRegistration) return;
    if (!isGuestSelector(state)) return;

    const unconstrainedPermissions = currentBoardUnconstrainedUserPermissions(state);

    // In app-mode mobile we want to trigger the Toast animation
    const appMode = getMilanoteApplicationModeSelector(state);
    const toastEl = document.querySelector('.BoardPermissionToast');

    if (appMode === MilanoteApplicationMode.mobile && toastEl) {
        triggerBackgroundPulseCssAnimation('.BoardPermissionToast');
        return;
    }

    // Read only boards shouldn't show the registration modal
    if (!canGiveFeedback(unconstrainedPermissions)) return;

    await delay(200);

    state = getState();

    const isModalOpen = getIsModalOpen(state);

    if (isModalOpen) return;

    const currentBoardId = getCurrentBoardIdFromState(state);
    const locationQuery = getLocationQuery(state);
    const urlPermissionId = getUrlPermissionId(state);

    const isEditableBoard = isFullAccess(unconstrainedPermissions);

    const appRegisterUrl = buildAppRegisterOverlayUrl({
        currentBoardId,
        permissionId: urlPermissionId,
        locationQuery,
        registrationEntryPoint: isEditableBoard
            ? REGISTRATION_ENTRY_POINT.PUBLIC_EDIT_REGISTRATION
            : REGISTRATION_ENTRY_POINT.PUBLISHED_REGISTRATION,
        onboarding: isEditableBoard ? PUBLIC_EDIT_REGISTRATION_ONBOARDING : PUBLISH_FEEDBACK_REGISTRATION_ONBOARDING,
    });

    dispatch(navigateToUrl(appRegisterUrl));
};

export const setTemporarilyDisableGuestRegistrationForm = (isOpen) => {
    if (isOpen) return false;

    disableGuestRegistration = true;

    setTimeout(() => {
        disableGuestRegistration = false;
    }, 100);
};
