import { SheetId, SheetConfig } from './sheetTypes';

export enum SheetActionTypes {
    SHEET_OPEN = 'SHEET_OPEN',
    SHEET_CLOSE = 'SHEET_CLOSE',
    SHEET_UPDATE_ACTIVE_SNAP_POINT = 'SHEET_UPDATE_ACTIVE_SNAP_POINT',
}

export type SheetOpenAction = {
    type: SheetActionTypes.SHEET_OPEN;
    sheetId: SheetId;
    instanceId?: string;
    config?: SheetConfig;
};

export interface ErrorSheetOpenAction extends SheetOpenAction {
    activeErrorNoticeId: string;
    data: object | undefined;
}

export type SheetCloseAction = {
    type: SheetActionTypes.SHEET_CLOSE;
    sheetId: SheetId;
    instanceId?: string;
};

export type SheetUpdateActiveSnapPointAction = {
    type: SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT;
    sheetId: SheetId;
    instanceId?: string;
    activeSnapPoint: number;
};

export type SheetAction = SheetOpenAction | ErrorSheetOpenAction | SheetCloseAction | SheetUpdateActiveSnapPointAction;
