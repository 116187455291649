const BASE_HYDRATION_OMIT = [
    'form',
    'routing',
    // TODO: fix rehydration of undoRedo state
    // - it uses a bunch of specific immutable constructs which require special handling
    'undoRedo',
    // remove onboarding state as it has functions in it which can't be serialised
    'app.onboarding',
    // Remove modal state as it incorrectly restores login/register form state (activeModal) when using the
    // Google Sign In flow, this in turn causes some shortcuts to stop working because they rely on the
    // activeModal state being empty in order to function
    'app.modal',
    // Any in progress uploads should be cleared from the saved state
    'app.attachments',
    // this state relates to viewing a published link, so should not be rehydrated
    'app.preview',
    // clear this entire section as it controls whether we're in preview mode
    'app.boardPreview',
    // don't want to keep any popups open when we reopen the app
    'app.popup',
    // these are used to track the keys that are pressed while a drag is in progress
    // They should be cleared to ensure that a page reload doesn't have leftover drag state
    'app.dragModifiedKeys',
    //  Used to keep track of text content in clipped cards
    'app.clipper',
    // we don't selections to remain on page reload
    'app.selection',
    // otherwise tooltips may show on refresh
    'app.tooltip',
    // we don't want to be in an editing state on page reload
    'app.currentlyEditing',
    // contains logic relating to things that are in the process of being duplicated
    'app.duplications',
    // we don't want dragging state to persist between reloads
    'app.dragging',
    // we don't want to remain in presentation mode on page reload
    'app.presentation',
    // probably better so that it just resets to the canvas
    'app.currentFocus',
    // avoids any element search logic to maintain after reloads
    'app.elementFilter',
    'app.resizing',
    'app.annotation',
    // best to clear the clipboard between page loads
    'local.clipboard',
    'remoteActivity',
];

export const LOCAL_CACHE_HYDRATION_OMIT = [
    ...BASE_HYDRATION_OMIT,
    'app.currentBoardId',
    // TODO-CHECKOUT: remove this after we move the subscription data out of currentUser
    'app.currentUser.subscription.plan',
    'app.sheet',
];
export const LOCAL_CACHE_CLIENT_PERSISTENCE_ENABLED_HYDRATION_OMIT = [...LOCAL_CACHE_HYDRATION_OMIT, 'elements'];

export const INITIAL_STATE_HYDRATION_OMIT = [...BASE_HYDRATION_OMIT, 'platform'];

// These are the keys that are expected to be stored as POJOs in the state
// Used when rehydrating the state in the electron app to ensure that these keys are treated as POJOs instead of Immutable Maps
export const INITIAL_STATE_POJO_KEYS = [
    'comments',
    'asyncResources',
    'app.sheet',
    'app.modal.errorModal',

    // CMS data
    'app.cms.emoji.data',
    'app.cms.ai-assistant-shortcuts.data',

    // cache
    'cache.selectors',
    'cache.elementSummaries',

    // Board summaries / hierarchies
    'app.boardSummaries.summaries',
    'app.boardHierarchies',
];

export const INSTANT_APP_INITIAL_STATE_POJO_KEYS = [...INITIAL_STATE_POJO_KEYS, 'platform'];
