// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import { CAPACITOR_LIFECYCLE_EVENT, CapacitorAppStatus, CapacitorLifecycleAction } from './capacitorLifecycleTypes';

export const capacitorLifecycleAction = (
    status: CapacitorAppStatus,
    lifecycleEvent: string,
    timestamp = getTimestamp(),
): CapacitorLifecycleAction => ({
    type: CAPACITOR_LIFECYCLE_EVENT,
    status,
    lifecycleEvent,
    timestamp,
});
