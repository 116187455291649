import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarReportContentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        viewBox="0 0 26 26"
        className="Icon mobile-toolbar-report-content"
        {...props}
    >
        <path
            fill="#5F6672"
            d="M4.063 3.25c0-.215.085-.422.237-.574.213-.213.61-.477 1.221-.722.945-.378 2.165-.6 3.687-.6 1.49 0 2.512.292 4.635 1.142 1.94.775 2.813 1.025 4.032 1.025 1.321 0 2.336-.185 3.084-.484.244-.097.435-.198.579-.294.069-.046.1-.071.096-.067a.812.812 0 0 1 1.387.574v13a.813.813 0 0 1-.238.575c-.212.212-.61.477-1.22.721-.946.378-2.165.6-3.688.6-1.49 0-2.511-.292-4.635-1.142-1.939-.775-2.813-1.025-4.032-1.025-1.32 0-2.336.185-3.083.484a3.581 3.581 0 0 0-.439.208l.002 7.162a.813.813 0 0 1-1.625 0V3.25zm5.145-.27c-1.32 0-2.336.184-3.083.483a4.011 4.011 0 0 0-.327.147l-.11.06v11.22l.075-.027c.826-.293 1.844-.474 3.071-.504l.374-.005c1.49 0 2.512.292 4.635 1.142 1.94.775 2.813 1.025 4.032 1.025 1.321 0 2.336-.185 3.084-.484.122-.048.23-.098.327-.147l.11-.06V4.609l-.076.028c-.826.293-1.844.474-3.07.504l-.375.005c-1.49 0-2.511-.292-4.635-1.142C11.3 3.23 10.427 2.98 9.208 2.98z"
        />
    </svg>
);
const Memo = memo(MobileToolbarReportContentIcon);
export default Memo;
