// Types
import { Action } from 'redux';
import { BaseVisibilityState } from './baseVisibleConnectionState';
import { VisibleConnectionStatus } from './visibleConnectionStatusTypes';
import { SOCKET_DISCONNECT, SOCKET_INTERRUPTION } from '../../utils/socket/socketConstants';

/**
 * The socket is currently connected - no visual indicators are shown.
 */
export class ConnectedState extends BaseVisibilityState {
    static stateKey = VisibleConnectionStatus.connected;

    nextState(action: Action) {
        switch (action.type) {
            case SOCKET_DISCONNECT:
            case SOCKET_INTERRUPTION:
                return VisibleConnectionStatus.interrupted;
            default:
                return undefined;
        }
    }
}
