import { DraftConverter, mapBlockToNode } from '@tiptap/draftjs-to-tiptap';
import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { isEmpty } from 'lodash/fp';

import { TextAlignment } from '../../../../table/CellTypeConstants';
import { ALIGNMENT_SUPPORTED_NODE_TYPES, TiptapContentNode, TiptapNodeType } from '../../../tiptapTypes';
import convertChecklistBlockToTiptapNode from './convertChecklistBlockToTiptapNode';
import convertDraftInlineStyleToTiptapMark from './convertDraftInlineStyleToTiptapMark';
import { convertEntityToMark } from './convertEntityToMark';
import { DraftConverterSpec, MapBlockToNodeFn } from './draftToTiptapConverterTypes';
import { postprocessContent } from './postprocessing';
import { rewriteTree } from './rewriteTree';

const convertSmallTextBlockToNode: MapBlockToNodeFn = (context) => {
    const { doc, converter, block, entityMap } = context;

    return converter.addChild(
        converter.createNode('smallText'),
        converter.splitTextByEntityRangesAndInlineStyleRanges({
            doc,
            block,
            entityMap,
        }),
    );
};

/**
 * This is the OOTB @tiptap/draftjs-to-tiptap converter's implementation
 * of the "unstyled" block type, except for the way that it handles
 * blocks with no text.
 *
 * The original implementation would return null in this case. This would
 * result in the block being skipped entirely, which is not what we want.
 *
 * Instead, we return an empty paragraph node - this matches the Tiptap behaviour
 * when creating a Tiptap card from scratch with empty lines.
 */
const convertUnstyledBlockToNode: MapBlockToNodeFn = (context) => {
    const { converter, doc, block, entityMap } = context;

    const paragraph = converter.createNode(TiptapNodeType.paragraph);

    if (!block.text) return paragraph;

    if (block.inlineStyleRanges.length === 0) {
        if (block.entityRanges.length === 0) {
            // Plain text, fast path
            return converter.addChild(paragraph, converter.createText(block.text));
        }
    }

    return converter.addChild(
        paragraph,
        converter.splitTextByEntityRangesAndInlineStyleRanges({
            doc,
            block,
            entityMap,
        }),
    );
};

const convertCodeBlockToNode: MapBlockToNodeFn = (context) => {
    const { converter, doc, block, entityMap } = context;

    const codeBlock = converter.createNode(TiptapNodeType.codeBlock);
    codeBlock.attrs = {
        language: null,
    };
    if (!block.text) return codeBlock;

    return converter.addChild(
        codeBlock,
        converter.splitTextByEntityRangesAndInlineStyleRanges({
            doc,
            block,
            entityMap,
        }),
    );
};

const convertBlockToNode: MapBlockToNodeFn = (context) => {
    switch (context.block.type) {
        case 'small-text':
            return convertSmallTextBlockToNode(context);
        case 'checklist':
            return convertChecklistBlockToTiptapNode(context);
        case 'unstyled':
            return convertUnstyledBlockToNode(context);
        case 'code-block':
            return convertCodeBlockToNode(context);
        default:
            // use tiptap's default converter
            return mapBlockToNode(context);
    }
};

const getAlignment = (node: TiptapContentNode, block: RawDraftContentBlock): TextAlignment | null => {
    if (!ALIGNMENT_SUPPORTED_NODE_TYPES.includes((node.type as TiptapNodeType) || '')) return null;

    if (block.data?.['text-align-center']) return TextAlignment.CENTER;
    if (block.data?.['text-align-right']) return TextAlignment.RIGHT;
    return TextAlignment.LEFT;
};

const addGlobalNodeAttrs = (node: TiptapContentNode, block: RawDraftContentBlock) => {
    let newAttrs: Record<string, any> = {
        textAlign: getAlignment(node, block),
    };

    newAttrs = Object.fromEntries(Object.entries(newAttrs).filter(([_, v]) => v !== null));

    if (!isEmpty(newAttrs)) {
        return {
            ...node.attrs,
            ...newAttrs,
        };
    }

    return node.attrs;
};

const converter = new DraftConverter({
    mapBlockToNode(context) {
        const node = convertBlockToNode(context);
        if (!node) return node;

        const attrs = addGlobalNodeAttrs(node, context.block);
        if (attrs) {
            node.attrs = attrs;
        }

        return node;
    },
    mapEntityToMark(context) {
        // The mapEntityToMark function exported by draftjs-to-tiptap is hardcoded to only handle
        // links, and strips the data from all other mark types, making it impossible to convert
        // them. This does roughly the same thing, but keeps the data for non-link entities.
        const entity = context.entityMap[context.range.key];
        const mark = convertEntityToMark(entity, String(context.range.key));

        return converter.addMark(mark);
    },
    mapInlineStyleToMark(context) {
        return convertDraftInlineStyleToTiptapMark(context);
    },
} as DraftConverterSpec);

/**
 * Converts an element's textContent (exported so tests don't have to set up a whole MNElement)
 */
export function convertDraftContentToTiptap(textContent: RawDraftContentState): TiptapContentNode {
    const baseConversion = converter.convert(textContent);

    return rewriteTree(baseConversion, postprocessContent);
}
