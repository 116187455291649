// Lib
import * as Immutable from 'immutable';
import { AnyAction } from 'redux';

// Constants
import { APP_START_CURRENT_BOARD_REFRESH, APP_END_CURRENT_BOARD_REFRESH } from './appServerReconnectionStatusConstants';

const initialState = Immutable.Map({
    isRefreshingCurrentBoard: false,
});

export default (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case APP_START_CURRENT_BOARD_REFRESH:
            return state.set('isRefreshingCurrentBoard', true);
        case APP_END_CURRENT_BOARD_REFRESH:
            return state.set('isRefreshingCurrentBoard', false);
        default:
            return state;
    }
};
