// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import LineWeightPopupContent from './LineWeightPopupContent';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

// Styles
import './LinePopup.scss';

const LineWeightPopup = (props) => {
    const { closePopup } = props;

    return (
        <ToolbarPopup
            popupId={PopupIds.LINE_WEIGHT}
            className="LineWeightPopup LinePopup"
            buttonSelector=".LineweightTool .icon"
            closePopup={closePopup}
        >
            <div className="line-weight-popup-content">
                <LineWeightPopupContent {...props} />
            </div>
        </ToolbarPopup>
    );
};

LineWeightPopup.propTypes = {
    closePopup: PropTypes.func,
};

export default LineWeightPopup;
