// Utils
import logger from '../../logger/logger';
import { getClosestUpBoardId } from '../../../common/elements/utils/elementTraversalUtils';

// Services
import * as elementService from '../elementService';
import * as boardService from '../board/boardService';
import { refreshIfModified } from '../board/boardRefreshService';

// Actions
import { currentBoardIdSet } from '../../reducers/currentBoardId/currentBoardIdActions';

// Selectors
import { getElements } from '../selectors/elementsSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';

// Constants
import { ELEMENT_MODAL_ELEMENT_ID_SET, ELEMENT_MODAL_ELEMENT_ID_CLEAR } from './elementModalConstants';

export const elementModalElementIdSet = (elementId: string) => ({
    type: ELEMENT_MODAL_ELEMENT_ID_SET,
    elementId,
});

export const elementModalElementIdClear = () => ({
    type: ELEMENT_MODAL_ELEMENT_ID_CLEAR,
});

/**
 * If the user is a guest, they might not load the ancestors of the ElementModal's rendered element.
 *
 * In this case we want to fetch the ancestors and then if we don't have a current board ID set, set
 * it to the first parent board of the rendered element.
 */
export const fetchElementModalElementAndAncestors =
    (elementId: string, permissionId: string) => async (dispatch: Function, getState: Function) => {
        await Promise.all([
            dispatch(
                elementService.fetchElements({
                    elementIds: [elementId],
                    permissionIdsOverride: [permissionId],
                    force: true,
                }),
            ),
            dispatch(
                boardService.fetchBoards({
                    boardIds: [elementId],
                    permissionIdsOverride: [permissionId],
                    loadAncestors: true,
                }),
            ),
        ]);

        const state = getState();
        const currentBoardId = getCurrentBoardId(state);

        if (currentBoardId) return;

        const elements = getElements(state);

        const firstParentBoardId = getClosestUpBoardId(elements, elementId);

        if (!firstParentBoardId) {
            logger.warn('A parent ID could not be found for the element ID', elementId);
            return;
        }

        dispatch(currentBoardIdSet({ boardId: firstParentBoardId, restored: false }));
    };

export const refreshParentBoardIfModified =
    (elementId: string) =>
    async (dispatch: Function, getState: Function): Promise<void> => {
        const state = getState();
        const elements = getElements(state);

        const firstParentBoardId = getClosestUpBoardId(elements, elementId);
        return dispatch(refreshIfModified({ boardId: firstParentBoardId }));
    };
