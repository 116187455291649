// Lib
import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { defer } from 'lodash';

// Actions
import { deselectAllElements } from '../../selection/selectionActions';

// Components
import SheetContainer, {
    Content,
    Header,
    Heading,
    CloseButton,
    Overlay,
    Sheet,
} from '../../../mobile/structural/sheet';
import DocumentModalTiptapEditor from './DocumentModalTiptapEditor';

// Hooks
import useSheet from '../../../mobile/structural/sheet/hooks/useSheet';
import useTipTapDocumentEditingProps from './useTipTapDocumentEditingProps';
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Utils
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';
import { getEditorDomElementId } from '../../utils/elementUtil';
import doesEditorJsonHaveText from '../../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import { SheetId } from '../../../mobile/structural/sheet/sheetTypes';
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';

// Constants
import { DOCUMENT_DEFAULT_TITLE } from '../../../../common/documents/documentConstants';
import { DOCUMENT_MODAL_TITLE_EDITOR_KEY } from './documentModalConstants';
import EditableTitle from '../../../components/editableTitle/EditableTitle';

// Styles
import './MobileDocumentSheet.scss';

interface MobileDocumentModalProps {
    close: () => void;
    elementId: string;
    element: ImMNElement;
    textContent: TiptapContent | null;
    isEditable: boolean;
    isPreview: boolean;
}

const MobileDocumentSheet = (props: MobileDocumentModalProps) => {
    const { close, element, elementId, isEditable, isPreview } = props;

    const sheetProps = useSheet(SheetId.Document);
    const tipTapDocumentEditingProps = useTipTapDocumentEditingProps(elementId, element);
    const textContent = useElementTiptapContent(element, getTextContent(element), DocumentType.textContent);

    const { isEditing, dispatchStartEditingDocument } = tipTapDocumentEditingProps;

    // Delay the start editing action - to allow the title editing to end before switching to
    // the main editor, otherwise the finish editing action would apply to the main content too
    const delayedStartEditingMainContent = () => {
        defer(dispatchStartEditingDocument);
    };

    const dispatch = useDispatch();
    const dispatchDeselectElements = () => {
        dispatch(deselectAllElements());
    };

    const classes = classNames('MobileDocumentSheet', {
        preview: isPreview,
        'read-only': !isEditable,
    });

    const startEditIfEmpty = () => {
        if (!!textContent && doesEditorJsonHaveText(textContent)) return;

        delayedStartEditingMainContent();
    };

    return (
        <SheetContainer
            {...sheetProps}
            openByDefault
            defaultSnapPoint={1}
            onCloseTransitionStart={dispatchDeselectElements}
            onCloseTransitionEnd={close}
            onOpenTransitionEnd={startEditIfEmpty}
            showToolbar={isEditing && !isPreview}
            className={classes}
        >
            <Overlay />
            <Sheet>
                <Header>
                    <Heading>
                        <EditableTitle
                            {...props}
                            isEditable={isEditable}
                            editorKey={DOCUMENT_MODAL_TITLE_EDITOR_KEY}
                            initialValue={DOCUMENT_DEFAULT_TITLE}
                            onStopEditing={delayedStartEditingMainContent}
                        />
                    </Heading>
                    <CloseButton>Close</CloseButton>
                </Header>
                <Content>
                    <div id="document-modal-content-container">
                        <div id="document-modal-content-scrollable-container" data-scrollable={true}>
                            <div id="document-modal-content" className="content">
                                <div id={getEditorDomElementId(elementId)}>
                                    <DocumentModalTiptapEditor
                                        {...props}
                                        {...tipTapDocumentEditingProps}
                                        textContent={textContent}
                                        isMobile
                                        isEditable={isEditable}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileDocumentSheet;
