// Lib
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { prop } from '../../../../../../common/utils/immutableHelper';

// Selectors
import popupOpenSelector from '../../../../../components/popupPanel/popupOpenSelector';

// Hooks
import {
    ActiveTiptapEditorSelector,
    useTiptapActiveEditorSelector,
} from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import ToolbarTool from '../../ToolbarTool';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import PopupTrigger from '../../../../../components/popupPanel/PopupTrigger';
import TiptapTextStylePopupContent from './textStylePopup/TiptapTextStylePopupContent';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';
import { EditorTextStyleToolSettings } from '../../../toolbarTypes';
import useClosePopupOnUnmount from '../../../../../components/popupPanel/useClosePopupOnUnmount';

const isHeadingLevel2ActiveSelector: ActiveTiptapEditorSelector = (editor) => editor?.isActive('heading', { level: 2 });

interface TiptapTextStyleToolButtonProps {
    isPopupOpen: boolean;
}

const TiptapTextStyleToolButton = ({ isPopupOpen }: TiptapTextStyleToolButtonProps): ReactElement => {
    return (
        <ToolbarTool className="TiptapTextStyleToolButton" isOpen={isPopupOpen} name="Text style">
            {/* using a div with a .png image here instead of a svg Icon
            because the gradient does not export correctly */}
            <div className="text-style-icon" />
        </ToolbarTool>
    );
};

interface TiptapTextStyleTool extends ToolbarToolProps {
    showBlockStyleTools?: boolean;
}

const TiptapTextStyleTool = (props: TiptapTextStyleTool): ReactElement => {
    const { uid, id, item, selectedElements } = props;

    const element = prop(0, selectedElements);

    const popupId = uid || id || PopupIds.BLOCK_STYLE;
    const isPopupOpen = useSelector(popupOpenSelector(popupId));

    const isHeadingLevel2Active = useTiptapActiveEditorSelector(isHeadingLevel2ActiveSelector);

    const popupArrowColor = isHeadingLevel2Active ? 'var(--popup-arrow-color)' : undefined;
    const showBlockStyleTools =
        (item?.toolSettings as EditorTextStyleToolSettings)?.showBlockStyleTools || props.showBlockStyleTools || false;

    // Close the popup when the component unmounts to prevent it from being left open
    //  and appearing the next time the tool shows
    useClosePopupOnUnmount(popupId);

    return (
        <div className="TiptapTextStyleTool">
            <PopupTrigger popupId={popupId} preventFocus>
                {(popupIsOpen) => <TiptapTextStyleToolButton isPopupOpen={popupIsOpen} />}
            </PopupTrigger>
            <ToolbarPopup
                arrowColor={popupArrowColor}
                popupId={popupId}
                buttonSelector=".TiptapTextStyleToolButton .icon"
                className="TextStylePopup"
                popupIsOpen={isPopupOpen}
            >
                <TiptapTextStylePopupContent showBlockStyleTools={showBlockStyleTools} element={element} />
            </ToolbarPopup>
        </div>
    );
};

export default TiptapTextStyleTool;
