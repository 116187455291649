// Lib
import * as Immutable from 'immutable';
import { get as getIn, isEmpty, omit } from 'lodash';

// Utils
import globalLogger, { LoggerComponents } from '../../../logger';
import {
    isAsyncEntityError,
    isAsyncEntityFetching,
} from '../../../utils/services/http/asyncResource/asyncResourceUtils';
import { SocketConnectionStatus, SocketReconnectionMode } from '../../../utils/socket/socketConstants';

// Constants
import { ResourceTypes } from '../../../utils/services/http/asyncResource/asyncResourceConstants';
import { INSTANT_APP_INITIAL_STATE_POJO_KEYS } from '../../../store/initialStateConstants';
import { VisibleConnectionStatus } from '../../visibleConnectionStatus/visibleConnectionStatusTypes';

const logger = globalLogger.createChannel(LoggerComponents.INSTANT_APP);

/**
 * Removes resource entity state if it's fetching or errored.
 */
const clearFetchingResources = (persistedState: any) => {
    if (!persistedState?.asyncResources) return persistedState;

    const resourceTypes = Object.keys(persistedState.asyncResources);

    for (const resourceType of resourceTypes) {
        const entityIds = Object.keys(persistedState.asyncResources?.[resourceType]) || [];

        for (const entityId of entityIds) {
            const entity = persistedState.asyncResources[resourceType][entityId];

            if (!isAsyncEntityFetching(entity) && !isAsyncEntityError(entity)) continue;

            logger.debug(`%c- Removing fetching/errored entity`, 'color: grey', resourceType, entityId, entity);

            delete persistedState.asyncResources[resourceType][entityId];
        }
    }

    return persistedState;
};

/**
 *
 */
const setSocketDisconnectionTimes = (persistedState: any, cacheTimestamp: number) => {
    if (!persistedState?.app) return persistedState;

    persistedState.app.socketConnection = {
        ...persistedState.app.socketConnection,
        status: SocketConnectionStatus.DISCONNECTED,
        reconnectionMode: SocketReconnectionMode.REHYDRATION,
        visibleConnectionStatus: VisibleConnectionStatus.connecting,
        interruptionTime: cacheTimestamp,
        disconnectionTime: cacheTimestamp,
        channels: [],
        boardChannels: [],
    };

    return persistedState;
};

/**
 * Removes resource entity state if it's in the trash.
 */

const clearTrashResources = (persistedState: any) => {
    if (!persistedState?.asyncResources) return persistedState;

    const trashResource = persistedState.asyncResources[ResourceTypes.trash];

    if (trashResource && !isEmpty(trashResource)) {
        delete persistedState.asyncResources[ResourceTypes.trash];
    }

    return persistedState;
};

/**
 * Creates a new initial state ImmutableJS object from a persisted state object.
 *
 * @param state a persisted state object in serialised json/pojo form
 * @param pojoKeys state keys that should not be converted to ImmutableJS
 * @param omitKeys state keys that should not be rehydrated
 * @returns an ImmutableJS object representing initial state of the app
 */
export const produceImmutableReducerState = (
    state: any,
    pojoKeys: string[],
    omitKeys: string[],
): Immutable.Map<string, any> => {
    const pojoState = omit(state, [...omitKeys, ...pojoKeys]);

    const immutableState = Immutable.fromJS(pojoState);

    const rehydratedState = immutableState.withMutations((mutableState: Immutable.Map<string, any>) => {
        pojoKeys.forEach((field) => {
            const value = getIn(state, field);

            if (value !== undefined) {
                const path = field.split('.');
                mutableState.setIn(path, value);
            }
        });
    });

    return rehydratedState;
};

/**
 * Converts the state persisted in IndexedDB to the ImmutableJS structure
 * expected by the redux store.
 */
export const rebuildPersistedState = (
    persistedState: any,
    cacheTimestamp: number,
    cacheVersion: string,
): Immutable.Map<string, any> => {
    persistedState = setSocketDisconnectionTimes(persistedState, cacheTimestamp);
    persistedState = clearFetchingResources(persistedState);
    persistedState = clearTrashResources(persistedState);

    // Initialise the rehydration state.
    // It's simpler to do this here rather than firing a redux action to set the state
    persistedState.localCache = {
        hydration: {
            hydrated: true,
            hydrationTimestamp: Date.now(),
            cacheTimestamp,
            cacheVersion,
        },
    };

    const rehydratedState = produceImmutableReducerState(persistedState, INSTANT_APP_INITIAL_STATE_POJO_KEYS, []);

    return rehydratedState;
};
