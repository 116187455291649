import React, { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

// Utils
import { getArea, reverseTranslate } from '../../../common/maths/geometry/rect';
import { hideTooltip } from './tooltipActions';
import { getArrowPosition, getShouldDisplayArrow, getTooltipPosition } from './tooltipUtil';
import { getFloatingPanelMountPoint } from './floatingPanelUtils';

// Components
import FloatingPanelErrorBoundary from './FloatingPanelErrorBoundary';
import FloatingPanelArrow from './FloatingPanelArrow';
import { CSSTransitionGroup } from '../../../node_module_clones/react-transition-group';

// Types
import { HIDDEN_POSITION, TooltipAlignment, TooltipPositions } from './tooltipConstants';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';

import './FloatingPanel.scss';

const EMPTY_RECT: Rect = {
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
};

const HIDDEN_RECT: Rect = {
    ...EMPTY_RECT,
    top: HIDDEN_POSITION.top,
    left: HIDDEN_POSITION.left,
    x: HIDDEN_POSITION.left,
    y: HIDDEN_POSITION.top,
};

const getPanelRect = (panelRef: RefObject<HTMLElement>): Rect => {
    if (!panelRef.current) return EMPTY_RECT;
    return panelRef.current.getBoundingClientRect();
};

const getArrowRect = (arrowRef: RefObject<HTMLElement>): Rect => {
    if (!arrowRef.current) return EMPTY_RECT;
    return arrowRef.current.getBoundingClientRect();
};

const getPanelTargetRect = (
    targetElement?: HTMLElement | null,
    relativeParentElement?: HTMLElement | null,
    targetRect?: Rect,
): Rect => {
    let panelTargetRect: Rect = targetRect || targetElement?.getBoundingClientRect() || EMPTY_RECT;
    if (!panelTargetRect) {
        console.warn('FloatingPanel: Could not find target rect');
        return EMPTY_RECT;
    }

    const relativeParentRect = relativeParentElement?.getBoundingClientRect();
    if (relativeParentRect) {
        panelTargetRect = reverseTranslate(
            {
                x: relativeParentRect.x,
                y: relativeParentRect.y,
            },
            panelTargetRect,
        );
    }

    return panelTargetRect;
};

type FloatingPanelProps = {
    responsive?: boolean;
    selector?: string;
    mountPointSelector?: string;
    relativeParentSelector?: string;
    delay?: number;
    offset?: number;
    duration?: number;
    className?: string;
    showArrow?: boolean;
    arrowColor?: string;
    arrowStrokeColor?: string;
    arrowWidth?: number;
    arrowHeight?: number;
    arrowStrokeWidth?: number;
    arrowStyle?: React.CSSProperties;
    targetRect?: DOMRect;
    style?: React.CSSProperties;
    insets?: Rect;
    fade?: boolean;
    position?: TooltipPositions;
    alignment?: TooltipAlignment;
    children?: React.ReactElement;
    pollPosition?: boolean;
    hideOnEvent?: string;
    dispatchHideTooltip?: () => void;
    onMouseDown?: () => void;
    onClick?: () => void;
    gridSize?: number;
    distance?: number;
};

const FloatingPanel = (props: FloatingPanelProps) => {
    const {
        children,
        className,
        fade,
        style,
        arrowColor = 'var(--arrow-color)',
        arrowStrokeColor = 'var(--arrow-stroke-color)',
        arrowWidth = 9,
        arrowHeight = 7,
        arrowStrokeWidth = 1,
        responsive,
        onMouseDown,
        onClick,
        mountPointSelector,
        pollPosition,
        hideOnEvent = 'click',
        relativeParentSelector,
        selector,
        delay,
        duration,
        targetRect,
        insets = { top: 0, right: 0, bottom: 0, left: 0 },
    } = props;

    const dispatch = useDispatch();

    const panelRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);

    const showPanelDelayRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const hidePanelDelayRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const requestAnimationFrameRef = useRef<number | null>(null);

    const [hidden, setHidden] = useState<boolean>(true);

    const updatePosition = (panelRect: Rect, panelTargetRect: Rect): Rect => {
        if (!panelRef.current) return HIDDEN_RECT;

        // If the target takes up no space on the screen, then don't show the panel
        if (getArea(panelTargetRect) === 0) {
            panelRef.current.style.top = `${HIDDEN_POSITION.top}px`;
            panelRef.current.style.left = `${HIDDEN_POSITION.left}px`;
            return HIDDEN_RECT;
        }

        const position = getTooltipPosition(panelTargetRect, panelRect, props);

        panelRef.current.style.top = `${position.top}px`;
        panelRef.current.style.left = `${position.left}px`;

        // FIXME - WEB-12496 - We should try to avoid using window.innerWidth and window.innerHeight, especially
        //  when being called during a requestAnimationFrame. This can cause layout thrashing.
        const availableHeight = window.innerHeight - insets.top - insets.bottom;
        const availableWidth = window.innerWidth - insets.left - insets.right;

        panelRef.current.style.setProperty('--top-position', `${position.top}px`);
        panelRef.current.style.setProperty('--left-position', `${position.left}px`);
        panelRef.current.style.setProperty('--popup-available-height', `${availableHeight}px`);
        panelRef.current.style.setProperty('--popup-available-width', `${availableWidth}px`);

        return position;
    };

    const updateArrow = (panelRect: Rect, arrowRect: Rect, panelTargetRect: Rect) => {
        if (!arrowRef.current) return;

        if (!getShouldDisplayArrow(props)) {
            arrowRef.current.style.visibility = 'hidden';
            return;
        }

        const arrowPosition = getArrowPosition(panelTargetRect, panelRect, arrowRect, props);

        arrowRef.current.style.top = `${arrowPosition.top}px`;
        arrowRef.current.style.left = `${arrowPosition.left}px`;
        arrowRef.current.style.transform = `rotate(${arrowPosition.rotate}deg)`;

        if (props.arrowStyle && props.arrowStyle.transform) {
            arrowRef.current.style.transform = `${arrowRef.current.style.transform} ${props.arrowStyle.transform}`;
        }

        arrowRef.current.style.visibility = 'visible';
        panelRef.current?.classList.add(arrowPosition.side);
    };

    const updatePanel = (currentSelector: string | undefined) => {
        if (!panelRef.current) {
            // if panel is not mounted, try again next frame
            requestAnimationFrame(() => updatePanel(currentSelector));
            return;
        }

        const targetElement: HTMLElement | null = selector ? document.querySelector(selector) : null;
        const relativeParentElement: HTMLElement | null = relativeParentSelector
            ? document.querySelector(relativeParentSelector)
            : null;

        const panelRect = getPanelRect(panelRef);
        const arrowRect = getArrowRect(arrowRef);
        const panelTargetRect = getPanelTargetRect(targetElement, relativeParentElement, targetRect);

        const updatedPanelRect = updatePosition(panelRect, panelTargetRect);
        updateArrow(updatedPanelRect, arrowRect, panelTargetRect);

        if (pollPosition) {
            // poll position each frame
            requestAnimationFrameRef.current = requestAnimationFrame(() => updatePanel(currentSelector));
        }
    };

    const hidePanel = () => {
        dispatch(hideTooltip());
    };

    const showPanel = () => {
        setHidden(false);
        updatePanel(selector);
    };

    useEffect(() => {
        if (hidden) return;

        updatePanel(selector);

        return () => {
            if (requestAnimationFrameRef.current) {
                cancelAnimationFrame(requestAnimationFrameRef.current);
            }
        };
    }, [selector]);

    // mount / unmount
    useEffect(() => {
        document.addEventListener(hideOnEvent, hidePanel, {
            capture: true,
        });

        if (delay) {
            showPanelDelayRef.current = setTimeout(showPanel, delay);
        } else {
            showPanel();
        }

        if (duration) {
            hidePanelDelayRef.current = setTimeout(hidePanel, duration);
        }

        return () => {
            document.removeEventListener(hideOnEvent, hidePanel, {
                capture: true,
            });
            if (showPanelDelayRef.current) clearTimeout(showPanelDelayRef.current);
            if (hidePanelDelayRef.current) clearTimeout(hidePanelDelayRef.current);
        };
    }, []);

    const tipStyle = {
        ...HIDDEN_POSITION,
        ...style,
    };

    const arrowStyle: CSSProperties = {
        ...props.arrowStyle,
        position: 'absolute',
        width: arrowWidth,
        height: arrowHeight,
    };

    const floatingPanel = !hidden ? (
        <div
            ref={panelRef}
            className={classNames('FloatingPanel', className, { hide: hidden, responsive, fade })}
            onPointerDown={onMouseDown}
            onClick={onClick}
            style={tipStyle}
        >
            <FloatingPanelArrow
                ref={arrowRef}
                width={arrowWidth}
                height={arrowHeight}
                color={arrowColor}
                strokeColor={arrowStrokeColor}
                strokeWidth={arrowStrokeWidth}
                style={arrowStyle}
            />
            <div className="content">
                <FloatingPanelErrorBoundary {...props}>{children}</FloatingPanelErrorBoundary>
            </div>
        </div>
    ) : null;

    const transitionWrapper = fade ? (
        <CSSTransitionGroup transitionName="fade-move" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
            {floatingPanel}
        </CSSTransitionGroup>
    ) : (
        floatingPanel
    );

    const portalMountPoint = getFloatingPanelMountPoint(mountPointSelector);
    if (!portalMountPoint) return null;

    return createPortal(transitionWrapper, portalMountPoint);
};

export default FloatingPanel;
