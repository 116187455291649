// Lib
import React from 'react';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';
import useSheet from '../../../../../mobile/structural/sheet/hooks/useSheet';
import MobileLineWeightSheet from './MobileLineWeightSheet';

// Types
import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

type MobileLineWeightToolProps = {
    selectedElements: Immutable.List<ImMNElement>;
    name: string;
    gridSize: number;
};

const MobileLineWeightTool = (props: MobileLineWeightToolProps) => {
    const sheetProps = useSheet(SheetId.LineWeightTool);
    const { isSheetOpen, dispatchOpenSheet } = sheetProps;

    return (
        <div className="LineweightTool">
            <ToolbarTool {...props} isOpen={isSheetOpen} onClick={dispatchOpenSheet}>
                <Icon {...props} name="toolbar-line-weight" />
            </ToolbarTool>
            <MobileLineWeightSheet sheetProps={sheetProps} />
        </div>
    );
};

export default MobileLineWeightTool;
