import { clamp } from 'lodash';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { getDomElementId, getEditorDomElementId } from '../../../element/utils/elementUtil';
import virtualKeyboardMaxHeightSingleton from '../../../utils/keyboard/virtualKeyboardMaxHeightSingleton';

const SCROLL_PADDING = 50;

export type ScrollFocus = { x: number; y: number };

export const getContentDomElement = (elementId: string) => {
    // If there is a separate editor component open for this element, use that
    const editorDomId = getEditorDomElementId(elementId);
    const editorElement = document.getElementById(editorDomId);
    if (editorElement) return editorElement;

    const elementDomId = getDomElementId(elementId);
    const elementDom = document.getElementById(elementDomId);

    // Otherwise, use the element itself
    return elementDom;
};

/**
 * Horizontally centers the element that's being edited.
 */
const getTargetScrollLeft = (
    scrollableParent: HTMLElement,
    scrollableParentRect: Rect,
    elementRect: Rect,
    scrollFocus: ScrollFocus | undefined,
) => {
    // If the element is smaller in width than the scrollableParent, center the element horizontally on screen
    const isElementSmallerThanPageView = elementRect.width < scrollableParentRect.width;
    if (isElementSmallerThanPageView) {
        const centerOffsetX = (scrollableParentRect.width - elementRect.width) / 2;

        return elementRect.left - centerOffsetX;
    }

    // If scrollFocus is not provided, scroll to the left of the element (with padding)
    if (!scrollFocus) return elementRect.left - SCROLL_PADDING;

    // If the element is larger than the window, and scrollFocus is provided, we want to scroll
    // to the scrollFocus point
    return clamp(
        scrollableParent.scrollLeft + scrollFocus.x - 100,
        elementRect.right - scrollableParentRect.width + SCROLL_PADDING,
    );
};

/**
 * Gets the target scrollTop property for the scrollable element.
 */
const getTargetScrollTop = (
    scrollableParent: HTMLElement,
    scrollableParentRect: Rect,
    elementRect: Rect,
    scrollFocus: ScrollFocus | undefined,
    bottomObstructionHeight: number,
) => {
    let pageViewHeight = scrollableParentRect.height;

    if (scrollableParentRect.height > virtualKeyboardMaxHeightSingleton.getVirtualKeyboardMaxHeight()) {
        // Since scrollableParent doesn't cover the primary toolbar and bottom safe area, whereas bottomObstructionHeight does,
        // we want to get the height of scrollableParent that spans to the bottom of the page,
        // in order to get an accurate pageViewHeight
        const scrollableParentHeightToBottom = window.innerHeight - scrollableParentRect.top;
        pageViewHeight = scrollableParentHeightToBottom - bottomObstructionHeight;
    }

    // If the element is smaller in height than the scrollableParent, center the element vertically on screen
    const isElementSmallerThanPageView = elementRect.height < pageViewHeight;
    if (isElementSmallerThanPageView) {
        const centerOffsetY = (pageViewHeight - elementRect.height) / 2;
        return elementRect.top - centerOffsetY;
    }

    // If scrollFocus is not provided, scroll to the top of the element (with padding)
    if (!scrollFocus) return elementRect.top - SCROLL_PADDING;

    // Otherwise, scroll to the scrollFocus point
    return scrollableParent.scrollTop + scrollFocus.y - scrollableParentRect.top - pageViewHeight / 2;
};

/**
 * Determines the target scroll position for the scrollable element.
 */
export const getTargetScrollToElement = (
    scrollableParent: HTMLElement,
    elementRect: Rect,
    scrollFocus: ScrollFocus | undefined,

    // This is the height of the obstruction at the bottom of the screen to account for
    // when getting for target scroll position, such as keyboard or sheet
    bottomObstructionHeight: number,
) => {
    const scrollableParentRect = scrollableParent.getBoundingClientRect();

    const targetScrollLeft = getTargetScrollLeft(scrollableParent, scrollableParentRect, elementRect, scrollFocus);
    const targetScrollTop = getTargetScrollTop(
        scrollableParent,
        scrollableParentRect,
        elementRect,
        scrollFocus,
        bottomObstructionHeight,
    );

    const maxScrollLeft = scrollableParent.scrollWidth - scrollableParentRect.width;
    const maxScrollTop = scrollableParent.scrollHeight - scrollableParentRect.height;

    // Clamp the target scroll values to the scrollable element's bounds
    return {
        left: clamp(targetScrollLeft, 0, maxScrollLeft),
        top: clamp(targetScrollTop, 0, maxScrollTop),
    };
};
