export const CAPACITOR_LIFECYCLE_EVENT = 'CAPACITOR_LIFECYCLE_EVENT';

/**
 * Capacitor App Lifecycle State Machine (as far as I'm aware):
 *
 * ACTIVE ──────► INACTIVE ──────► BACKGROUND ──────► SUSPENDED ──────► TERMINATED
 *   ▲               │                 │                  │                 │
 *   └───────────────└─────────────────└──────────────────└─────────────────┘
 */

export enum CapacitorAppStatus {
    // App is in the foreground
    ACTIVE = 'active',
    // App is in the foreground, but not receiving events
    //  E.g. Briefly while the app is transitioning from active to background, or during a call
    INACTIVE = 'inactive',
    // App is in the background and executing code
    BACKGROUND = 'background',
    // App is in the background and not executing code
    SUSPENDED = 'suspended',
    // App is terminated (not running)
    // NOTE: The state will never be terminated (because we can't update it at that time),
    //  but it is included for completeness
    TERMINATED = 'terminated',
}

export type CapacitorLifecycleAction = {
    type: typeof CAPACITOR_LIFECYCLE_EVENT;
    status: CapacitorAppStatus;
    lifecycleEvent: string;
    timestamp: number;
};
