import { Capacitor } from '@capacitor/core';
import { CapacitorConfig } from '../../common/config/configTypes';
import getClientConfig from '../utils/getClientConfig';

/**
 * Conditionally load the NewRelicCapacitorPlugin if it's enabled.
 *
 * NOTE: We're conditionally using a dynamic await import here to avoid performance issues
 *  that would occur if the plugin was imported at the top of the file within the web codebase.
 */
export const getNewRelicCapacitorPluginIfSafe = async (clientConfig = getClientConfig() as CapacitorConfig) => {
    if (!clientConfig.newrelic.capacitorAppToken) return null;
    return await import('@newrelic/newrelic-capacitor-plugin');
};

/**
 * Sets the given user ID on the NewRelicCapacitorPlugin
 *
 * @param userId the user ID to set
 */
export const setNewRelicUserIdCapacitor = async (userId: string | null) => {
    // Native library (through Capacitor plugin)
    if (!Capacitor.isPluginAvailable('NewRelicCapacitorPlugin')) return;

    const plugin = await getNewRelicCapacitorPluginIfSafe();
    if (!plugin) return;

    plugin.NewRelicCapacitorPlugin.setUserId({ userId: userId || 'guest' });
};
