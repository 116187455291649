// Utils
import {
    getAsyncEntityFetchedTime,
    isAsyncEntityFetched,
    isAsyncEntityFetching,
} from '../services/http/asyncResource/asyncResourceUtils';

// Selectors
import { selectSocketDisconnectionTime } from '../socket/socketConnectionSelector';
import { getAsyncResourceEntityState } from '../services/http/asyncResource/asyncResourceSelector';

// Actions
import { refreshIfModified } from '../../element/board/boardRefreshService';

// Types
import { UnknownAction } from 'redux';
import { ReduxStore } from '../../types/reduxTypes';
import { ResourceTypes } from '../services/http/asyncResource/asyncResourceConstants';
import { CURRENT_BOARD_ID_SET } from '../../reducers/currentBoardId/currentBoardIdConstants';
import { AsyncResourceEntity } from '../services/http/asyncResource/reducers/asyncResourceReducerTypes';

/**
 * Trigger a "refresh if modified" call for the current board if:
 * - It's been fetched before, and
 * - We've been disconnected since the last fetch
 */
const shouldCheckForModifications = ({
    boardResource,
    disconnectionTime,
}: {
    boardResource: AsyncResourceEntity;
    disconnectionTime: number;
}) => {
    // If it's not fetched, let the standard fetching logic handle it (in workspaceCurrentBoardFetchDecorator)
    if (!isAsyncEntityFetched(boardResource)) return false;
    if (isAsyncEntityFetching(boardResource)) return false;

    const fetchedTime = getAsyncEntityFetchedTime(boardResource);

    return !fetchedTime || fetchedTime < disconnectionTime;
};

/**
 * Triggers a "refresh if modified" call for the current board if it's been fetched before,
 * and we've been disconnected since the last fetch.
 *
 * Other logic relating to board fetching exists in:
 * - RefreshStaleBoardManager
 * - workspaceCurrentBoardFetchDecorator
 * - BoardChildrenLoadObserver
 *
 * The first two might be good candidates for refactoring to use this middleware.
 */
export default (store: ReduxStore) => (next: Function) => (action: UnknownAction) => {
    if (action.type !== CURRENT_BOARD_ID_SET) return next(action);

    const currentBoardId = action.boardId as string;

    // The current board has been changed, so we want to refresh the board if
    //  it's been modified since we last fetched it. But we only need to do this
    //  if we've been disconnected since the fetch, because otherwise it'll already
    //  be up to date, via the websocket.
    const state = store.getState();

    const disconnectionTime = selectSocketDisconnectionTime(state);

    // Get the board's resource state
    const boardResource = getAsyncResourceEntityState(state, ResourceTypes.boards, currentBoardId);

    if (shouldCheckForModifications({ boardResource, disconnectionTime })) {
        store.dispatch(
            refreshIfModified({
                boardId: currentBoardId,
                loadAncestors: true,
                afterTimestamp: disconnectionTime,
            }),
        );
    }

    return next(action);
};
