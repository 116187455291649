// Lib
import React, { ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// Selectors
import { getPlatformTypeSelector } from '../../../../../../platform/platformSelector';

// Hooks
import { useActiveTiptapEditorCallback } from '../../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Types
import { PlatformType } from '../../../../../../../common/platform/platformTypes';

// Styles
import './TextStylePopupContent.scss';

interface TextStylePopupContentProps {
    showBlockStyleTools: boolean;
    blockStyleComponents: ReactNode;
    textColorComponents: ReactNode;
    textHighlightComponents: ReactNode;
}

const TextStylePopupContent = (props: TextStylePopupContentProps): ReactElement => {
    const { showBlockStyleTools, blockStyleComponents, textColorComponents, textHighlightComponents } = props;

    const platformType = useSelector(getPlatformTypeSelector);
    const isIpad = platformType === PlatformType.ipad;

    // This is required for iPad to keep the focus on the editor after the click event
    const keepFocus = useActiveTiptapEditorCallback((activeEditor) => {
        activeEditor?.commands.focus();
    }, []);

    return (
        <div
            className={classNames('TextStylePopupContent', { 'color-only': !showBlockStyleTools })}
            onClick={isIpad ? keepFocus : undefined}
        >
            {showBlockStyleTools && <div className="block-styles">{blockStyleComponents}</div>}

            <div className="text-color-section color">
                <div className="text-color-section-title">Color</div>
                <div className="text-color-row">{textColorComponents}</div>
            </div>

            <div className="text-color-section background">
                <div className="text-color-section-title">Highlight</div>
                <div className="text-color-row">{textHighlightComponents}</div>
            </div>
        </div>
    );
};

export default TextStylePopupContent;
