// Lib
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import * as Immutable from 'immutable';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformIos } from '../../platform/utils/platformDetailsUtils';
import * as pointsLib from '../../../common/maths/geometry/point';
import findScrollableDomParent from '../../utils/dom/findScrollableDomParent';
import { getTopLeftBoundaryPoint } from '../../../common/documentOrder/geometry/point';
import { getRectCenterPoint } from '../../../common/maths/geometry/rect';
import { stopPropagationOnly } from '../../utils/domUtil';
import { translateDOMRectIntoScrollableParentCoordinates } from '../../utils/dom/scrollableParentUtils';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { isColumn } from '../../../common/elements/utils/elementTypeUtils';
import { getElements } from '../selectors/elementSelector';
import { transformCanvasViewportCoordsRectIntoCanvasDocumentCoords } from '../../canvas/utils/canvasPositionUtils';

// Measurements
import measurementsRegistry from '../../components/measurementsStore/measurementsRegistry';

// Selectors
import { getDraggedLineEdgesPx } from '../line/lineEdgeSelector';
import { getCurrentVisibleBoardCanvasOrigin } from '../selectors/currentBoardSelector';
import { getDragModifierKeys } from '../../utils/dnd/modifierKeys/dragModifierKeysSelector';
import { getShallowMeasurementsMap } from '../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Actions
import { deselectAll } from '../../../common/elements/selectionActions';
import { finishEditingElement } from '../selection/selectionActions';
import { createAndEditElement } from '../actions/elementActions';

// Hooks
import usePreventTouchScroll from '../../utils/dom/usePreventTouchScroll';

// Components
import Icon from '../../components/icons/Icon';
import TooltipSource from '../../components/tooltips/TooltipSource';

// Constants
import { MODIFIER_KEYS } from '../../utils/dnd/modifierKeys/dragModifierKeysConstants';
import { ELEMENT_CREATION_SOURCES } from '../../../common/elements/elementConstants';
import { TooltipPositions } from '../../components/tooltips/tooltipConstants';
import { LINE_EDGE_DRAG_PREVIEW_ID } from '../../reducers/draggingConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { NO_DRAG_OFFSET } from '../../utils/dnd/dndConstants';
import { TIMES } from '../../../common/utils/timeUtil';
import {
    LINE_EDGE,
    LINE_EDGE_DND_TYPE,
    LINE_MARKER_STYLE,
    LINE_STYLE,
    LINE_WEIGHT,
} from '../../../common/lines/lineConstants';
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import './QuickLineCreationTool.scss';

const getPreviewLineElement = ({ currentBoardId, startElementId, endSnapped = false, endElementId }) =>
    Immutable.fromJS({
        id: LINE_EDGE_DRAG_PREVIEW_ID,
        elementType: ElementType.LINE_TYPE,
        location: {
            parentId: currentBoardId,
            position: { x: 0, y: 0 },
            section: BoardSections.CANVAS,
        },
        content: {
            // Snap the start point to the current element
            start: { snapped: true, elementId: startElementId, x: 0, y: 0 },
            end: { snapped: endSnapped, elementId: endElementId, x: 0, y: 0 },
            lineWeight: LINE_WEIGHT.M,
            lineStyle: LINE_STYLE.SOLID,
            startStyle: LINE_MARKER_STYLE.NONE,
            endStyle: LINE_MARKER_STYLE.ARROW,
        },
    });

const createLineOnDrop =
    ({
        element,
        currentBoardId,
        gridSize,
        offsetDiff,
        scrollDiff,
        startElementId,
        startOrigin,
        endPosition,
        initialClientOffset,
        initialSourceClientOffset,
    }) =>
    (dispatch, getState, { measurementsStore }) => {
        const pos = {
            ...endPosition,
            ...startOrigin,
        };

        const previewElement = getPreviewLineElement({
            currentBoardId,
            startElementId,
            endElementId: endPosition.elementId,
            endSnapped: endPosition.snapped,
        });

        // We want to create a line which starts snapped at the centre point of the element
        // and finishes with the correct offset from that centre point, potentially snapped to another element
        const measurementsState = measurementsStore.getState();
        const measurements = getShallowMeasurementsMap(measurementsState);

        const state = getState();

        const shouldSnapToAngle = getDragModifierKeys(state).get(MODIFIER_KEYS.shiftKey);

        const { startEdgeOrigin, endEdgeOrigin } = getDraggedLineEdgesPx(state, {
            element: previewElement,
            hoveredElementId: endPosition.elementId,
            draggedEdge: LINE_EDGE.end,
            offsetDiff,
            scrollDiff,
            pos,
            initialClientOffset,
            initialSourceClientOffset,
            shouldSnapToAngle,
            measurements,
        });

        // Move the line element to be a bounding box around the line. So the top left point is 0,0
        let translation = getTopLeftBoundaryPoint([startEdgeOrigin, endEdgeOrigin]);

        const scaleToGridUnits = pointsLib.scale(1 / gridSize);
        const lineElementPosition = pointsLib.round(scaleToGridUnits(translation));
        // Ensure the translation sits on grid points - not 100% sure if this is really necessary
        translation = pointsLib.scale(gridSize, lineElementPosition);

        const lineStartPosition = scaleToGridUnits(pointsLib.reverseTranslate(translation, startEdgeOrigin));
        const lineEndPosition = scaleToGridUnits(pointsLib.reverseTranslate(translation, endEdgeOrigin));

        // We need to deselect the line when dropping from a column onto another column, due to the jump in origin
        const elements = getElements(state);
        const endElement = getElement(elements, endPosition.elementId);
        const shouldSelect = !(isColumn(element) && isColumn(endElement));

        dispatch(
            createAndEditElement({
                elementType: ElementType.LINE_TYPE,
                location: {
                    parentId: currentBoardId,
                    section: BoardSections.CANVAS,
                    position: lineElementPosition,
                },
                currentBoardId,
                edit: false,
                select: shouldSelect,
                creationSource: ELEMENT_CREATION_SOURCES.QUICK_LINE,
                content: {
                    start: {
                        snapped: true,
                        elementId: startElementId,
                        ...lineStartPosition,
                    },
                    end: {
                        ...endPosition,
                        ...lineEndPosition,
                    },
                },
            }),
        );
    };

const getInitialEndDragPosition = (domNode, canvasOrigin, zoomScale, zoomTranslationPx, gridSize) => {
    const scrollableParent = findScrollableDomParent(domNode);
    const boundingRect = domNode.getBoundingClientRect();
    const transformedRect = translateDOMRectIntoScrollableParentCoordinates(scrollableParent, boundingRect);
    const canvasSpaceRect = transformCanvasViewportCoordsRectIntoCanvasDocumentCoords(
        transformedRect,
        gridSize,
        canvasOrigin,
        zoomScale,
        zoomTranslationPx,
    );

    return getRectCenterPoint(canvasSpaceRect);
};

const ConnectedQuickLineCreationToolHandle = (props) => {
    const { connectDragSource, isDragging, positionRef, connectDragPreview, onPointerDown } = props;

    useEffect(() => {
        connectDragPreview(getEmptyImage());
    }, []);

    return connectDragSource(
        <div
            className={classNames('QuickLineCreationTool', { dragging: isDragging })}
            ref={positionRef}
            onMouseDown={stopPropagationOnly}
            onPointerDown={onPointerDown}
        >
            <Icon name="quick-line-circle" />
            <div className="arrow-container">
                <TooltipSource
                    tooltipText="Drag me"
                    position={TooltipPositions.RIGHT}
                    distance={12}
                    offset={2}
                    duration={TIMES.SECOND * 1.2}
                    pollPosition
                    enabled
                    fade
                    triggerOnClick
                >
                    <Icon name="quick-line-arrow" />
                </TooltipSource>
            </div>
        </div>,
    );
};

const ConnectedQuickLineCreationTool = (props) => {
    const {
        elementId: startElementId,
        element,
        currentBoardId,
        gridSize,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const { onPointerDown } = usePreventTouchScroll();

    const positionRef = useRef();

    const canvasOrigin = useSelector(getCurrentVisibleBoardCanvasOrigin);

    const dispatch = useDispatch();

    const dispatchCreateLineOnDrop = (args) => dispatch(createLineOnDrop(args));
    const dispatchDeselectAll = (elementId) => {
        dispatch(deselectAll({}));
        dispatch(finishEditingElement(elementId));
    };
    const dispatchFinishEditingElement = (elementId) => {
        dispatch(finishEditingElement(elementId));
    };

    const [dragProps, connectDragSource, connectDragPreview] = useDrag({
        item: { id: LINE_EDGE_DRAG_PREVIEW_ID, type: LINE_EDGE_DND_TYPE },
        begin: () => {
            const zoomScale = getContextZoomScale();
            const zoomTranslationPx = getContextZoomTranslationPx?.();

            // Point from canvas (0,0) to top right of element
            const rectCentre = getInitialEndDragPosition(
                positionRef.current,
                canvasOrigin,
                zoomScale,
                zoomTranslationPx,
                gridSize,
            );

            // If the quick line tool is held-down and the drag starts, the quick line tool will be removed
            // from the DOM when its deselected. On iOS, this will cause the canvas to enter "scrolling mode"
            // and will break the quick line drag, as well as any following drags. So keep the element selected
            // to avoid these issues on iOS
            requestAnimationFrame(() => {
                isPlatformIos(platformSingleton)
                    ? dispatchFinishEditingElement(startElementId)
                    : dispatchDeselectAll(startElementId);
            });

            const initialScrollPoint = measurementsRegistry.getCanvasViewportScrollAsPoint();

            // There's two ways to do this:
            //  #1 - Pretend the preview line element is positioned where the quick line creation tool is
            //  #2 - Pretend the preview line is at 0,0 on the canvas and then translate the end point using "pos"
            //       which would usually refer to the starting point of the end edge.
            //       I've chosen #2 here as #1 would involve faking the measurements map in LineDragPreview.js which
            //       felt like it would be messier, to me
            return {
                id: LINE_EDGE_DRAG_PREVIEW_ID,
                edge: LINE_EDGE.end,
                // The initial position of the line edge
                element: getPreviewLineElement({ currentBoardId, startElementId }),
                // Start the line edge from the tool's center point
                pos: {
                    snapped: false,
                    ...rectCentre,
                },
                initialScrollPoint,
            };
        },
        end: (item, monitor) => {
            // Doing nothing if it was not dropped onto a compatible target
            const dropResult = monitor.getDropResult();

            if (!monitor.didDrop() || isEmpty(dropResult) || dropResult.ignore) return;

            const {
                offsetDiff = NO_DRAG_OFFSET,
                newPosition,
                initialClientOffset,
                initialSourceClientOffset,
            } = dropResult;

            // Get the starting position
            const { pos, initialScrollPoint } = monitor.getItem();

            const endScrollPoint = measurementsRegistry.getCanvasViewportScrollAsPoint();
            const scrollDiff = pointsLib.difference(initialScrollPoint, endScrollPoint);

            dispatchCreateLineOnDrop({
                element,
                currentBoardId,
                gridSize,
                offsetDiff,
                scrollDiff,
                startElementId,
                startOrigin: pos,
                endPosition: newPosition,
                initialClientOffset,
                initialSourceClientOffset,
            });
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <ConnectedQuickLineCreationToolHandle
            {...props}
            {...dragProps}
            onPointerDown={onPointerDown}
            positionRef={positionRef}
            canvasOrigin={canvasOrigin}
            connectDragPreview={connectDragPreview}
            connectDragSource={connectDragSource}
        />
    );
};

ConnectedQuickLineCreationTool.propTypes = {
    currentBoardId: PropTypes.string.isRequired,
    elementId: PropTypes.string,
    element: PropTypes.object,

    gridSize: PropTypes.number.isRequired,
    canvasOrigin: PropTypes.object,

    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

ConnectedQuickLineCreationToolHandle.propTypes = {
    ...ConnectedQuickLineCreationTool.propTypes,

    positionRef: PropTypes.any,

    isDragging: PropTypes.bool,
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
};

export default ConnectedQuickLineCreationTool;
