import React, { ReactElement } from 'react';

// Components
import SheetContainer, { Content, Overlay, Sheet } from '../../../../../mobile/structural/sheet';
import { UseSheetReturnType } from '../../../../../mobile/structural/sheet/hooks/useSheet';

// Styles
import LineWeightPopupContent from './LineWeightPopupContent';

import './MobileLineWeightSheet.scss';

export type MobileLineWeightSheetProps = {
    sheetProps: UseSheetReturnType;
};

const MobileLineWeightSheet = (props: MobileLineWeightSheetProps): ReactElement => {
    const { sheetProps } = props;

    return (
        <SheetContainer className="MobileLineWeightSheet" {...sheetProps}>
            <Overlay />
            <Sheet>
                <Content>
                    <div className="line-weight-content">
                        <LineWeightPopupContent close={sheetProps.dispatchCloseSheet} />
                    </div>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileLineWeightSheet;
