// Lib
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import FinalForm from '../../../components/form/finalForm/FinalForm';
import FinalField from '../../../components/form/finalForm/FinalField';
import FormInput from '../../../components/form/fields/FormInput';
import StyledButton from '../../../components/buttons/StyledButton';
import Spinner from '../../../components/loaders/Spinner';
import AuthErrorMessage from '../../../components/form/messages/AuthErrorMessage';
import PasswordInput from '../../../components/form/fields/PasswordInput';
import FormRow from '../../../components/form/FormRow';
import RouteLink from '../../../components/buttons/RouteLink';

// Hooks
import useGetSocialOauthError from '../../useGetSocialOauthError';

// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformPhoneOrMobileMode, isPlatformTablet } from '../../../platform/utils/platformDetailsUtils';
import formSubmissionExceptionHandler from '../../../components/form/formSubmissionExceptionHandler';
import { shouldDisableSubmitButton } from '../../../components/form/finalForm/finalFormUtils';

// Constants
import { FIELDS } from '../../../../common/validation/validationConstants';

const LOGIN_FORM_NAME = 'login';
const LOGIN_FIELDS = [FIELDS.email, FIELDS.loginPassword];

const LoginForm = (props) => {
    const { deviceId, location, dispatchLogin } = props;

    const emailInputRef = useRef(null);
    const initialValues = useMemo(() => ({ deviceId }), [deviceId]);
    const oauthError = useGetSocialOauthError(location);

    const onSubmit = useCallback(async (values) => {
        try {
            await dispatchLogin(values);
        } catch (err) {
            return formSubmissionExceptionHandler({ form: LOGIN_FORM_NAME, sensitive: true })(err);
        }
    }, []);

    const ForgotPasswordLink = (
        <div className="forgotten-password">
            <RouteLink to="/forgotten-password" keepParams location={location}>
                Forgot your password?
            </RouteLink>
        </div>
    );

    useEffect(() => {
        // Don't automatically focus the form on mobile or on tablet, as the keyboard hides too much
        // and various warning popups can interfere
        if (isPlatformPhoneOrMobileMode(platformSingleton) || isPlatformTablet(platformSingleton)) return;

        // Focuses the email input field when the page loads.
        emailInputRef.current && emailInputRef.current.focus();
    }, []);

    return (
        <FinalForm
            className="auth-form"
            initialValues={initialValues}
            onSubmit={onSubmit}
            fields={LOGIN_FIELDS}
            name={LOGIN_FORM_NAME}
        >
            {(formState) => (
                <>
                    {/* Prevent passwords from auto-filling after an social sign in error */}
                    {oauthError && (
                        <input
                            id="pwd"
                            className="fake-password"
                            tabIndex="-1"
                            aria-hidden="true"
                            type="password"
                            name="fakepasswordremembered"
                        />
                    )}

                    <FinalField field={FIELDS.email}>
                        {({ input, display }) => (
                            <FormRow label="Email address" name={input.name} error={display.error}>
                                <FormInput
                                    {...input}
                                    ref={emailInputRef}
                                    type={FIELDS.email.type}
                                    maxLength={FIELDS.email.maxLength}
                                    error={display.error}
                                />
                            </FormRow>
                        )}
                    </FinalField>

                    <FinalField field={FIELDS.loginPassword}>
                        {({ input, display }) => (
                            <FormRow
                                label="Password"
                                name={input.name}
                                error={display.error}
                                HelperElement={ForgotPasswordLink}
                            >
                                <PasswordInput
                                    {...input}
                                    maxLength={FIELDS.loginPassword.maxLength}
                                    canShowPassword={false}
                                    error={display.error}
                                />
                            </FormRow>
                        )}
                    </FinalField>

                    <StyledButton
                        type="submit"
                        className="row primary"
                        id={`${LOGIN_FORM_NAME}-submit`}
                        disabled={shouldDisableSubmitButton(formState)}
                    >
                        {!formState.submitting ? 'Log in' : <Spinner show />}
                    </StyledButton>

                    <AuthErrorMessage
                        primaryError={formState.submitError || oauthError}
                        hide={formState.modifiedSinceLastSubmit || formState.submitting}
                    />
                </>
            )}
        </FinalForm>
    );
};

LoginForm.propTypes = {
    location: PropTypes.object,
    deviceId: PropTypes.string,
    dispatchLogin: PropTypes.func,
};

export default LoginForm;
