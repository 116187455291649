// Utils
import { asObject } from '../../../common/utils/immutableHelper';
import { isImage } from '../../../common/elements/utils/elementTypeUtils';

// Selectors
import { getAttachments } from '../attachments/attachmentsSelector';
import { getCurrentBoardVisibleDescendants } from './currentBoardSelector';

// Types
import { Attachment } from '../attachments/attachmentTypes';
import { MNElementMap } from '../../../common/elements/elementModelTypes';
import { GenericReduxThunkAction } from '../../../common/actions/actionTypes';

/**
 * Retrieves the current board images via a thunk.
 * If the image is currently being uploaded, the image data will be taken from the attachment instead.
 */
export const getCurrentBoardImagesForCarouselThunk = (): GenericReduxThunkAction => (dispatch, getState) => {
    const state = getState();

    const currentBoardImages = asObject(getCurrentBoardVisibleDescendants(state).filter(isImage)) as MNElementMap;

    const attachments = asObject(getAttachments(state)) as Record<string, Attachment>;

    return Object.fromEntries(
        Object.entries(currentBoardImages).map(([elementId, imageElement]) => {
            const attachment = attachments[elementId];

            // If there's an attachment for the image, use that data instead
            const attachmentImageContent = attachment?.data?.dataUri
                ? {
                      width: attachment.data?.width,
                      height: attachment.data?.height,
                      original: attachment.data?.dataUri,
                  }
                : null;

            return [
                elementId,
                {
                    ...imageElement,
                    content: {
                        ...imageElement.content,
                        image: {
                            ...imageElement.content.image,
                            ...attachmentImageContent,
                        },
                    },
                },
            ];
        }),
    );
};
