// NOTE: This file is based on `amplitudeServiceSwift.js`, created for passing Amplitude events to Capacitor

import { Capacitor } from '@capacitor/core';
import { AmplitudeCapacitorPlugin } from '../../capacitor_plugins/amplitude';

import { CAPACITOR_AMPLITUDE_PLUGIN } from '../../capacitor_plugins/pluginConstants';

const warnAmplitudeNotAvailable = (fn) => (args) => {
    console.warn("Amplitude is attempting to be invoked, but it's not available", fn.name, args);
};

const ifAmplitudeIsAvailable =
    (fn) =>
    (...args) => {
        if (Capacitor.isPluginAvailable(CAPACITOR_AMPLITUDE_PLUGIN)) {
            fn(...args);
        } else {
            warnAmplitudeNotAvailable(fn)(...args);
        }
    };

export const setAmplitudeUser = ifAmplitudeIsAvailable((user) => {
    AmplitudeCapacitorPlugin.setUserId({ userId: user._id });
});

export const clearAmplitudeUder = ifAmplitudeIsAvailable(() => {
    AmplitudeCapacitorPlugin.setUserId({ userId: null });
});

export const sendAmplitudeUserProperties = ifAmplitudeIsAvailable((userProperties) => {
    AmplitudeCapacitorPlugin.setUserProperties({ properties: JSON.stringify(userProperties) });
});

export const sendAmplitudeEvent = ifAmplitudeIsAvailable(({ eventType, eventProperties, userProperties }) => {
    if (eventType) {
        AmplitudeCapacitorPlugin.sendEvent({ type: eventType, event: JSON.stringify(eventProperties) });
    }
});
