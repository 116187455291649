// Util
import { getElementType } from '../../../../common/elements/utils/elementPropertyUtils'; // Utils
import { hasPermission } from '../../../../common/permissions/permissionUtil';
import { getToolbarHash } from './toolbarGeneralUtils';

// Constants
import { ACCESS_BITS } from '../../../../common/permissions/permissionBitsConstants';
import { ALL_ELEMENT_TYPES } from '../../../../common/elements/utils/elementTypeUtils';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { ToolbarItemConfig, ToolbarList, ToolbarListType } from '../toolbarTypes';
import { ElementType } from '../../../../common/elements/elementTypes';
import { ImMNCurrentUser } from '../../../user/currentUserSelector';

const ALL_ELEMENT_TYPES_SET = new Set(ALL_ELEMENT_TYPES);

export const getSelectedElementTypes = (selectedElements: Immutable.List<ImMNElement>): Set<ElementType> => {
    const jsSet = new Set<ElementType>();

    selectedElements.map(getElementType).forEach((elementType) => {
        // ensure the element type is valid
        if (!ALL_ELEMENT_TYPES_SET.has(elementType)) return;

        jsSet.add(elementType);
    });

    return jsSet;
};

/**
 * Returns the intersection of the tools available for all element types.
 */
export const getElementTypeToolsIntersection = (
    elementTypes: Set<ElementType>,
    editorKey: string | undefined,
    toolArrayFn: (elementType: ElementType, editorKey?: string | undefined) => ToolbarItemConfig[],
): ToolbarItemConfig[] => {
    if (!elementTypes.size) return [];

    const elementTypeTools = [...elementTypes].map((elementType) => toolArrayFn(elementType, editorKey));

    const availableToolIntersection = elementTypeTools.reduce(
        (acc, tools) => acc.filter((tool) => tools.some((otherTool) => otherTool.id === tool.id)),
        elementTypeTools[0],
    );

    return availableToolIntersection;
};

/**
 * Returns a list of tools available for the given toolbar type + selected element types.
 * If no tools are available for the given toolbar type, the next highest priority toolbar type is used, until a list of tools is found.
 * @param toolbarTypePriorities - The order of toolbar types to try when looking for tools. If no tools are available for the given toolbar type, the next highest priority toolbar type is used until a list of tools is found.
 * @param baseToolLists - The base tool lists for each toolbar type, depending on the selected element types.
 * @param toolbarType - The toolbar type to get tools for.
 */
const getPriorityOrderedToolsForToolbarType = (
    toolbarTypePriorities: ToolbarListType[],
    baseToolLists: Record<ToolbarListType, ToolbarItemConfig[]>,
    toolbarType: ToolbarListType,
): {
    type: ToolbarListType;
    tools: ToolbarItemConfig[];
} => {
    const baseTools = baseToolLists[toolbarType];

    // if there are tools available for this toolbar type, return them
    if (baseTools.length) {
        return {
            type: toolbarType,
            tools: baseTools,
        };
    }

    const priorityIndex = toolbarTypePriorities.indexOf(toolbarType);

    // no more toolbars to try
    const nextPriorityIndex = priorityIndex - 1;
    if (nextPriorityIndex < 0) {
        return {
            type: toolbarType,
            tools: [],
        };
    }

    const nextPriorityToolbarType = toolbarTypePriorities[nextPriorityIndex];

    return getPriorityOrderedToolsForToolbarType(toolbarTypePriorities, baseToolLists, nextPriorityToolbarType);
};

/**
 * Returns a list of tools available for the given toolbar type + selected element types.
 * This list is filtered based on the current user's settings and their current board permissions.
 */
const getAvailableToolbarTools = (
    toolbarType: ToolbarListType,
    toolbarTypePriorities: ToolbarListType[],
    baseToolLists: Record<ToolbarListType, ToolbarItemConfig[]>,
    selectedElements: Immutable.List<ImMNElement>,
    selectedChildElements: Immutable.List<ImMNElement>,
    currentUser: ImMNCurrentUser,
    currentPermissions: number,
    editorKey: string | undefined,
    isEditingRange: boolean | undefined,
): {
    type: ToolbarListType;
    tools: ToolbarItemConfig[];
} => {
    // eslint-disable-next-line prefer-const
    let { type, tools } = getPriorityOrderedToolsForToolbarType(toolbarTypePriorities, baseToolLists, toolbarType);

    // remove any tools that are only available / not available when multiple elements are selected
    const isMultiSelect = selectedElements.size > 1;
    if (isMultiSelect) {
        tools = tools.filter((tool) => tool.multiSelect);
    }

    // remove any tools that are not available based on the selected elements
    // (eg, all selected elements must be on the canvas, must be > 2 selected elements etc.)
    tools = tools.filter((tool) => {
        const availabilityPredicate = tool.availabilityPredicate;
        if (!availabilityPredicate) return true;

        return availabilityPredicate({
            selectedElements,
            selectedChildElements,
            currentUser,
            editorKey,
            isEditingRange,
            permissions: currentPermissions,
        });
    });

    // ensure the user has the required permissions to use the tool
    tools = tools.filter((tool) => {
        const permissionRequired = tool.permissionPredicate?.(selectedElements) ?? ACCESS_BITS.WRITE;

        return hasPermission(permissionRequired, currentPermissions);
    });

    return { type, tools };
};

/**
 * Gets a UID for the given toolbar item, based on the toolList id and the item id.
 * */
export const getToolbarItemUID = (itemId: string, toolListId: string): string => `${itemId}-${toolListId}`;

/**
 * Creates a toolbar list for the given toolbar type + selected elements.
 */
export const createToolbarList = (
    toolbarType: ToolbarListType,
    toolbarTypePriorities: ToolbarListType[],
    baseToolLists: Record<ToolbarListType, ToolbarItemConfig[]>,
    selectedElements: Immutable.List<ImMNElement>,
    selectedChildElements: Immutable.List<ImMNElement>,
    currentUser: ImMNCurrentUser,
    currentPermissions: number,
    editorKey: string | undefined,
    isEditingRange: boolean | undefined,
): ToolbarList => {
    const { type, tools } = getAvailableToolbarTools(
        toolbarType,
        toolbarTypePriorities,
        baseToolLists,
        selectedElements,
        selectedChildElements,
        currentUser,
        currentPermissions,
        editorKey,
        isEditingRange,
    );

    const hash = getToolbarHash(tools);
    const toolListId = `list-${hash}`;

    return {
        id: toolListId,
        type: type,
        elementTypes: getSelectedElementTypes(selectedElements),
        items: tools.map((item) => ({ ...item, uid: getToolbarItemUID(item.id, toolListId) })),
    };
};

/**
 * Determines the required toolbar type based on the current selection and editor state.
 */
export const getRequiredToolbarListType = (
    defaultToolbarType: ToolbarListType,
    selectedElements: Immutable.List<ImMNElement>,
    editorKey: string | undefined,
    hasEditorSelection: boolean | undefined,
): ToolbarListType => {
    if (hasEditorSelection && editorKey) return ToolbarListType.EDITING_RANGE;
    if (editorKey) return ToolbarListType.EDITING;
    if (selectedElements.size > 0) return ToolbarListType.SELECTED;

    return defaultToolbarType;
};

export const isToolbarListTypeAlwaysAvailable = (type: ToolbarListType): boolean => {
    switch (type) {
        case ToolbarListType.CREATE_ELEMENT:
        case ToolbarListType.MOBILE_TABS:
        case ToolbarListType.MOBILE_UTILS:
        case ToolbarListType.MOBILE_GUEST:
        case ToolbarListType.MOBILE_GUEST_UTILS:
            return true;
        default:
            return false;
    }
};
