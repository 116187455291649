// Lib
import * as Immutable from 'immutable';
import { EditorState } from 'draft-js';

// Utils
import editorGetCurrentBlock from './editorGetCurrentBlock';

export const shouldOverrideCurrentInlineStyle = (editorState) => {
    const selection = editorState.getSelection();

    // When there's a selection use the default behaviour
    if (!selection.isCollapsed()) return false;

    const startOffset = selection.getStartOffset();

    // If not at the start of a block, use the default behaviour
    if (startOffset !== 0) return false;

    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    // If we have a single block, use the default behaviour
    if (!blockMap || blockMap.size <= 1) return false;

    const currentBlock = editorGetCurrentBlock(editorState);

    // If there's text in the current block, use the default behaviour
    if (currentBlock.getLength() > 0) return false;

    // Otherwise use the overridden styles
    return true;
};

/**
 * The Draft JS editor behaviour uses "sticky" inline styles.
 * This means that the current inline style will use the style of the previous character unless it's being overridden.
 *
 * In the case of a new block, this means that it will use the inline style of the last character in the previous block,
 * unless the current block has some text in it.
 * This isn't very desirable, so this function will override that particular case, unless a style override is already
 * in place.
 */
export default (editorState) => {
    if (!editorState) return Immutable.OrderedSet();

    if (!shouldOverrideCurrentInlineStyle(editorState)) {
        // There's a scenario - when indenting/unindenting tasks where a selection can be set that
        // doesn't exist in the editor state. We must avoid using the editorState.getCurrentInlineStyle
        // method in this case, as it will throw an exception
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const startKey = selection?.getStartKey();

        return contentState && startKey && contentState.getBlockForKey(startKey)
            ? editorState.getCurrentInlineStyle()
            : Immutable.OrderedSet();
    }

    // Get the overridden style
    const styleOverride = editorState.getInlineStyleOverride();

    // If there's no overrides, simply clear the styles
    if (!styleOverride) return Immutable.OrderedSet();

    let currentStyle = styleOverride;

    // Get the inline style of the previous character
    const alteredEditorState = EditorState.setInlineStyleOverride(editorState, null);
    const previousLineInlineStyle = alteredEditorState.getCurrentInlineStyle();

    previousLineInlineStyle.forEach((style) => {
        // If they were in the previous line and they're still in the overridden styles then the user does not want this
        // style on the current line, so remove it
        if (styleOverride.has(style)) {
            currentStyle = currentStyle.remove(style);
        }

        // If they were in the previous line but they're not in this override then the user must have tried to toggle
        // them on again, so we should add it to the set.
        if (!styleOverride.has(style)) {
            currentStyle = currentStyle.add(style);
        }
    });

    // If they aren't in the previous line but they are in the overridden styles then the user wants them on, so leave
    // them in
    return currentStyle;
};
