// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import FullScreenModal from './FullScreenModal';
import FullScreenErrorModal from './FullScreenErrorModal';

const RouteModalRoot = ({ children, isError, ...props }) => {
    if (isError) {
        return (
            <FullScreenErrorModal overlay {...props}>
                {children}
            </FullScreenErrorModal>
        );
    }

    return (
        <FullScreenModal overlay {...props}>
            {children}
        </FullScreenModal>
    );
};

RouteModalRoot.propTypes = {
    children: PropTypes.node,

    isError: PropTypes.bool,
};

export default RouteModalRoot;
