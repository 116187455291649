import registerDocument from '../../../common/documents/index';
import presentationalComponent from './Document';
import containerComponent from './DocumentContainer';
import modalComponent from './modal/DocumentModal';
import modalSheetComponent from './modal/MobileDocumentSheet';

// Utils
import { noSelectedElementsInIconViewMode } from '../../workspace/toolbar/config/toolbarToolConfigUtils';
import { isDesktopMode } from '../../platform/utils/platformDetailsUtils';
import platformSingleton from '../../platform/platformSingleton';

// Constants
import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

const onlyInCardView = ({ selectedElements }) => noSelectedElementsInIconViewMode(selectedElements);

export default () =>
    registerDocument({
        containerComponent,
        presentationalComponent,
        modalComponent,
        modalSheetComponent: modalSheetComponent,
        tools: {
            selected: [
                TOOLBAR_TOOLS.COLOR,
                TOOLBAR_TOOLS.DISPLAY_MODE,
                { ...TOOLBAR_TOOLS.CAPTION, availabilityPredicate: onlyInCardView },
                { ...TOOLBAR_TOOLS.REACTION, availabilityPredicate: onlyInCardView },
                ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
            ],
            editing_CAPTION: TOOL_SETS.TEXT_EDITING_CAPTION,
            editing_DOCUMENT_TITLE: [],
            editing: isDesktopMode(platformSingleton) ? [] : TOOL_SETS.TEXT_EDITING_INLINE_AND_BLOCK,
            'editing_DOCUMENT-MODAL-TITLE': [TOOLBAR_TOOLS.META_HIDE_TOOLBAR],
        },
    });
