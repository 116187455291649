import React, { useRef } from 'react';
import { connect, useSelector } from 'react-redux';

import getGridSize from '../../utils/grid/gridSizeSelector';
import elementModalSelector from './elementModalSelector';
import { getModalSheetDisplayElementComponent } from './elementModalDisplayComponentUtils';

import useElementModalElementIdSynchroniser from './hooks/useElementModalElementIdSynchroniser';
import useElementModalElementFetcher from './hooks/useElementModalElementFetcher';
import useElementModalSelectionSynchroniser from './hooks/useElementModalSelectionSynchroniser';

type ElementModalSheetProps = ReturnType<typeof elementModalSelector> & {
    close: (event: any) => void;
    params: {
        elementId: string;
    };
};

const ElementModalSheet: React.FC<ElementModalSheetProps> = (props) => {
    const { element, params, isElementFetched } = props;

    const closeCallbackRef = useRef<(event: MouseEvent) => void | null>(null);
    const elementInstanceModalComponent = useRef(null);

    const gridSize = useSelector(getGridSize);

    useElementModalElementIdSynchroniser(params.elementId); // note: using the elementId from the route params here
    useElementModalSelectionSynchroniser(props);
    useElementModalElementFetcher(props);

    if (!element && !isElementFetched) return null;

    const { DisplayElement } = getModalSheetDisplayElementComponent(element);
    if (!DisplayElement) return null;

    return (
        <DisplayElement
            {...props}
            isModalView
            gridSize={gridSize}
            closeCallbackRef={closeCallbackRef}
            setOutsideModalChildren={() => {} /* do nothing, sheet doesn't support this */}
            elementInstanceModalRef={elementInstanceModalComponent}
        />
    );
};

export default connect(elementModalSelector)(ElementModalSheet);
