// Lib
import React, { useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getModalDisplayElementComponent } from './elementModalDisplayComponentUtils';

// Selectors
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getElementType } from '../../../common/elements/utils/elementPropertyUtils';
import { getMilanoteApplicationModeSelector } from '../../platform/platformSelector';
import elementModalSelector from './elementModalSelector';

// Components
import RouteModalRoot from '../../components/modal/RouteModalRoot';
import ModalCloseButton from '../../components/modal/ModalCloseButton';

// Hooks
import useElementModalElementFetcher from './hooks/useElementModalElementFetcher';
import useElementModalElementIdSynchroniser from './hooks/useElementModalElementIdSynchroniser';
import useElementModalSelectionSynchroniser from './hooks/useElementModalSelectionSynchroniser';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

// Types
import { ImMNElement } from '../../../common/elements/elementModelTypes';

import './ElementModal.scss';

export type ElementModalProps = ReturnType<typeof elementModalSelector> & {
    element: ImMNElement;
    close: (event: any) => void;
    params: {
        elementId: string;
    };
};

const ElementModal: React.FC<ElementModalProps> = (props) => {
    const { params, element, isElementFetched, close } = props;
    const closeCallbackRef = useRef<(event: MouseEvent) => void | null>(null);

    const elementInstanceModalComponent = useRef(null);
    const [outsideModalChildren, setOutsideModalChildren] = useState(null);

    const gridSize = useSelector(getGridSize);
    const appMode = useSelector(getMilanoteApplicationModeSelector);

    useElementModalElementIdSynchroniser(params.elementId); // note: using the elementId from the route params here
    useElementModalSelectionSynchroniser(props);
    useElementModalElementFetcher(props);

    const handleClose = (event: MouseEvent) => {
        closeCallbackRef.current && closeCallbackRef.current(event);
        close(event);
    };

    if (!element && !isElementFetched) return null;

    const elementType = getElementType(element);

    const alignTop = elementType === ElementType.CARD_TYPE || elementType === ElementType.DOCUMENT_TYPE;
    const { DisplayElement, isError = false } = getModalDisplayElementComponent(element);

    return (
        <RouteModalRoot
            {...props}
            className={classNames('ElementModal', elementType && elementType.toLowerCase())}
            outsideModalChildren={outsideModalChildren}
            close={handleClose}
            isError={isError}
            padding={false}
            styled={false}
            alignTop={alignTop}
            autoWidth
            blur
            hideClose
            noTransition
        >
            {appMode === MilanoteApplicationMode.mobileLegacy && <ModalCloseButton close={handleClose} />}
            <div className="element-modal-contents">
                <DisplayElement
                    {...props}
                    isModalView
                    gridSize={gridSize}
                    closeCallbackRef={closeCallbackRef}
                    setOutsideModalChildren={setOutsideModalChildren}
                    elementInstanceModalRef={elementInstanceModalComponent}
                />
            </div>
        </RouteModalRoot>
    );
};

export default connect(elementModalSelector)(ElementModal);
