import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { constant, identity, negate } from 'lodash';
import { withState } from '../../../node_module_clones/recompose';
import { createStructuredSelector } from 'reselect';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import { getGridSize } from '../../utils/grid/gridSizeSelector';
import { getData } from '../attachments/attachmentsSelector';
import { analyticsEvent } from '../../analytics';
import { getElementId, getImageProp } from '../../../common/elements/utils/elementPropertyUtils';
import { getHasImageToShow, getImageDefaultMaxWidth } from './imageElementUtils';

// Actions
import { acceptElementAttachmentUndo, uploadAttachment } from '../attachments/attachmentActions';
import { navigateToElement } from '../../reducers/navigationActions';
import { switchConnectedElementParents } from '../connections/elementConnectionsActions';
import { getCurrentBoardImagesForCarouselThunk } from '../selectors/currentBoardImagesSelector';

// Components
import Image from './Image';
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import elementSaveAspectRatioResizeDecorator from '../resizing/elementSaveAspectRatioResizeDecorator';
import elementSaveMediaResizeDecorator from '../resizing/elementSaveMediaResizeDecorator';
import imageDoubleClickResizeDecorator from './imageDoubleClickResizeDecorator';
import ImageColorPaletteObserver from '../../components/images/imageColorExtraction/ImageColorPaletteObserver';

// Constants
import { BoardSections, RootBoardTypes } from '../../../common/boards/boardConstants';
import { IMAGE_MIN_WIDTH_GRID } from '../../../common/images/imageConstants';

const mapStateToProps = createStructuredSelector({
    gridSize: getGridSize,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUploadImage: (props) => dispatch(uploadAttachment(props)),
    dispatchAcceptElementAttachmentUndo: (props) => dispatch(acceptElementAttachmentUndo(props)),
    dispatchNavigateToImage: (elementId) => dispatch(navigateToElement({ elementId })),
    dispatchSwitchConnectedElementParents: (args) => dispatch(switchConnectedElementParents(args)),
    dispatchGetCurrentBoardImagesForCarousel: () => dispatch(getCurrentBoardImagesForCarouselThunk()),
});

const shouldMaintainAspectRatio = ({ element, allowAnyAspectRatio, attachment }) =>
    !allowAnyAspectRatio && (getImageProp(element) || getData(attachment));

@connect(mapStateToProps, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys', 'canDrop'])
@captionContainerDecorator
@withState('allowAnyAspectRatio', 'setAllowAnyAspectRatio', false)
@elementResizeDecorator({
    getMinWidth: constant(IMAGE_MIN_WIDTH_GRID),
    getMinHeight: constant(IMAGE_MIN_WIDTH_GRID),
    enableHeight: true,
})
@elementSaveAspectRatioResizeDecorator({
    getIsEnabled: shouldMaintainAspectRatio,
    getMinWidth: constant(IMAGE_MIN_WIDTH_GRID),
    getDefaultMaxWidth: getImageDefaultMaxWidth,
})
@elementSaveMediaResizeDecorator({
    getIsEnabled: negate(shouldMaintainAspectRatio),
    getMinHeight: constant(IMAGE_MIN_WIDTH_GRID),
    getMinWidth: constant(IMAGE_MIN_WIDTH_GRID),
    getDefaultMaxWidth: getImageDefaultMaxWidth,
})
@imageDoubleClickResizeDecorator
class ImageContainer extends React.Component {
    setElementSize = (size) => {
        const { setElementSize } = this.props;
        analyticsEvent('resized-image');
        return setElementSize(size);
    };

    handleKey = (event) => {
        if (!this.props.isSelected) return;
        this.props.captionKeyHandler(event, this.props);
    };

    uploadImage = (file, transactionId) => {
        const { dispatchUploadImage, element } = this.props;
        return dispatchUploadImage({
            id: getElementId(element),
            file,
            transactionId,
        });
    };

    render() {
        const {
            connectLineEdgeDropTarget,
            canConnectLineEdge,
            boardSection,
            dispatchGetCurrentBoardImagesForCarousel,
        } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        // Don't allow long hover image replacement if the image is in the quick notes or inbox, unless it's a
        // placeholder image
        const allowHoverReplaceImage =
            !getHasImageToShow(this.props) ||
            (boardSection !== BoardSections.INBOX && boardSection !== RootBoardTypes.QUICK_NOTES);

        return lineEdgeConnector(
            <div>
                <ImageColorPaletteObserver element={this.props.element} />
                <Image
                    {...this.props}
                    dispatchGetCurrentBoardImagesForCarousel={dispatchGetCurrentBoardImagesForCarousel}
                    allowHoverReplaceImage={allowHoverReplaceImage}
                    uploadFile={this.uploadImage}
                    handleKey={this.handleKey}
                    setElementSize={this.setElementSize}
                />
            </div>,
        );
    }
}

ImageContainer.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    attachment: PropTypes.object,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    isSelected: PropTypes.bool,
    captionKeyHandler: PropTypes.func,
    dispatchCleanElementAttachment: PropTypes.func,
    dispatchAcceptElementAttachmentUndo: PropTypes.func,
    dispatchNavigateToImage: PropTypes.func,
    dispatchSwitchConnectedElementParents: PropTypes.func,
    dispatchUpdateElement: PropTypes.func.isRequired,
    dispatchUploadImage: PropTypes.func,
    dispatchGetCurrentBoardImagesForCarousel: PropTypes.func,
    inList: PropTypes.string,
    setElementSize: PropTypes.func,
    boardSection: PropTypes.string,
    canConnectLineEdge: PropTypes.bool,
};

export default ImageContainer;
