export enum SheetId {
    // Containers
    Inbox = 'Inbox',
    Document = 'Document',
    Notifications = 'Notifications',
    Sharing = 'Sharing',

    // Menu
    CanvasContextMenu = 'CanvasContextMenu',
    QuickAddMenu = 'QuickAddMenu',
    AccountSettings = 'AccountSettings',
    BoardEditorPermission = 'BoardEditorPermission',

    // Tools
    ContextMenuTool = 'ContextMenuTool',
    TextStyleRichTextTool = 'TextStyleRichTextTool',
    ColorTool = 'ColorTool',
    IconTool = 'IconTool',
    ReactionTool = 'ReactionTool',
    ElementReactionTool = 'ElementReactionTool',
    DueDateTool = 'DueDateTool',
    SearchTool = 'SearchTool',
    ColorDisplayTool = 'ColorDisplayTool',
    LineWeightTool = 'LineWeightTool',

    // Popups
    BillingAlertPopup = 'BillingAlertPopup',
    Auth = 'Auth',
    SegmentPicker = 'SegmentPicker',

    // Modals
    RouteModal = 'RouteModal',
    Error = 'Error',
    ReportContent = 'ReportContent',
}

// Sheets on the "..." menu, which reset the toolbar when opened
export const MORE_TOOLS_SHEETS = [SheetId.AccountSettings, SheetId.SearchTool, SheetId.Notifications];

export type SheetConfig = {
    scrollToElementOnOpen?: boolean;
    containsElementEditor?: boolean;
    stayActiveOnLocationChange?: boolean;
};

interface BaseSheetState {
    active: true;
    sheetId: SheetId;
    activeSnapPoint: number;
    config?: SheetConfig;
}

export interface ErrorSheetState extends BaseSheetState {
    sheetId: SheetId.Error;
    activeErrorNoticeId: string;
    data: object | undefined;
}

export type SheetState = BaseSheetState | ErrorSheetState;

export type SheetReducerState = {
    [sheetKey in string]?: SheetState;
};
