// Lib
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import { partition } from 'lodash';

// Utils
import { isAlias } from '../../../common/elements/utils/elementTypeUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import { isAsyncEntityCached } from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Services
import * as boardRefreshService from './boardRefreshService';
import workspaceInitialisationMonitoringSingleton from '../../workspace/initialisation/workspaceInitialisationMonitoringSingleton';

// Selectors
import { isGuestSelector } from '../../user/currentUserSelector';
import { getAsyncResourceEntityState } from '../../utils/services/http/asyncResource/asyncResourceSelector';
import { getIsClientPersistenceEnabledForCurrentUser } from '../feature/elementFeatureSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Types
import { LegacyHybridUseCase, MilanoteApplicationMode, PlatformDetails } from '../../../common/platform/platformTypes';
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';
import { GenericReduxThunkAction } from '../../../common/actions/actionTypes';
import { FetchBoardsArgs } from './boardService';
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { selectIsSocketConnected, selectSocketConnectionStatus } from '../../utils/socket/socketConnectionSelector';
import usePrevious from '../../utils/react/usePrevious';
import { SocketConnectionStatus } from '../../utils/socket/socketConstants';

const createDebouncedFetchIdsFunc = (fetchFunc: (args: FetchBoardsArgs) => GenericReduxThunkAction) => {
    let queriedFetchIds: string[] = [];
    let boardFetchAncestors = false;
    let fetchBoardTimeout: ReturnType<typeof setTimeout>;

    return (boardId: string, loadAncestors: boolean, canvasOrder: boolean): GenericReduxThunkAction =>
        (dispatch, getState) => {
            queriedFetchIds.push(boardId);
            boardFetchAncestors = boardFetchAncestors || loadAncestors;

            clearTimeout(fetchBoardTimeout);

            fetchBoardTimeout = setTimeout(() => {
                const state = getState();

                const isClientPersistenceEnabled = getIsClientPersistenceEnabledForCurrentUser(state);

                // Preload the board if we haven't fetched it yet or if it's already preloaded.
                // This is to ensure that we don't preload the board if it's already fetched.
                // This might occur if the board was fetched in a previous session / before a rehydration.
                const [cachedBoardIds, fetchBoardIds] = isClientPersistenceEnabled
                    ? // If the entity is cached or hasn't been fetched, retrieve via the cache
                      partition(queriedFetchIds, (id) => {
                          const boardResource = getAsyncResourceEntityState(state, ResourceTypes.boards, id);
                          if (!boardResource) return true;
                          return isAsyncEntityCached(boardResource);
                      })
                    : // Fetch all if client persistence is disabled
                      [[], queriedFetchIds];

                if (!isEmpty(cachedBoardIds)) {
                    dispatch(
                        fetchFunc({
                            cache: true,
                            boardIds: cachedBoardIds,
                            loadAncestors: boardFetchAncestors,
                            excludeSelf: true,
                            canvasOrder,
                        }),
                    );
                }

                if (!isEmpty(fetchBoardIds)) {
                    dispatch(
                        fetchFunc({
                            cache: false,
                            boardIds: fetchBoardIds,
                            loadAncestors: boardFetchAncestors,
                            excludeSelf: true,
                            canvasOrder,
                        }),
                    );
                }

                queriedFetchIds = [];
                boardFetchAncestors = false;
            }, 250);
        };
};

const debouncedFetchBoard = createDebouncedFetchIdsFunc(boardRefreshService.refreshBoardsIfDisconnectedAndModified);

const getIntersectionObserverRoot = (platformDetails: PlatformDetails) => {
    const useBrowserViewport = platformDetails.legacyHybridUseCase === LegacyHybridUseCase.ANDROID_BOARD_LIST;

    // When null, it will default to the browser viewport, however the threshold & rootMargin won't work
    // This isn't important for the legacy apps
    if (useBrowserViewport) return null;

    // For the new mobile apps we'll use the canvas viewport, or MobilePageBody if we're on a listing page
    if (platformDetails.appMode === MilanoteApplicationMode.mobile) {
        return document.getElementById('canvas-viewport') || document.querySelector('.MobilePageBody');
    }

    return document.querySelector('.App');
};

const getIntersectionObserverConfig = (platformDetails: PlatformDetails): IntersectionObserverInit => {
    const config: IntersectionObserverInit = {
        root: getIntersectionObserverRoot(platformDetails),
        threshold: 0.25,
    };

    if (platformDetails.appMode === MilanoteApplicationMode.mobile) {
        config.rootMargin = '50% 100% 50% 100%';
    }

    return config;
};

interface BoardChildrenLoadObserverProps {
    element: ImMNElement;
    documentMode: boolean;
}

const BoardChildrenLoadObserver: React.FC<BoardChildrenLoadObserverProps> = (props) => {
    const { children, element, documentMode } = props;

    const observeDiv = useRef<HTMLDivElement>(null);
    const observer = useRef<IntersectionObserver | null>(null);

    const platformDetails = useSelector(getPlatformDetailsSelector);
    const isSocketConnected = useSelector(selectIsSocketConnected);
    const socketConnectionStatus = useSelector(selectSocketConnectionStatus);
    const prevSocketConnectionStatus = usePrevious(socketConnectionStatus);
    const isGuest = useSelector(isGuestSelector);

    const dispatch = useDispatch();
    const dispatchFetchBoard = useCallback(
        (boardId: string, loadAncestors: boolean, canvasOrder: boolean) =>
            dispatch(debouncedFetchBoard(boardId, loadAncestors, canvasOrder)),
        [],
    );

    const removeBoardObserver = useCallback(() => {
        observeDiv.current && observer.current?.unobserve(observeDiv.current);
        observer.current = null;
    }, []);

    const intersectionCallback = useCallback(
        async ([intersection]) => {
            const { isIntersecting } = intersection;

            // If the board isn't visible yet, don't worry about fetching it
            if (!isIntersecting) return;

            // If the board is visible on the canvas and it's a fresh page load, we want
            // to make sure we track when the board counts are retrieved
            workspaceInitialisationMonitoringSingleton.registerBoardCountsWaiting();

            // If we have the div with the intersection observer, stop observing as we don't need to action it again
            removeBoardObserver();

            dispatchFetchBoard(getPhysicalId(element), isAlias(element), documentMode);
        },
        [documentMode],
    );

    const createBoardObserver = useCallback(() => {
        // Another observer is already observing
        if (observer.current) return;

        // if browser doesn't support IntersectionObserver, just preload the board children
        // NOTE: Unlikely that any of our userbase will fall into this category - it's been supported for 5 years
        if (!window.IntersectionObserver) return intersectionCallback([{ isIntersecting: true }]);

        observer.current = new IntersectionObserver(
            intersectionCallback,
            getIntersectionObserverConfig(platformDetails),
        );
        observeDiv.current && observer.current.observe(observeDiv.current);
    }, [intersectionCallback, platformDetails]);

    // On mount observe, on unmount remove observer
    useEffect(() => {
        // Guest users won't have a socket connected, but we still want to fetch the board on initial load
        if (!isSocketConnected && !isGuest) return;

        // Socket has just connected, but from a state other than disconnected.
        //  - This must have been an "interrupted" status, which is a temporary socket disconnection, thus
        //   we don't want to re-fetch all children boards on a minor outage
        if (prevSocketConnectionStatus && prevSocketConnectionStatus !== SocketConnectionStatus.DISCONNECTED) return;

        createBoardObserver();

        return removeBoardObserver;
    }, [isSocketConnected]);

    return <div ref={observeDiv}>{children}</div>;
};

export default BoardChildrenLoadObserver;
