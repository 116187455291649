// Lib
import { combineReducers } from 'redux-immutable';

// Utils
import platformSingleton from '../platform/platformSingleton';
import {
    isPlatformElectronMac,
    isPlatformLegacyMobileApp,
    isPlatformModernMobileApp,
} from '../platform/utils/platformDetailsUtils';
import { isCurrentBrowserLogoutAction } from '../auth/authUtils';

// Client only state
import currentlyEditing from '../element/selection/currentlyEditingReducer';
import selection from '../element/selection/selectionReducer';
import gridSize from '../utils/grid/gridReducer';
import theme from '../user/account/accountModal/interface/themeSettings/themeReducer';
import currentUser from '../user/currentUserReducer';
import clientData from '../user/clientDataReducer';
import dragging from './draggingReducer';
import annotation from '../element/drawing/annotation/store/annotationReducer';
import duplications from '../element/duplicate/elementDuplicateReducer';
import cloneInstances from '../element/clone/cloneInstancesReducer';
import resizing from '../element/resizing/store/resizingReducer';
import collision from '../components/collision/collisionReducer';
import popup from '../components/popupPanel/popupReducer';
import modal from '../components/modal/modalReducer';
import sheet from '../mobile/structural/sheet/sheetReducer';
import socketConnection from '../utils/socket/socketReducer';
import appVisibility from '../offline/appVisibility/appVisibilityReducer';
import currentBoardId from './currentBoardId/currentBoardIdReducer';
import dragModifierKeys from '../utils/dnd/modifierKeys/dragModifierKeysReducer';
import richMedia from '../element/richMedia/richMediaReducer';
import boardSummaries from '../element/board/summary/boardSummariesReducer';
import boardHierarchies from '../element/board/hierarchy/boardHierarchyReducer';
import onboarding from '../workspace/onboarding/onboardingReducer';
import tooltip from '../components/tooltips/tooltipReducer';
import attachments from '../element/attachments/attachmentsReducer';
import cms from '../cms/cmsReducer';
import currentFocus from './focusReducer';
import commentsInput from '../element/comment/store/commentsInputReducer';
import clipper from '../element/card/clipper/store/clipperReducer';
import userContacts from '../user/contacts/userContactsReducer';
import initialised from '../app/initialisation/initialisationReducer';
import elementFilter from '../element/elementFilter/elementFilterReducer';
import canvas from '../canvas/store/canvasReducer';
import mediaPlayer from '../element/file/mediaPlayer/mediaPlayerReducer';
import interfaceSettings from '../user/account/accountModal/interface/interfaceSettingsReducer';
import preview from '../workspace/preview/previewReducer';
import boardPreview from '../workspace/boardPreview/boardPreviewReducer';
import session from './sessionReducer';
import presentation from '../workspace/presentation/presentationReducer';
import serverReconnection from '../offline/appServerConnection/appServerReconnectionReducer';
import navigationHistory from '../user/navigationHistory/navigationHistoryReducer';

// Constants
import {
    USER_CHECKED_NOTIFICATIONS,
    USER_CHECKED_QUICK_NOTES,
    USER_ENABLE_EXPERIMENT,
    USER_UPDATE,
} from '../../common/users/userConstants';
import { LOGOUT } from '../auth/authConstants';
import {
    ELEMENT_DUPLICATION_COMPLETE,
    ELEMENT_DUPLICATION_PROCESSING,
} from '../element/duplicate/elementDuplicateConstants';

const reducersMap = {
    initialised,
    currentBoardId,
    currentlyEditing,
    currentFocus,
    clipper,
    dragModifierKeys,
    selection,
    annotation,
    duplications,
    cloneInstances,
    gridSize,
    theme,
    currentUser,
    clientData,
    dragging,
    resizing,
    collision,
    socketConnection,
    appVisibility,
    popup,
    modal,
    sheet,
    richMedia,
    presentation,
    boardSummaries,
    boardHierarchies,
    onboarding,
    tooltip,
    cms,
    attachments,
    commentsInput,
    userContacts,
    elementFilter,
    canvas,
    mediaPlayer,
    interfaceSettings,
    boardPreview,
    preview,
    session,
    serverReconnection,
    navigationHistory,
};

if (isPlatformModernMobileApp(platformSingleton)) {
    reducersMap.capacitorApp = require('../capacitor/store/capacitorLifecycleReducer').default;
}

// Hybrid app reducer is used for both the Electron and Swift apps
if (isPlatformElectronMac(platformSingleton) || isPlatformLegacyMobileApp(platformSingleton)) {
    reducersMap.hybridApp = require('../hybridApp/store/hybridAppReducer').default;
} else {
    // The version reducer is only used to keep track of the web app version
    // This is used in the PWA because it's open for longer periods
    reducersMap.version = require('./version/versionReducer').default;
    // Similarly, the PWA reducer is only required in the web version
    reducersMap.pwaApp = require('../pwaApp/store/pwaAppReducer').default;
}

// only include the electron-specific reducer when running in electron context
if (isPlatformElectronMac(platformSingleton)) {
    reducersMap.electron = require('./electronReducer').default;
}

const appReducer = combineReducers(reducersMap);

const clientAppReducer = (state, action) => {
    // If there's a logout from the same browser, reset the state, otherwise ignore it
    if (action.type === LOGOUT) {
        return isCurrentBrowserLogoutAction(action) ? appReducer(undefined, action) : state;
    }

    // This could happen if the current user updates their profile image
    const isUpdateOfCurrentUser =
        (action.type === USER_UPDATE ||
            action.type === USER_ENABLE_EXPERIMENT ||
            action.type === USER_CHECKED_NOTIFICATIONS ||
            action.type === USER_CHECKED_QUICK_NOTES) &&
        action.userId === state.getIn(['currentUser', '_id']);

    const isDuplicationAction =
        action.type === ELEMENT_DUPLICATION_PROCESSING || action.type === ELEMENT_DUPLICATION_COMPLETE;

    if (action.remote && !isUpdateOfCurrentUser && !isDuplicationAction) {
        return state;
    }
    return appReducer(state, action);
};

export default clientAppReducer;
