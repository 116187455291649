// Lib
import { assign, difference, keys, size, take, union } from 'lodash';

// Utils
import http from '../../utils/services/http';
import { prop } from '../../../common/utils/immutableHelper';
import { asyncResource } from '../../utils/services/http/asyncResource/asyncResource';
import { isBoardLike } from '../../../common/elements/utils/elementTypeUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import { getDataExpiryTimestamp } from '../../utils/services/http/axiosResponseUtils';
import { getChildIds } from '../../../common/dataStructures/graphUtils';

// Actions
import { loadElements } from '../actions/elementActions';
import { loadElementsIntoWorkerCache } from '../../../common/elements/elementActions';
import { boardSummariesLoadElements } from './summary/boardSummariesActions';
import { boardHierarchiesLoadElements } from './hierarchy/boardHierarchiesActions';
import { commentsLoad } from '../comment/store/commentActions';
import { boardCanvasOrderLoad } from '../../../common/boards/boardActions';
import { getTokenElementIdGroupsThunk } from '../../utils/permissions/permissionsActions';
import { markAsyncResourceAsStale } from '../../utils/services/http/asyncResource/asyncResourceActions';

// Selectors
import { getElements } from '../selectors/elementSelector';
import { breadcrumbSelector } from '../../workspace/breadcrumbs/breadcrumbSelector';
import { boardVisibleElementGraphSelector } from '../selectors/elementGraphSelector';
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getClosestPermissionIdForElementIdSelector } from '../../utils/permissions/permissionsSelector';
import { getDuplicateLoading } from '../duplicate/elementDuplicateSelector';
import {
    getIsAsyncResourceEntityFetched,
    getIsAsyncResourceEntityFetching,
} from '../../utils/services/http/asyncResource/asyncResourceSelector';
import { getIsClientPersistenceEnabledForCurrentUser } from '../feature/elementFeatureSelector';

// Constants
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';

// Types
import { IdGraph } from '../../../common/dataStructures/graphTypes';
import { MNElementMap } from '../../../common/elements/elementModelTypes';
import { AsyncResourceResponseMetadata } from '../../utils/services/http/asyncResource/asyncResourceTypes';
import { MNComment } from '../../../common/comments/commentTypes';

export type FetchBoardsArgs = {
    boardIds: string[];
    force?: boolean;
    cache?: boolean;
    loadAncestors?: boolean;
    excludeSelf?: boolean;
    canvasOrder?: boolean;
    addLoadedBoardSummaries?: boolean;
    permissionIdsOverride?: string[];
};

type FetchBoardsResult = {
    boardIds: string[];
    childrenReturned: MNElementMap;
    comments: { [commentId: string]: MNComment };
    elementCount: number;
    elements: MNElementMap;
    errors: { [id: string]: object };
    fetchedTime: number;
};

/**
 * Fetches multiple boards in one go, based on the boardIds passed through in the argument object.
 * But only fetches them if they aren't already being fetched.
 */
export const fetchBoards =
    (args: FetchBoardsArgs) =>
    async (dispatch: Function, getState: Function): Promise<FetchBoardsResult | undefined> => {
        const {
            cache = false,
            boardIds,
            force,
            loadAncestors,
            excludeSelf,
            canvasOrder,
            addLoadedBoardSummaries = true,
            permissionIdsOverride = undefined,
        } = args;

        const state = getState();

        /* Don't fetch duplicates that are in a loading state unless they're forced, because:
         * - The BoardChildrenLoadObserver will try to fetch the board before it's been duplicated
         *    on the server.
         *    - This would result in the board being "fetched" without any children
         * - The elementDuplicateActions will force fetch the board once its finished duplicating
         *    - It will then correctly load its children
         */
        const boardIdsWithoutLoadingDuplicates = boardIds
            .filter((boardId) => !!boardId)
            .filter((boardId) => {
                const isLoading = getDuplicateLoading(state, { elementId: boardId }) === true;
                return force || !isLoading;
            });

        // Limit to a max of 50 boards in every fetch to reduce the possibility of the server crashing
        const requestedBoardIds = take(boardIdsWithoutLoadingDuplicates, 50);

        const isClientPersistenceEnabled = getIsClientPersistenceEnabledForCurrentUser(state);
        const shouldCache = cache && isClientPersistenceEnabled;

        return dispatch(
            asyncResource(
                ResourceTypes.boards,
                requestedBoardIds,
                force,
                shouldCache,
            )(async (boardIdsToFetch) => {
                if (!boardIdsToFetch?.length) return;

                const shouldExcludeSelf = boardIdsToFetch.reduce(
                    (exclude, boardId) =>
                        exclude &&
                        // Intentionally using the elements resource here rather than boards
                        (getIsAsyncResourceEntityFetching(state, ResourceTypes.elements, boardId) ||
                            getIsAsyncResourceEntityFetched(state, ResourceTypes.elements, boardId)),
                    excludeSelf,
                );

                const boardVisibleElementsGraph = boardVisibleElementGraphSelector(state) as IdGraph;

                // Get the current children of the board to compare after the fetch and find out what's been deleted
                const initialChildIdsByParentIds = boardIdsToFetch.reduce((map, boardId) => {
                    map[boardId] = getChildIds(boardVisibleElementsGraph, boardId);
                    return map;
                }, {} as IdGraph);

                const tokenGroups: { token: string; elementIds: string[] }[] = await dispatch(
                    getTokenElementIdGroupsThunk({ elementIds: boardIdsToFetch, permissionIdsOverride }),
                );

                const responses = await Promise.all(
                    tokenGroups.map(async ({ token, elementIds }) =>
                        http({
                            url: `boards`,
                            timeout: 60000,
                            retry: 1,
                            params: {
                                excludeSelf: shouldExcludeSelf,
                                loadAncestors,
                                tokens: token,
                                canvasOrder,
                                ids: elementIds.join(','),
                            },
                        }).catch((err) => {
                            // This is used in case the errors are caused by the fetch next boards
                            // timing out, we don't want to store those errors
                            dispatch(markAsyncResourceAsStale(ResourceTypes.boards, boardIdsToFetch));
                            const alreadyHandledIds = [...boardIdsToFetch];

                            err.asyncResource = {
                                alreadyHandledIds,
                            };

                            throw err;
                        }),
                    ),
                );

                const returnObj = {
                    boardIds: [],
                    childrenReturned: {} as MNElementMap,
                    comments: {},
                    elementCount: 0,
                    elements: {} as MNElementMap,
                    errors: {},
                    asyncResource: {} as AsyncResourceResponseMetadata,
                    canvasOrder: {},
                };

                responses.forEach((response) => {
                    const { data = {} } = response;

                    returnObj.boardIds = union(returnObj.boardIds, data.boardIds || []);
                    returnObj.childrenReturned = assign(returnObj.childrenReturned, data.childrenReturned || {});
                    returnObj.comments = assign(returnObj.comments, data.comments || {});
                    returnObj.elementCount += data.elementCount || 0;
                    returnObj.elements = assign(returnObj.elements, data.elements || {});
                    returnObj.errors = assign(returnObj.errors, data.errors || {});
                    returnObj.canvasOrder = assign(returnObj.canvasOrder, data.canvasOrder || {});

                    const responseExpiry = getDataExpiryTimestamp(response);

                    const shouldUpdateExpiry =
                        responseExpiry &&
                        (!returnObj.asyncResource.expiry || responseExpiry < returnObj.asyncResource.expiry);

                    if (shouldUpdateExpiry) {
                        returnObj.asyncResource.expiry = responseExpiry;
                    }

                    returnObj.asyncResource.fetchedTime = data.fetchedTime;
                    returnObj.asyncResource.errors = assign(returnObj.errors, data.errors || {});
                });

                if (!size(returnObj.elements)) return returnObj;

                // Figure out the difference between the fetched elements and previous elements
                // The remaining will need to be deleted
                const retrievedChildIds = keys(returnObj.elements);
                const deletedElementIds = boardIdsToFetch.reduce((deletedIdList, boardId) => {
                    if (!returnObj.childrenReturned[boardId]) return deletedIdList;
                    const initialChildIds = initialChildIdsByParentIds[boardId];
                    const deletedIds = difference(initialChildIds, retrievedChildIds);
                    return deletedIdList.concat(deletedIds);
                }, [] as string[]);

                shouldCache
                    ? dispatch(loadElementsIntoWorkerCache(returnObj.elements, deletedElementIds))
                    : dispatch(loadElements(returnObj.elements, deletedElementIds));

                if (size(returnObj.comments)) dispatch(commentsLoad({ comments: returnObj.comments }));

                if (size(returnObj.canvasOrder)) {
                    dispatch(boardCanvasOrderLoad({ canvasOrder: returnObj.canvasOrder }));
                }

                if (addLoadedBoardSummaries) {
                    dispatch(boardSummariesLoadElements(returnObj.elements));
                    dispatch(boardHierarchiesLoadElements(returnObj.elements));
                }
                return returnObj;
            }),
        );
    };

export type FetchBoardArgs = {
    boardId: string;
    force?: boolean;
    loadAncestors?: boolean;
    excludeSelf?: boolean;
    addLoadedBoardSummaries?: boolean;
    canvasOrder?: boolean;
};

/**
 * Fetches the board and its children from the server if required.
 * If the board has already been fetched from the server then it will not be re-fetched, unless it is 'forced' to.
 */
export const fetchBoard = (args: FetchBoardArgs) => fetchBoards({ ...args, boardIds: [args.boardId] });

/**
 * Fetch the visible boards on the specified board,
 * This is used during element duplication and force fetch element middleware.
 */
export const fetchVisibleDescendantBoards = (boardId: string) => (dispatch: Function, getState: Function) => {
    const state = getState();
    const elementsMap = getElements(state);

    const visibleDescendantsMap = boardVisibleElementGraphSelector(state) as IdGraph;
    const boardVisibleDescendants = visibleDescendantsMap[boardId] || [];
    const ancestorAndChildrenIds = boardVisibleDescendants.concat(
        // @ts-ignore Unable to get types from JS file
        breadcrumbSelector(state, { currentBoardId: boardId }).path,
    );

    // Fetch any ancestor or child which:
    // - doesn't exist, or
    // - is a board or alias and hasn't been fetched yet
    const nextBoardsToFetch = ancestorAndChildrenIds.reduce((boardIdsToFetch, elementId) => {
        const el = prop(elementId, elementsMap);

        if (!el || isBoardLike(el)) {
            const actualBoardId = !el ? elementId : getPhysicalId(el);
            boardIdsToFetch.push(actualBoardId);
        }

        return boardIdsToFetch;
    }, [] as string[]);

    return dispatch(fetchBoards({ boardIds: nextBoardsToFetch, excludeSelf: true, loadAncestors: false }));
};

/**
 * Fetches the important information about a board when it's password protected, so
 * that it's details can be shown in the header while the user is presented with the
 * password entry form.
 */
const prefetchPasswordProtectedBoard =
    (boardId: string, permissionId: string) =>
    async (dispatch: Function): Promise<void> => {
        const response = await http({ url: `boards/${boardId}/password-pre-fetch`, params: { p: permissionId } });
        return dispatch(loadElements(response.data.elements));
    };

export const prefetchPasswordProtectedCurrentBoard =
    () =>
    (dispatch: Function, getState: Function): Promise<void> => {
        const state = getState();

        const currentBoardId = getCurrentBoardIdFromState(state);
        // @ts-ignore I think it's struggling to infer types in the JS file
        const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId: currentBoardId });

        return dispatch(prefetchPasswordProtectedBoard(currentBoardId, permissionId));
    };
