// Types
import {
    ApplicationType,
    DeviceFormFactor,
    LegacyHybridUseCase,
    MilanoteApplicationMode,
    PlatformDetails,
    PlatformType,
} from '../../../common/platform/platformTypes';

/**********************************************************
 * Application mode
 **********************************************************/

export const isMobileMode = (platformDetails: PlatformDetails) =>
    platformDetails.appMode === MilanoteApplicationMode.mobile;

export const isMobileLegacyMode = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appMode === MilanoteApplicationMode.mobileLegacy;

export const isDesktopMode = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appMode === MilanoteApplicationMode.desktop;

/**********************************************************
 * Application type
 **********************************************************/

export const isPlatformIframe = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appType === ApplicationType.iframe;

export const isPlatformElectronMac = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appType === ApplicationType.electronMac;

export const isPlatformDesktopPwa = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appType === ApplicationType.pwa;

/**
 * Determines if the platform is a native app (e.g. Old iOS / Android app, or the new Capacitor app).
 */
export const isPlatformNativeMobileApp = (platformDetails: PlatformDetails): boolean => {
    switch (platformDetails.appType) {
        case ApplicationType.iphoneCapacitor:
        case ApplicationType.iphoneSwift:
        case ApplicationType.androidCapacitor:
        case ApplicationType.androidTabletCapacitor:
        case ApplicationType.androidFlutter:
            return true;
        default:
            return false;
    }
};

/**
 * Determines if the platform is Android - NOTE this could be a native app or a web app.
 */
export const isPlatformAndroid = (platformDetails: PlatformDetails): boolean => {
    if (platformDetails.type === PlatformType.android) return true;

    if (platformDetails.capacitorPlatform === 'android') return true;

    if (platformDetails.legacyHybridUseCase === LegacyHybridUseCase.ANDROID_BOARD_LIST) return true;

    return false;
};

/**
 * Determines if the platform is iOS - NOTE this could be a native app or a web app.
 */
export const isPlatformIos = (platformDetails: PlatformDetails): boolean => {
    if (platformDetails.type === PlatformType.iphone) return true;
    if (platformDetails.type === PlatformType.ipad) return true;
    if (platformDetails.type === PlatformType.ipod) return true;

    if (platformDetails.capacitorPlatform === 'ios') return true;

    if (platformDetails.appType === ApplicationType.iphonePwa) return true;
    if (platformDetails.appType === ApplicationType.iphoneSwift) return true;
    if (platformDetails.appType === ApplicationType.iphoneCapacitor) return true;
    if (platformDetails.appType === ApplicationType.ipadSwift) return true;
    if (platformDetails.appType === ApplicationType.ipadCapacitor) return true;

    return false;
};

export const isPlatformApple = (platformDetails: PlatformDetails): boolean =>
    isPlatformIos(platformDetails) ||
    platformDetails.type === PlatformType.mac ||
    platformDetails.appType === ApplicationType.electronMac;

export const isPlatformLegacyMobileApp = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appType === ApplicationType.iphoneSwift ||
    platformDetails.appType === ApplicationType.ipadSwift ||
    platformDetails.appType === ApplicationType.androidFlutter;

export const isPlatformModernMobileApp = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appType === ApplicationType.iphoneCapacitor ||
    platformDetails.appType === ApplicationType.ipadCapacitor ||
    platformDetails.appType === ApplicationType.androidCapacitor ||
    platformDetails.appType === ApplicationType.androidTabletCapacitor;

export const isPlatformInstalledDesktopApp = (platformDetails: PlatformDetails): boolean =>
    isPlatformDesktopPwa(platformDetails) || isPlatformElectronMac(platformDetails);

/**
 * Same as isPlatformInstalled app, but does not include the PWA apps.
 */
export const isPlatformNativeApp = (platformDetails: PlatformDetails): boolean =>
    isPlatformModernMobileApp(platformDetails) ||
    isPlatformLegacyMobileApp(platformDetails) ||
    isPlatformElectronMac(platformDetails);

export const isPlatformInstalledApp = (platformDetails: PlatformDetails): boolean =>
    isPlatformNativeApp(platformDetails) ||
    platformDetails.appType === ApplicationType.iphonePwa ||
    isPlatformDesktopPwa(platformDetails);

/**********************************************************
 * Device / Form Factor
 **********************************************************/

/**
 * Helper function to determine if the platform is a phone or it's in a "mobile mode".
 */
export const isPlatformPhoneOrMobileMode = (platformDetails: PlatformDetails): boolean => {
    if (platformDetails.formFactor === DeviceFormFactor.phone) return true;

    if (platformDetails.appMode === MilanoteApplicationMode.mobileLegacy) return true;

    return platformDetails.appMode === MilanoteApplicationMode.mobile;
};

/**
 * Helper function to determine if the form factor is a tablet
 */
export const isPlatformTablet = (platformDetails: PlatformDetails): boolean => {
    return platformDetails.formFactor === DeviceFormFactor.tablet;
};

/**
 * Helper function to determine if the platform is a phone or using mobile mode, excluding the legacy mobile mode.
 */
export const isModernPhoneOrMobileMode = (platformDetails: PlatformDetails): boolean => {
    if (platformDetails.appMode === MilanoteApplicationMode.mobileLegacy) return false;

    return isPlatformPhoneOrMobileMode(platformDetails);
};

export const isPlatformIpad = (platformDetails: PlatformDetails): boolean =>
    platformDetails.type === PlatformType.ipad ||
    platformDetails.appType === ApplicationType.ipadSwift ||
    platformDetails.appType === ApplicationType.ipadCapacitor;

export const isPlatformIpadCapacitor = (platformDetails: PlatformDetails): boolean =>
    platformDetails.appType === ApplicationType.ipadCapacitor;

export const isPlatformMobileWeb = (platformDetails: PlatformDetails): boolean =>
    platformDetails.formFactor === DeviceFormFactor.phone && platformDetails.appType === ApplicationType.web;

/**
 * This catches the scenario where the device is a desktop, but the app is running in a mobile mode.
 * This should only occur during development / testing by the development team.
 */
export const isDesktopWebTesting = (platformDetails: PlatformDetails): boolean =>
    platformDetails.formFactor === DeviceFormFactor.desktop && platformDetails.appType === ApplicationType.web;
