import React from 'react';
import PropTypes from 'prop-types';
import { min, identity } from 'lodash';
import { branch } from '../../../node_module_clones/recompose';

// Components
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import ImageUploader from './ImageUploader';
import ImageRenderer from './ImageRenderer';
import ColorBar from '../../components/colors/ColorBar';
import Caption from '../../components/caption/Caption';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import ElementResizeHandle from '../resizing/ElementResizeHandle';
import ElementActivityIndicator from '../activity/ElementActivityIndicator';
import QuickNotesActivityIndicator from '../activity/QuickNotesActivityIndicator';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';

// Decorators
import imageDropTargetDecorator from './imageDropTargetDecorator';

// Utils
import { elementClassNames, getDomElementId, isElementBelowEditableWidth } from '../utils/elementUtil';
import { prop, objectSize } from '../../../common/utils/immutableHelper';
import { getNaturalImageWidth } from './imageElementUtils';
import { getData } from '../attachments/attachmentsSelector';
import { getElementStyle, pixelsToGridPoints, scaleToGrid } from '../../utils/grid/gridUtils';
import {
    getColor,
    getImageProp,
    getShowBackground,
    getWidth,
    getTempId,
    getIsDataUri,
    getElementId,
    getCaption,
    getShowCaption,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { IMAGE_SMALL_WIDTH_GRID } from '../../../common/images/imageConstants';

// Styles
import './Image.scss';

// NOTE: This very strangely must be a class component in order to work correctly with React DnD
// If it's not, then "component" is null in the onDrop function of the imageDropTargetDecorator.
@branch(({ allowHoverReplaceImage }) => allowHoverReplaceImage, imageDropTargetDecorator(), identity)
class Image extends React.Component {
    render() {
        const {
            currentBoardId,
            elementId,
            element,
            gridSize,
            inList,
            tempSize,
            elementEvents,
            isEditable,
            isLocked,
            attachment,
            isSelected,
            permissions,
            isPresentational,
            setParentHoveredChildAcceptsDrop,
            getContextZoomScale,
            getContextZoomTranslationPx,
            dispatchGetCurrentBoardImagesForCarousel,
        } = this.props;

        const attachmentData = getData(attachment);
        const imageData = getImageProp(element) || attachmentData;
        const hasImage = objectSize(imageData) > 0;

        if (getTempId(element) && !hasImage) return null;

        const colorName = getColor(element);
        const captionContent = getCaption(element);
        const captionVisible = getShowCaption(element) && !isElementBelowEditableWidth(this.props);

        const imageElementSize = getWidth(element);

        // Initially the width is undefined therefore imageWidth would be ELEMENT_DEFAULT_WIDTH for a few ms
        let naturalImageWidth = getNaturalImageWidth({ element, attachmentData });
        if (hasImage && !naturalImageWidth) return null;

        // When adding data:uri images, they tend to be smaller initially and then resolve to larger images later
        naturalImageWidth = getIsDataUri(element)
            ? ELEMENT_DEFAULT_WIDTH * gridSize
            : scaleToGrid(naturalImageWidth, gridSize);

        const imageWidth =
            (tempSize && tempSize.width) ||
            imageElementSize ||
            min([pixelsToGridPoints(naturalImageWidth, gridSize), ELEMENT_DEFAULT_WIDTH]);

        const isTransparent = !getShowBackground(element) && prop('transparent', imageData);

        const classes = elementClassNames(
            `Image drag-handle`,
            {
                ...this.props,
                transparent: isTransparent,
            },
            {
                'small-width': imageWidth < 15,
                'caption-visible': captionVisible,
                'minimum-width': imageWidth < IMAGE_SMALL_WIDTH_GRID,
            },
        );

        const displayElement = hasImage ? (
            <ImageRenderer
                {...this.props}
                dispatchGetCurrentBoardImagesForCarousel={dispatchGetCurrentBoardImagesForCarousel}
                isTransparent={isTransparent}
            />
        ) : (
            <ImageUploader {...this.props} />
        );

        return (
            <div
                id={getDomElementId(getElementId(element))}
                className={classes}
                style={getElementStyle(imageWidth, gridSize, inList)}
                {...elementEvents}
            >
                <ColorBar colorName={colorName} isSelected={isSelected} />
                <QuickLineCreationTool
                    show={getShowQuickLineTool(this.props)}
                    elementId={elementId}
                    element={element}
                    currentBoardId={currentBoardId}
                    gridSize={gridSize}
                    getContextZoomScale={getContextZoomScale}
                    getContextZoomTranslationPx={getContextZoomTranslationPx}
                />
                <ElementActivityIndicator {...this.props} />
                <QuickNotesActivityIndicator {...this.props} />
                <ElementUserTag {...this.props} />

                {displayElement}

                <Caption
                    {...this.props}
                    padTop
                    placeholder="Add a caption"
                    textContent={captionContent}
                    captionVisible={captionVisible}
                />

                <AttachedCommentsDropTarget
                    setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                    isRelative
                    gridSize={gridSize}
                    isPresentational={isPresentational}
                    element={element}
                    elementId={elementId}
                    getContextZoomScale={getContextZoomScale}
                />
                <AttachedCommentsContainer
                    elementId={elementId}
                    element={element}
                    gridSize={gridSize}
                    isPresentational={isPresentational}
                    currentBoardId={currentBoardId}
                    permissions={permissions}
                />
                <ElementResizeHandle {...this.props} showHandle={!isLocked && isEditable && !inList} />
            </div>
        );
    }
}

Image.propTypes = {
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,
    attachment: PropTypes.object,
    gridSize: PropTypes.number,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    canDrop: PropTypes.bool,
    inList: PropTypes.string,
    uploadFile: PropTypes.func,
    isHovered: PropTypes.bool,
    tempSize: PropTypes.object,
    className: PropTypes.string,
    elementEvents: PropTypes.object,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    filterQuery: PropTypes.string,
    filterState: PropTypes.string,
    permissions: PropTypes.number,
    isPresentational: PropTypes.bool,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
    dispatchGetCurrentBoardImagesForCarousel: PropTypes.func,
};

export default Image;
