// Lib
import React from 'react';

import PropTypes from 'prop-types';

// Components
import DragHandle from './DragHandle';

// Styles
import './ControlPointDragHandle.scss';

const ControlPointDragHandle = (props) => <DragHandle className="ControlPointDragHandle" {...props} />;

ControlPointDragHandle.propTypes = {
    pos: PropTypes.object,
    show: PropTypes.bool,
};

export default ControlPointDragHandle;
