// Const
import { DRAG_START, DRAG_END } from '../../../reducers/draggingConstants';
import {
    ELEMENTS_SELECTED,
    ELEMENT_EDIT_COMPLETE,
    ELEMENT_EDIT_START,
} from '../../../../common/elements/selectionConstants';
import {
    FOCUS_MODE_ACTIVE,
    FOCUS_MODE_APPEARING,
    FOCUS_MODE_DISAPPEARING,
    FOCUS_MODE_INACTIVE,
} from '../../../workspace/presentation/presentationConstants';
import { HYBRID_ACTION_TYPES } from './hybridMiddlewareConstants';
import { LOGOUT } from '../../../auth/authConstants';
import { INTERCOM_SHOW_HELP_CENTER, INTERCOM_SHOW_MESSAGES } from '../../../analytics/analyticsConstants';
import { THEME_MODE_SET } from '../../../user/account/accountModal/interface/themeSettings/themeConstants';
import { ELEMENTS_LOAD } from '../../../../common/elements/elementsConstants';
import { ErrorModalActionTypes } from '../../../components/error/modals/errorModalTypes';

// Utils
import { isEmpty } from 'lodash';
import { swiftEncodeJson } from '../utils/swiftUtils';

// Lib
import logger from '../../../logger/logger';

// Actions which will be forwarded to the `milanoteReduxActionHandler`.
const SEND_TO_SWIFT_ACTIONS = new Set([
    THEME_MODE_SET,
    LOGOUT,
    INTERCOM_SHOW_MESSAGES,
    INTERCOM_SHOW_HELP_CENTER,
    DRAG_START,
    DRAG_END,
    ELEMENT_EDIT_START,
    ELEMENT_EDIT_COMPLETE,
    ELEMENTS_SELECTED,
    ELEMENTS_LOAD,
    FOCUS_MODE_ACTIVE,
    FOCUS_MODE_APPEARING,
    FOCUS_MODE_DISAPPEARING,
    FOCUS_MODE_INACTIVE,
    HYBRID_ACTION_TYPES.ELEMENT_EDIT_START,
    HYBRID_ACTION_TYPES.ELEMENT_SELECTED,
    HYBRID_ACTION_TYPES.LAUNCH_APPLE_SUBSCRIPTION_STORE,
    HYBRID_ACTION_TYPES.LAUNCH_APPLE_MANAGE_SUBSCRIPTION_VIEW,
    HYBRID_ACTION_TYPES.PRESENT_MODAL_IMAGE,
    HYBRID_ACTION_TYPES.PRESENT_MODAL_PDF,
    HYBRID_ACTION_TYPES.USER_NAVIGATE,
    ErrorModalActionTypes.ERROR_MODAL_CLOSE,
]);

/**
 * Supports communication from the JS code to the Swift apps, primarily in the form of
 * forwarding relevant actions to the Swift apps.
 * Actions that are supported will need to be manually defined above.
 */
export default (store) => (next) => (action) => {
    if (action.remote) return next(action);
    // Only actions included in this allowlist will be sent to the swift layer
    if (!SEND_TO_SWIFT_ACTIONS.has(action.type)) return next(action);

    var enhancedAction = action;
    if (action.type == ELEMENTS_SELECTED && !isEmpty(action.ids)) {
        const state = store.getState();
        const elements = action.ids.map((id) => state.getIn(['elements', id]));
        enhancedAction.elements = elements;
    }

    // `window.webkit` is available when the client is ran on iOS WKWebView
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.milanoteReduxActionHandler) {
        // `milanoteJStoSwift` needs to match the message handler name that in WKWebView's configuration
        window.webkit.messageHandlers.milanoteReduxActionHandler
            .postMessage({
                action: swiftEncodeJson(enhancedAction),
            })
            .catch((error) => {
                logger.error('Action could not be posted: ', enhancedAction, error);
            });
    }

    if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
        window.flutter_inappwebview.callHandler('flutterReduxActionHandler', swiftEncodeJson(enhancedAction));
    }

    return next(action);
};
