// Utils
import { allSelectedElementsOnCanvas, commentsFeedbackOtherwiseAll, feedbackRequired } from '../toolbarToolConfigUtils';
import fileGetMediaPlayerType from '../../../../element/file/utils/fileGetMediaPlayerType';

// Components
import DesktopColorTool from '../../components/selectionTools/colorTools/DesktopColorTool';
import MobileColorTool from '../../components/selectionTools/colorTools/popup/mobile/MobileColorTool';
import TitleTool from '../../components/selectionTools/titleTool/TitleTool';
import CaptionTool from '../../components/selectionTools/captionTool/CaptionTool';
import ReactionTool from '../../components/selectionTools/reactionTool/ReactionTool';
import MobileReactionTool from '../../components/selectionTools/reactionTool/MobileReactionTool';
import IconTool from '../../components/selectionTools/iconTools/IconTool';
import MobileIconTool from '../../components/selectionTools/iconTools/mobile/MobileIconTool';
import MediaTool from '../../components/selectionTools/attachmentTool/MediaTool';
import MediaLoopTool from '../../components/selectionTools/attachmentTool/MediaLoopTool';
import MediaAutoplayTool from '../../components/selectionTools/attachmentTool/MediaAutoplayTool';
import DisplayModeTool from '../../components/selectionTools/displayModeTool/DisplayModeTool';
import ImageEditTool from '../../components/selectionTools/imageEditTool/ImageEditTool';
import ShowBackgroundTool from '../../components/selectionTools/showBackgroundTool/ShowBackgroundTool';
import CustomColorTool from '../../components/selectionTools/colorTools/CustomColorTool';
import StartArrowTool from '../../components/selectionTools/arrowTools/StartArrowTool';
import EndArrowTool from '../../components/selectionTools/arrowTools/EndArrowTool';
import LineStyleTool from '../../components/selectionTools/lineTools/LineStyleTool';
import LineWeightTool from '../../components/selectionTools/lineTools/LineWeightTool';
import LineLabelTool from '../../components/selectionTools/lineTools/LineLabelTool';
import CollapseTool from '../../components/selectionTools/commentTools/CollapseTool';
import EditDrawingTool from '../../components/selectionTools/editDrawingTool/EditDrawingTool';
import AlignmentTool from '../../components/selectionTools/arrangementTools/alignmentTools/AlignmentTool';
import DistributeTool from '../../components/selectionTools/arrangementTools/distributeTools/DistributeTool';
import ConnectTool from '../../components/selectionTools/connectTool/ConnectTool';
import ColorDisplayTool from '../../components/selectionTools/colorDisplayTool/ColorDisplayTool';
import MobileContextMenuTool from '../../components/mobileTools/mobileContextMenu/MobileContextMenuTool';
import MobileColorDisplayTool from '../../components/selectionTools/colorDisplayTool/MobileColorDisplayTool';
import MobileLineWeightTool from '../../components/selectionTools/lineTools/MobileLineWeightTool';

// Constants
import { TOOL_HEIGHT } from '../toolbarToolConfigConstants';
import { MEDIA_PLAYER_TYPES } from '../../../../element/file/mediaPlayer/mediaPlayerConstants';
import { CURRENT_BOARD_HEADER_TITLE_EDITOR_KEY } from '../../../../../common/boards/boardConstants';

// Types
import { ToolbarItemConfig } from '../../toolbarTypes';
import { MilanoteApplicationMode } from '../../../../../common/platform/platformTypes';

const COLOR: ToolbarItemConfig = {
    id: 'COLOR',
    name: 'Color',
    component: DesktopColorTool,
    mobileComponent: MobileColorTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    permissionPredicate: commentsFeedbackOtherwiseAll,
    availabilityPredicate: ({ editorKey }) => editorKey !== CURRENT_BOARD_HEADER_TITLE_EDITOR_KEY,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const TITLE: ToolbarItemConfig = {
    id: 'TITLE',
    name: 'Title',
    component: TitleTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const CAPTION: ToolbarItemConfig = {
    id: 'CAPTION',
    name: 'Caption',
    component: CaptionTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const REACTION: ToolbarItemConfig = {
    id: 'REACTION',
    name: 'Add reaction',
    component: ReactionTool,
    mobileComponent: MobileReactionTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: false,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const ICON: ToolbarItemConfig = {
    id: 'ICON',
    name: 'Icon',
    component: IconTool,
    mobileComponent: MobileIconTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: false,
    availabilityPredicate: ({ editorKey }) => editorKey !== CURRENT_BOARD_HEADER_TITLE_EDITOR_KEY,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const MEDIA: ToolbarItemConfig = {
    id: 'MEDIA',
    name: 'Media',
    component: MediaTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: false,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const MEDIA_LOOP: ToolbarItemConfig = {
    id: 'MEDIA_LOOP',
    name: 'Loop',
    component: MediaLoopTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    availabilityPredicate: ({ selectedElements }) =>
        selectedElements.every(
            (element) =>
                fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.AUDIO ||
                fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.VIDEO,
        ),
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const MEDIA_AUTOPLAY: ToolbarItemConfig = {
    id: 'MEDIA_AUTOPLAY',
    name: 'Autoplay',
    component: MediaAutoplayTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    availabilityPredicate: ({ selectedElements }) =>
        selectedElements.every(
            (element) =>
                fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.AUDIO ||
                fileGetMediaPlayerType(element) === MEDIA_PLAYER_TYPES.VIDEO,
        ),
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const DISPLAY_MODE: ToolbarItemConfig = {
    id: 'DISPLAY_MODE',
    name: 'Preview',
    component: DisplayModeTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const IMAGE_EDIT: ToolbarItemConfig = {
    id: 'IMAGE_EDIT',
    name: 'Edit image',
    component: ImageEditTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: false,
    appModeAvailability: [MilanoteApplicationMode.desktop],
};

const SHOW_BACKGROUND: ToolbarItemConfig = {
    id: 'SHOW_BACKGROUND',
    name: 'Transparent',
    component: ShowBackgroundTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const CUSTOM_COLOR: ToolbarItemConfig = {
    id: 'CUSTOM_COLOR',
    name: 'Color',
    component: CustomColorTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const COLOR_DISPLAY: ToolbarItemConfig = {
    id: 'COLOR_DISPLAY',
    name: 'Display',
    component: ColorDisplayTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    mobileComponent: MobileColorDisplayTool,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const START_ARROW: ToolbarItemConfig = {
    id: 'START_ARROW',
    name: 'Start',
    component: StartArrowTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const END_ARROW: ToolbarItemConfig = {
    id: 'END_ARROW',
    name: 'End',
    component: EndArrowTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const LINE_STYLE: ToolbarItemConfig = {
    id: 'LINE_STYLE',
    name: 'Dashed',
    component: LineStyleTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const LINE_WEIGHT: ToolbarItemConfig = {
    id: 'LINE_WEIGHT',
    name: 'Weight',
    component: LineWeightTool,
    mobileComponent: MobileLineWeightTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const LINE_LABEL: ToolbarItemConfig = {
    id: 'LINE_LABEL',
    name: 'Label',
    component: LineLabelTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: false,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const COLLAPSE: ToolbarItemConfig = {
    id: 'COLLAPSE',
    name: 'Collapse',
    component: CollapseTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    permissionPredicate: feedbackRequired,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const EDIT_DRAWING: ToolbarItemConfig = {
    id: 'EDIT_DRAWING',
    name: 'Edit drawing',
    component: EditDrawingTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: false,
    appModeAvailability: [MilanoteApplicationMode.desktop],
};

const ALIGNMENT: ToolbarItemConfig = {
    id: 'ALIGNMENT',
    name: 'Alignment',
    component: AlignmentTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    availabilityPredicate: ({ selectedElements }) =>
        selectedElements.size > 1 && allSelectedElementsOnCanvas(selectedElements),
    appModeAvailability: [MilanoteApplicationMode.desktop],
};

const DISTRIBUTE: ToolbarItemConfig = {
    id: 'DISTRIBUTE',
    name: 'Distribute',
    component: DistributeTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    availabilityPredicate: ({ selectedElements }) =>
        selectedElements.size > 2 && allSelectedElementsOnCanvas(selectedElements),
    appModeAvailability: [MilanoteApplicationMode.desktop],
};

const CONNECT_WITH_LINES: ToolbarItemConfig = {
    id: 'CONNECT_WITH_LINES',
    name: 'Connect',
    component: ConnectTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    availabilityPredicate: ({ selectedElements }) =>
        selectedElements.size > 1 && allSelectedElementsOnCanvas(selectedElements),
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const MOBILE_CONTEXT_MENU: ToolbarItemConfig = {
    id: 'MOBILE_CONTEXT_MENU',
    name: 'More',
    component: MobileContextMenuTool,
    height: TOOL_HEIGHT.SQUARE,
    multiSelect: true,
    availabilityPredicate: ({ editorKey }) => editorKey !== CURRENT_BOARD_HEADER_TITLE_EDITOR_KEY,
    appModeAvailability: [MilanoteApplicationMode.mobile],
};

export default {
    COLOR,
    TITLE,
    CAPTION,
    REACTION,
    ICON,
    MEDIA,
    MEDIA_LOOP,
    MEDIA_AUTOPLAY,
    DISPLAY_MODE,
    IMAGE_EDIT,
    SHOW_BACKGROUND,
    CUSTOM_COLOR,
    COLOR_DISPLAY,
    START_ARROW,
    END_ARROW,
    LINE_STYLE,
    LINE_WEIGHT,
    LINE_LABEL,
    COLLAPSE,
    EDIT_DRAWING,
    ALIGNMENT,
    DISTRIBUTE,
    CONNECT_WITH_LINES,
    MOBILE_CONTEXT_MENU,
} as const;
