import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getHeaderAvoidanceInsets } from '../../../../components/tooltips/tooltipInsetUtils';
import { closePopup as closePopupAction } from '../../../../components/popupPanel/popupActions';

// Selectors
import { isPopupIdOpenSelector } from '../../../../components/popupPanel/popupOpenSelector';
import { globalGridSizeSelector } from '../../../../utils/grid/gridSizeSelector';
import { getCurrentBoard } from '../../../../reducers/currentBoardId/currentBoardIdSelector';

// Hooks
import { useVirtualKeyboardHeightContext } from '../../../../utils/keyboard/VirtualKeyboardHeightContext';

// Components
import FloatingPanel from '../../../../components/tooltips/FloatingPanel';
import PopupPanelMouseManager from '../../../../components/popupPanel/PopupPanelMouseManager';

// Constants
import { TooltipAlignment, TooltipPositions } from '../../../../components/tooltips/tooltipConstants';

import './ToolbarPopup.scss';

type ToolbarPopupProps = {
    buttonSelector: string;
    popupId: string;
    className: string;
    closePopup: () => void;
    ignoredClicksSelector: string;
    offset: number;
    distance: number;
    closeOnMouseUp: boolean;
    stayOpen: boolean;
    alignment?: TooltipAlignment;
    position?: TooltipPositions;
    showArrow: boolean;
    arrowColor: string;
    arrowStrokeColor: string;
    arrowStrokeWidth: number;
    arrowTranslation: string;
};

const ToolbarPopupContents: React.FC<ToolbarPopupProps> = ({
    buttonSelector,
    popupId,
    className,
    ignoredClicksSelector,
    children,
    offset = 0,
    distance = 15,
    alignment,
    position = TooltipPositions.RIGHT,
    closeOnMouseUp = false,
    showArrow,
    arrowColor,
    arrowTranslation = `${offset - 2}px`,
    arrowStrokeColor = 'none',
    arrowStrokeWidth,
    ...props
}) => {
    const currentBoard = useSelector(getCurrentBoard);
    const globalGridSize = useSelector(globalGridSizeSelector);

    const dispatch = useDispatch();
    const closePopup = useCallback(() => dispatch(closePopupAction(popupId)), [popupId]);

    const virtualKeyboardHeight = useVirtualKeyboardHeightContext();

    return (
        <FloatingPanel
            selector={buttonSelector}
            className={classNames('ToolbarPopup light', className)}
            showArrow={showArrow}
            arrowColor={arrowColor}
            arrowStrokeColor={arrowStrokeColor}
            arrowWidth={13}
            arrowHeight={8}
            arrowStrokeWidth={arrowStrokeWidth}
            arrowStyle={{
                transform: `translate(${arrowTranslation}, 0.5px)`,
            }}
            distance={distance}
            offset={offset}
            position={position}
            alignment={alignment}
            pollPosition
            insets={getHeaderAvoidanceInsets(globalGridSize, currentBoard, virtualKeyboardHeight)}
        >
            <PopupPanelMouseManager
                {...props}
                visible
                closePopup={closePopup}
                popupId={popupId}
                closeOnMouseUp={closeOnMouseUp}
                ignoredClicksSelector={ignoredClicksSelector}
            >
                <div className="popup-content">{children}</div>
            </PopupPanelMouseManager>
        </FloatingPanel>
    );
};

const ToolbarPopup: React.FC<ToolbarPopupProps> = (props) => {
    // @ts-ignore The selector does take two args
    const isPopupOpen = useSelector((state) => isPopupIdOpenSelector(state, props));

    if (!isPopupOpen) return null;

    return <ToolbarPopupContents {...props} />;
};

export default ToolbarPopup;
