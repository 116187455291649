// Utils
import globalLogger from '../../logger';

// Types
import { ReduxStore } from '../../types/reduxTypes';
import { VisibleConnectionStatus } from './visibleConnectionStatusTypes';
import { visibleConnectionStatusChanged } from './visibleConnectionStatusActions';
import { StateMachineState } from '../../../common/utils/lib/stateMachine';

const logger = globalLogger.createChannel('visible-connection-status');

/**
 * Adds logging and dispatching of the state transition to redux, so that the concrete state classes
 * can focus on the logic of the state.
 */
export abstract class BaseVisibilityState extends StateMachineState {
    onEnter(store: ReduxStore) {
        logger.info(`Entering ${this.stateKey}`);
        store.dispatch(visibleConnectionStatusChanged(this.stateKey as VisibleConnectionStatus));
    }

    onExit(store: ReduxStore) {
        logger.info(`Exiting ${this.stateKey}`);
    }
}
