// Lib
import io from 'socket.io-client';
import HttpStatus from 'http-status-codes';
import { compact, get, isEmpty } from 'lodash/fp';

// Util
import platformSingleton from '../../platform/platformSingleton';
import { manuallyReportError } from '../../analytics/rollbarService';
import {
    isPlatformModernMobileApp,
    isPlatformLegacyMobileApp,
    isPlatformElectronMac,
} from '../../platform/utils/platformDetailsUtils';
import {
    cleanPersistedBuffer,
    cleanSendBufferBeforeFlush,
    debugSocketAction,
    debugSocketMaxRetryError,
    getResponseStatus,
    REFRESH_BOARD_ON_RECONNECT_ACTION_TYPES,
} from './socketClientUtils';
import getActionChannels from './getActionChannels';
import delay from '../../../common/utils/lib/delay';
import globalLogger from '../../logger/logger';
import { fetchBoard } from '../../element/board/boardService';
import { handleSocketErrors } from './handleSocketErrors';
import { getTimestamp } from '../../../common/utils/timeUtil';

// Socket throttling
import socketClientActionCounter from './socketClientActionCounter';
import { monitorLogAction } from '../../../common/actions/monitoring/monitoringActions';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { selectCapacitorLifecycleStatus } from '../../capacitor/store/capacitorLifecycleSelector';

// Actions
import { socketConnect, socketDisconnect, socketInterruption } from './socketActions';
import { openErrorNotice } from '../../components/error/errorNoticeActions';

// Config
import getClientConfig from '../getClientConfig';

// Constants
import { SocketError } from './socketConstants';
import {
    DEFAULT_SOCKET_INTERVAL,
    DEFAULT_SOCKET_TIMEOUT,
    SOCKET_EVENT_NAMES,
    SocketIoEvents,
} from '../../../common/utils/socket/socketConstants';
import { ROLLBAR_LEVELS } from '../../analytics/rollbarConstants';
import { ActionMonitoringDataKey } from '../../../common/actions/actionTypes';
import { SERVER_TYPE } from '../../../../config/configConstants';
import { CapacitorAppStatus } from '../../capacitor/store/capacitorLifecycleTypes';

const MAX_SOCKET_EMIT_RETRIES = 3;
const SOCKET_DISCONNECT_TIMEOUT = 20000;
const SOCKET_DISCONNECT_COUNT_TIMEOUT = 10000;

const clientConfig = getClientConfig();
const apiRoot = clientConfig.apiRoot || '/';

const logger = globalLogger.createChannel('socket-client');

const createSocketClient = (userId) => {
    if (
        isPlatformElectronMac(platformSingleton) ||
        isPlatformLegacyMobileApp(platformSingleton) ||
        isPlatformModernMobileApp(platformSingleton)
    ) {
        const url = apiRoot.replace('https', 'wss').replace('http', 'ws');

        logger.info('createSocketClient', { userId, url });

        return io(url, {
            query: `userId=${userId}`,
            transports: ['websocket', 'polling'],
        });
    }

    logger.info('createSocketClient', { userId });
    return io({ query: `userId=${userId}` });
};

class SocketManager {
    constructor(createSendBuffer) {
        this.socketClient = null;
        this.store = null;
        this.dispatch = null;
        this.isConnected = false;
        this.fetchCurrentBoardOnFlush = false;
        this.sendBuffer = cleanPersistedBuffer(createSendBuffer());
        this.userId = null;

        this.blockSocket = false;

        this.disconnectionCount = 0;
        this.disconnectionCountTimeoutId = null;

        this.disconnectionTimeoutId = null;
        this.getActionChannels = () => null;

        this.sentActionCounter = socketClientActionCounter();
        this.blockActions = false;

        this.lastKnownConnectionTime = 0;
    }

    logAndReportError(level, errorMessage, custom) {
        logger.error(errorMessage);

        manuallyReportError({
            errorMessage,
            level,
            error: new Error(errorMessage),
            custom,
        });
    }

    reconnectSocket() {
        logger.info('reconnectSocket');
        this.socketClient.connect();
    }

    /**
     * Creates a new socket connection associated to the current user ID, or reuses an existing one if
     * a socket connection already exists.
     */
    connectSocket = (userId) => {
        logger.info('connectSocket', { userId });

        if (!userId && !this.userId) {
            const errorMessage = 'Socket client: Attempting to connect a socket without a userId';
            this.logAndReportError(ROLLBAR_LEVELS.WARNING, errorMessage);
            return;
        }

        // We're blocking as a safety net in an attempt to prevent socket connection loops from
        //  causing spikes on the WS servers.
        if (this.blockSocket) {
            const errorMessage = 'Socket client: Attempting to connect a socket while blocked';

            this.logAndReportError(ROLLBAR_LEVELS.WARNING, errorMessage);

            this.dispatch(
                openErrorNotice({
                    errorNoticeId: SocketError.CONNECTION_FAILURE,
                    data: {
                        error: {
                            code: SocketError.CONNECTION_FAILURE,
                            message: errorMessage,
                        },
                        boardId: getCurrentBoardId(this.store.getState()),
                    },
                }),
            );

            return;
        }

        if (this.socketClient) {
            if (!userId || this.userId === userId) {
                if (this.socketClient.connected) {
                    // If we already have a socket client, and the userId hasn't changed, and it's connected, why are we
                    // trying to reconnect?
                    const errorMessage = 'Socket client: Attempting to connect a socket while already connected';
                    this.logAndReportError(ROLLBAR_LEVELS.WARNING, errorMessage);
                } else {
                    // If we already have a socket client, and the userId hasn't changed, and it's disconnected, then
                    // just reconnect it.
                    this.reconnectSocket();
                }

                return;
            }

            // Else the userId changed, so ensure all the existing socket client event handlers get removed and
            // disconnect it, then create a new socket client.
            this.disconnectAndDestroySocket();
        }

        this.userId = userId || this.userId;

        this.socketClient = createSocketClient(this.userId);

        // Socket connection handler
        this.socketClient.on(SOCKET_EVENT_NAMES.CONNECT, this.onSocketConnectionHandler);

        // Incoming action handler
        this.socketClient.on(SOCKET_EVENT_NAMES.ACTION, this.onSocketActionHandler);

        // Socket disconnection handler
        this.socketClient.on(SOCKET_EVENT_NAMES.DISCONNECT, this.onSocketDisconnectionHandler);

        // Add ping event listener
        //  This is used to determine the actual disconnection time in the capacitor apps, as
        //  the disconnect event is not always fired immediately when the app is backgrounded,
        //  but instead when the app is brought back to the foreground.
        // NOTE: The `.io.on` method is not available in the test context, so for now we're just
        //  checking if it exists before calling it. We might need to find another way to test this.
        this.socketClient.io.on?.(SocketIoEvents.ping, this.updateLastKnownConnectionTime);
    };

    /**
     * Manually disconnects a socket client.
     */
    disconnectAndDestroySocket = () => {
        logger.info('disconnectAndDestroySocket');

        this.disconnectionTimeoutId && clearTimeout(this.disconnectionTimeoutId);

        // Remove event handlers and disconnect and destroy socket client
        if (this.socketClient) {
            this.socketClient.off();
            this.socketClient.io.disconnect();
            // We need to set the socketClient to null here, as Electron will not allow us to reconnect a socket when a
            // user logs out and logs back in with the same userId.
            this.socketClient = null;
        }
    };

    /**
     * Blocks a socket connection from occurring.
     *
     * Currently used when a socket buffer fails to synchronise, we try to prevent the client from getting
     * into a loop of connection attempts.
     */
    blockSocketConnection = (reason, errorDetails, payloadActions, payloadSize) => {
        logger.info('blockSocketConnection', { reason, errorDetails, payloadActions, payloadSize });

        this.disconnectAndDestroySocket();

        this.blockSocket = true;

        const errorMessage = `Socket client: Blocking socket connection - ${reason}`;
        this.logAndReportError(ROLLBAR_LEVELS.WARNING, errorMessage, {
            errorDetails,
            payloadActionNames: payloadActions,
            payloadSizeBytes: payloadSize,
        });
    };

    /**
     * Handles when a connection is successfully made with the socket server.
     */
    onSocketConnectionHandler = () => {
        logger.info('onSocketConnectionHandler');
        this.updateLastKnownConnectionTime();

        if (this.disconnectionTimeoutId) {
            clearTimeout(this.disconnectionTimeoutId);
            this.disconnectionTimeoutId = null;
        }

        const state = this.store.getState();
        this.updateChannels({
            joined: state.getIn(['app', 'socketConnection', 'channels']).toJS(),
            left: [],
        });

        cleanSendBufferBeforeFlush(this.sendBuffer);

        this.flushSendBuffer().then(() => {
            this.isConnected = true;
            this.dispatch(socketConnect(getTimestamp()));
            if (this.fetchCurrentBoardOnFlush) {
                this.dispatch(
                    fetchBoard({
                        boardId: getCurrentBoardId(this.store.getState()),
                        loadAncestors: false,
                        force: true,
                    }),
                );
                this.fetchCurrentBoardOnFlush = false;
            }
        });
    };

    /**
     * Handles when a remote action is received from the socket server.
     */
    onSocketActionHandler = (action) => {
        this.updateLastKnownConnectionTime();
        return this.dispatch(action);
    };

    /**
     * Handles when a disconnection occurs with the socket server.
     */
    onSocketDisconnectionHandler = (errorMessage, errorDetails) => {
        this.disconnectionCount++;

        const state = this.store.getState();

        logger.info('onSocketDisconnectionHandler', {
            errorMessage,
            errorDetails,
            disconnectionCount: this.disconnectionCount,
        });

        // If a second disconnect occurs before the disconnection timeout, clear the timer
        if (this.disconnectionCountTimeoutId) clearTimeout(this.disconnectionCountTimeoutId);

        // If it's been 10 seconds since the last disconnection
        this.disconnectionCountTimeoutId = setTimeout(() => {
            this.disconnectionCount = 0;
            this.disconnectionCountTimeoutId = null;
        }, SOCKET_DISCONNECT_COUNT_TIMEOUT);

        const shouldClearSocketBuffer =
            this.disconnectionCount > 3 &&
            (errorMessage === 'transport close' || errorMessage === 'transport error') &&
            !this.sendBuffer.isEmpty();

        // This has been found to happen if a socket message exceeds the limit set on the socket server
        // e.g. "maxHttpBufferSize". Unfortunately the client won't receive information relating to what
        // actually went wrong, but due to the sendBuffer and retry mechanism, this will result in the
        // socket connecting and disconnecting many times in a short time period. Thus, we keep a count
        // of the number of disconnects within a 10 second period. If we do more than 3 and we have a
        // non-empty sendBuffer then it seems likely that the cause of the error is what's in the sendBuffer.
        // So we clear the send buffer and show the "save error" modal, so that the user will refresh their page.
        if (shouldClearSocketBuffer) {
            const stringBuffer = JSON.stringify(this.sendBuffer?.getBuffer()) || '';
            const payloadSizeBytes = stringBuffer.length;
            const payloadActionNames =
                this.sendBuffer
                    ?.getOrderedActions()
                    ?.map((action) => action.type)
                    .filter(Boolean)
                    .join(', ') || '';

            this.sendBuffer.clearBuffer();

            this.dispatch(
                openErrorNotice({
                    errorNoticeId: SocketError.CONNECTION_FAILURE,
                    data: {
                        error: {
                            code: SocketError.CONNECTION_FAILURE,
                            message: 'Socket buffer failed to synchronise',
                        },
                        boardId: getCurrentBoardId(state),
                    },
                }),
            );

            this.blockSocketConnection(
                'Failed to sync socket buffer',
                errorDetails,
                payloadActionNames,
                payloadSizeBytes,
            );
            return;
        }

        // APP DISCONNECTION IMPROVEMENTS
        // In the capacitor apps, the disconnect handler is not fired immediately if the app is suspended
        //  in the background - because the suspension will prevent any application code from running.
        // We can detect this case by comparing the time that we should have expected the disconnection event
        //  to fire (pingInterval + pingTimeout) with the time since we last received a socket message.
        // If it's longer ago than the expected disconnection time, we can assume that the disconnection event
        //  wasn't fired immediately, so we want to backdate it
        // In browsers, this might occur if the computer goes to sleep and wakes up much later
        const pingInterval = this.socketClient.io.engine?.pingInterval || DEFAULT_SOCKET_INTERVAL;
        const pingTimeout = this.socketClient.io.engine?.pingTimeout || DEFAULT_SOCKET_TIMEOUT;
        const pingDisconnectionTime = pingInterval + pingTimeout;
        const timeSinceLastConnection = Date.now() - this.lastKnownConnectionTime;

        const platformDetails = getPlatformDetailsSelector(state);
        const isCapacitorApp = isPlatformModernMobileApp(platformDetails);
        const capacitorAppStatus = selectCapacitorLifecycleStatus(state);
        const isCapacitorAppNotActive = isCapacitorApp && capacitorAppStatus !== CapacitorAppStatus.ACTIVE;

        // If it's a capacitor app that's not active, and a disconnection occurs - it should be a full disconnection
        //  regardless of the time since the last successful connection
        const isFullDisconnection = isCapacitorAppNotActive || timeSinceLastConnection > pingDisconnectionTime;

        // Backdate the timestamps if required
        const interruptionTimestamp = isFullDisconnection ? this.lastKnownConnectionTime : getTimestamp();

        this.dispatch(socketInterruption(interruptionTimestamp));

        this.isConnected = false;

        if (isFullDisconnection) {
            logger.info('onSocketDisconnectionHandler - manually forcing socket disconnect');
            this.dispatch(socketDisconnect(this.lastKnownConnectionTime));

            if (this.disconnectionTimeoutId) {
                clearTimeout(this.disconnectionTimeoutId);
                this.disconnectionTimeoutId = null;
            }

            return;
        }

        // If we've got an existing disconnection timeout that hasn't been cleared, don't start a new one
        if (this.disconnectionTimeoutId) return;

        // Mark the socket as disconnected if we have not reconnected within 20 seconds
        this.disconnectionTimeoutId = setTimeout(() => {
            logger.info('onSocketDisconnectionHandler - socket disconnect');
            this.dispatch(socketDisconnect(getTimestamp()));
        }, SOCKET_DISCONNECT_TIMEOUT);
    };

    /**
     * Keep track on the last time we successfully used the socket.io connection with the server.
     */
    updateLastKnownConnectionTime = () => {
        this.lastKnownConnectionTime = Date.now();
    };

    /**
     * Connect to all channels passed in here to ensure we can receive messages.
     * Only validate whether we should send messages when sending.
     */
    updateChannels = ({ joined, left }) => {
        // Don't do anything if not joining or leaving anything
        if (isEmpty(joined) && isEmpty(left)) return;

        this.safeSocketEmit(SOCKET_EVENT_NAMES.UPDATE_CHANNELS, { joined, left });
    };

    // TODO Could turn this into an async function if we updated electron to support it
    flushSendBuffer = () => {
        if (this.sendBuffer.isEmpty()) {
            this.sendBuffer.clearBuffer();
            return Promise.resolve();
        }

        const { id, eventName, action } = this.sendBuffer.getNextAction();

        action.bufferFlush = true;
        action.replay = true;

        action.monitoring = action.monitoring || {};
        action.monitoring[ActionMonitoringDataKey.REQUEST_MODE] = 'bufferFlush';

        if (REFRESH_BOARD_ON_RECONNECT_ACTION_TYPES[action.type]) {
            this.fetchCurrentBoardOnFlush = true;
        }

        return (
            this.rawSocketEmit({ eventName, action, actionId: id, isBufferFlush: true })
                // Skip errors so we try to at least send every event
                .catch(() => null)
                .then(this.flushSendBuffer)
        );
    };

    emitAction = (syncAction) => {
        const channels = compact(this.getActionChannels(syncAction));

        // if there are no valid channels to send the action to, don't send it!
        if (!channels.length) {
            // Only report this in non-production environments, for now, to ensure that we're not
            //  flooding Rollbar with errors. When confirmed, open this up to production too.
            if (getClientConfig().serverType !== SERVER_TYPE.production) {
                const errorMessage = `socketClient: Action cannot be sent as no valid channels were found (WEB-12595)`;
                logger.error(errorMessage, syncAction);

                manuallyReportError({
                    errorMessage,
                    level: ROLLBAR_LEVELS.ERROR,
                    error: new Error(errorMessage),
                    custom: {
                        actionType: syncAction.type,
                        userId: syncAction.user?._id,
                    },
                });
            }

            return;
        }

        this.safeSocketEmit(SOCKET_EVENT_NAMES.ACTION, {
            ...syncAction,
            channels,
        });
    };

    /**
     * Saves the action to a send buffer before emitting it via the socket.
     * This will ensure that the action will not be lost until an acknowledgement is received.
     */
    safeSocketEmit = (eventName, action) => {
        // TODO - Enable this when socket throttling logic has been validated
        // if (this.blockActions) return;

        const actionId = this.sendBuffer.addActionToBuffer(eventName, action);

        if (!this.isConnected) return;

        return this.rawSocketEmit({ eventName, action, actionId });
    };

    /**
     * Sends an action via the socket client and removes the action from the send buffer when
     * an "OK" acknowledgement is received.
     * Otherwise retries on error, until a maximum number of retries, then log the error and
     * remove the action from the send buffer.
     */
    rawSocketEmit = ({ eventName, action, actionId, attempt = 0, isBufferFlush }) =>
        new Promise((resolve, reject) => {
            // Don't count buffer flushes within the throttled count
            if (!isBufferFlush) {
                this.sentActionCounter.incrementSentActionCount(action);

                if (!this.sentActionCounter.isWithinCountLimits(action)) {
                    logger.error('Action count limit exceeded', action.type);

                    // TODO - Enable this when socket throttling logic has been validated
                    // this.blockActions = true;

                    const monitoringAction = monitorLogAction({
                        user: action.user,
                        operation: 'SOCKET_THROTTLE',
                        data: {
                            [ActionMonitoringDataKey.ACTION_TYPE]: action.type,
                        },
                    });
                    this.socketClient.emit(SOCKET_EVENT_NAMES.ACTION, monitoringAction);

                    // TODO - Enable this when socket throttling logic has been validated
                    // this.dispatch(errorModalOpen({
                    //     modalId: SocketError.ACTION_LIMIT_EXCEEDED,
                    //     data: {
                    //         error: {
                    //             code: SocketError.ACTION_LIMIT_EXCEEDED,
                    //             message: `Client socket action limit exceeded (${get('type', action)})`,
                    //         },
                    //         action,
                    //         boardId: getCurrentBoardId(this.store.getState()),
                    //     },
                    // }));
                    //
                    // return;
                }
            }

            this.socketClient.emit(eventName, action, async (response) => {
                debugSocketAction(eventName, action);

                const responseStatus = getResponseStatus(response);

                if (responseStatus === HttpStatus.OK) {
                    this.updateLastKnownConnectionTime();
                    this.sendBuffer.removeActionFromBuffer(actionId);
                    return resolve();
                }

                const handled = this.dispatch(handleSocketErrors(response, action));

                if (handled) {
                    this.sendBuffer.removeActionFromBuffer(actionId);
                    return resolve();
                }

                const isRetriable = !responseStatus || responseStatus >= 500;

                if (isRetriable && attempt < MAX_SOCKET_EMIT_RETRIES) {
                    await delay(Math.pow(attempt + 1, 2) * 100);

                    // Add properties onto the event so the server knows it's a replay
                    action.replay = true;
                    action.attempt = attempt + 1;

                    action.monitoring = action.monitoring || {};
                    action.monitoring[ActionMonitoringDataKey.REQUEST_MODE] = 'retry';

                    return this.rawSocketEmit({ eventName, action, actionId, attempt: attempt + 1 }).then(resolve);
                }

                debugSocketMaxRetryError(eventName, action, response);

                this.sendBuffer.removeActionFromBuffer(actionId);

                // TODO Add to failed actions?
                this.dispatch(
                    openErrorNotice({
                        errorNoticeId: SocketError.ACTION_FAILURE,
                        data: {
                            error: {
                                code: SocketError.ACTION_FAILURE,
                                message: `${responseStatus}: Client socket action error (${get('type', action)})`,
                            },
                            action,
                            boardId: getCurrentBoardId(this.store.getState()),
                        },
                    }),
                );

                return resolve();
            });
        });

    subscribeToStore = (store) => {
        this.store = store;
        this.dispatch = this.store.dispatch.bind(this.store);
        this.getActionChannels = getActionChannels(this.store);
    };
}

export const getSocketManager = (createSendBuffer) => new SocketManager(createSendBuffer);
