// Constants
import { CURRENT_BOARD_ID_SET, VISIBLE_BOARD_ID_SET } from './currentBoardIdConstants';

export const currentBoardIdSet = ({ boardId, restored }: { boardId: string; restored?: boolean }) => ({
    type: CURRENT_BOARD_ID_SET,
    boardId,
    restored,
});

export const visibleBoardIdSet = ({ boardId }: { boardId: string }) => ({
    type: VISIBLE_BOARD_ID_SET,
    boardId,
});
