import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarLoginIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        viewBox="0 0 26 26"
        className="Icon mobile-toolbar-login"
        {...props}
    >
        <path
            fill="#5F6672"
            fillRule="evenodd"
            d="M11.917 1.354a.813.813 0 0 1 0 1.625h-6.5c-.748 0-1.354.606-1.354 1.354v17.334c0 .748.606 1.354 1.354 1.354h6.5a.813.813 0 0 1 0 1.625h-6.5a2.98 2.98 0 0 1-2.98-2.98V4.334a2.98 2.98 0 0 1 2.98-2.979h6.5zm5.99 5.655 5.418 5.416.017.019c.016.016.03.033.044.05l-.061-.069a.818.818 0 0 1 .238.575l-.001.029a.817.817 0 0 1-.004.054l.005-.083a.818.818 0 0 1-.238.575l-5.417 5.416a.812.812 0 1 1-1.15-1.149l4.03-4.03H9.75a.812.812 0 1 1 0-1.624h11.038l-4.03-4.03A.813.813 0 0 1 16.68 7.1l.079-.091a.812.812 0 0 1 1.149 0z"
        />
    </svg>
);
const Memo = memo(MobileToolbarLoginIcon);
export default Memo;
