const createVirtualKeyboardMaxHeightSingleton = () => {
    let virtualKeyboardHeight = 0;

    return {
        getVirtualKeyboardMaxHeight() {
            return virtualKeyboardHeight;
        },
        setVirtualKeyboardMaxHeight(height: number) {
            if (height <= virtualKeyboardHeight) return;

            virtualKeyboardHeight = height;
        },
    };
};

const virtualKeyboardMaxHeightSingleton = createVirtualKeyboardMaxHeightSingleton();

export default virtualKeyboardMaxHeightSingleton;
