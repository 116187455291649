/**
 * This is a custom, basic implementation of a state machine.
 *
 * To use it, create classes that extend the StateMachineState class & implement the nextState method
 *  and stateKey static property, at a minimum.
 *
 * Then create the state machine via:
 *
 * ```typescript
 * const stateMachine = new StateMachine([State1, State2, State3], InitialState);
 * ```
 *
 * Each state class will define what actions will cause a transition to another state.
 *
 * Using a state machine can improve code by making it easier to reason about the logic flow.
 *
 * They are well suited to Redux applications, because actions are already being triggered for
 * the important events in the application.
 */
// Lib
import { Action } from 'redux';

/**
 * The base class of a state in the state machine.
 * Extend this class to create a new state.
 * Implement the nextState method to define the transitions to other states.
 */
export abstract class StateMachineState {
    static readonly stateKey: string = 'abstract-state';

    onEnter?(context: any): void;
    onAction?(action: Action, context: any): void;
    abstract nextState(action: Action): string | undefined;
    onExit?(context: any): void;

    get stateKey(): string {
        return (this.constructor as typeof StateMachineState).stateKey;
    }
}

// Simple helper type to improve readability below
type NonAbstractState = { stateKey: string } & (new () => StateMachineState);

/**
 * The state machine class.
 * Create an instance of this class to manage the state transitions.
 * It's unlikely that this class will need to be extended.
 */
export class StateMachine {
    public currentState: StateMachineState;
    private readonly validStatesMap: Record<string, NonAbstractState>;
    private isInitialised = false;

    constructor(validStateClasses: Array<NonAbstractState>, InitialState: typeof StateMachineState) {
        this.validStatesMap = Object.fromEntries(
            validStateClasses.map((StateClass) => [StateClass.stateKey, StateClass]),
        );
        // Assign the initial state, but we don't call onEnter until the first redux action happens (see below)
        this.currentState = this.getState(InitialState.stateKey);
    }

    private getState(stateName: string): StateMachineState {
        const StateClass = this.validStatesMap[stateName];

        if (!StateClass) {
            throw new Error(`Unknown state ${stateName}`);
        }

        return new StateClass();
    }

    public onAction(action: Action, context: any) {
        if (!this.isInitialised) {
            this.isInitialised = true;
            this.currentState.onEnter?.(context);
        }

        this.currentState.onAction?.(action, context);
        const nextStateName = this.currentState.nextState(action);

        if (!nextStateName) return;

        const nextState = this.getState(nextStateName);

        this.currentState.onExit?.(context);
        nextState.onEnter?.(context);
        this.currentState = nextState;
    }
}
