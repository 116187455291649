import React, { FC } from 'react';

import RouteLink from '../../../components/buttons/RouteLink';
import classNames from 'classnames';
import IconDark from '../../../components/icons/dark/IconDark';
import { useSelector } from 'react-redux';
import { selectIsMobileAppMode, selectIsTabletFormFactor } from '../../../platform/platformSelector';
import { currentBoardIdSelector, getIsCurrentVisibleBoardFetching } from '../../../element/board/boardSelector';
import { isGuestSelector } from '../../../user/currentUserSelector';

import './Splash.scss';

type SplashProps = {
    location: Location;
};

const VALID_SPLASH_PATHNAMES = new Set(['/', '/login', '/register', '/forgotten-password']);

export const selectIsSplashVisible = (state: any, location: Location) => {
    // only displayed on mobile and tablet
    if (!selectIsMobileAppMode(state) && !selectIsTabletFormFactor(state)) return false;

    // only displayed when logged out
    if (!isGuestSelector(state)) return false;

    // only shown when we're not on a board
    if (currentBoardIdSelector(state)) return false;
    if (getIsCurrentVisibleBoardFetching(state)) return false;

    return VALID_SPLASH_PATHNAMES.has(location.pathname);
};

const Splash: FC<SplashProps> = ({ location }) => {
    const isSplashVisible = useSelector((state) => selectIsSplashVisible(state, location));

    if (!isSplashVisible) return null;

    return (
        <div className="AppSplash">
            <div className="mascot" />
            <div className="logo-section">
                <div className="logo">
                    <IconDark name="milanote-logo-small" />
                </div>
                <h1 className="heading">Milanote</h1>
            </div>
            <h2 className="subheading">
                Get organised. <br />
                Stay creative.
            </h2>
            <div className="spacer" />
            <div className="buttons">
                <RouteLink
                    to="/login"
                    className={classNames('Button', 'StyledButton', 'row', 'primary')}
                    keepParams
                    location={location}
                >
                    Log in
                </RouteLink>
                <RouteLink
                    to="/register"
                    keepParams
                    className={classNames('Button', 'StyledButton', 'row', 'secondary')}
                    location={location}
                >
                    Sign up
                </RouteLink>
            </div>
        </div>
    );
};

export default Splash;
