const timeoutMap = new Map<HTMLElement, ReturnType<typeof setTimeout> | undefined>();

export const triggerBackgroundPulseCssAnimation = (selector: string, duration = 400) => {
    const domEl = document.querySelector(selector) as HTMLElement;

    if (!domEl) return;

    const oldPulseTimeout = timeoutMap.get(domEl);

    if (oldPulseTimeout) {
        clearTimeout(oldPulseTimeout);
        domEl.classList.remove('pulse');
        timeoutMap.delete(domEl);

        // Queue up the next pulse
        requestAnimationFrame(() => triggerBackgroundPulseCssAnimation(selector, duration));

        return;
    }

    domEl.classList.add('pulse');

    const pulseTimeout = setTimeout(() => {
        if (!domEl) return;

        domEl.classList.remove('pulse');
        timeoutMap.delete(domEl);
    }, duration);

    timeoutMap.set(domEl, pulseTimeout);
};
