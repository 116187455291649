import React, { createContext, useContext } from 'react';
import useSheetContextState, { SheetContextState } from './hooks/useSheetContextState';
import { SheetProps } from './SheetContainer';

const SheetContext = createContext<SheetContextState>({
    sheetContentRef: { current: null },
    sheetRef: { current: null },
    snapPointsState: [],
    isSheetMounted: false,
    setIsSheetMounted: (isMounted: boolean) => {},
    dispatchCloseSheet: () => {},
    handleSheetTouchStart: () => {},
    handleSheetTouchMove: () => {},
    handleSheetTouchEnd: () => {},
    handleSheetTouchCancel: () => {},
});

export const useSheetContext = () => useContext(SheetContext);

export const SheetContextProvider = (props: SheetProps) => {
    const { children } = props;
    const sheetContext = useSheetContextState(props);

    return (
        <SheetContext.Provider value={sheetContext}>
            {typeof children === 'function' ? children(sheetContext.isSheetMounted) : children}
        </SheetContext.Provider>
    );
};
