// Lib
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { identity } from 'lodash/fp';
import { debounce } from 'lodash';
import { branch } from '../../node_module_clones/recompose';
import { NativeTypes } from 'react-dnd-html5-backend';

// Utils
import { now } from '../utils/react/propsComparisons';
import getDomElementAncestors from '../utils/dom/getDomElementAncestors';
import canDomElementBecomeVerticallyScrollable from '../utils/dom/canDomElementBecomeVerticallyScrollable';
import { getRequiredSavePermission } from '../../common/elements/utils/elementTypePermissionsUtils';
import { hasPermission } from '../../common/permissions/permissionUtil';
import { listDropHoverFn } from './listDropHoverFn';
import { multiRef } from '../utils/multiRef';

// Constants
import { DROP_TARGET_TYPES } from '../../common/elements/elementConstants';
import { NATIVE_TYPES } from '../utils/dnd/dragAndDropUtils';

// Components
import listDoubleClickShortcutDecorator from './listDoubleClickShortcutDecorator';
import ListPresentational from './ListPresentational';
import { listDropLocationFn } from './listDropTargetDecorator';
import listElementManager from './listElementManager';
import { getTimestamp } from '../../common/utils/timeUtil';
import measurementsRegistry from '../components/measurementsStore/measurementsRegistry';
import ElementDropTarget from '../element/dnd/elementDropTargets/ElementDropTarget';
import { ElementType } from '../../common/elements/elementTypes';
import classNames from 'classnames';

const nowOverShallow = now('isOverShallow');

const findScrollableAncestors = (domElement) => {
    const ancestors = getDomElementAncestors(domElement);
    return ancestors.filter(canDomElementBecomeVerticallyScrollable);
};

const listDropTargetConfig = {
    drop: listDropLocationFn,
    canDrop: (props, monitor) => {
        const { listCanDropFn, permissions } = props;
        const item = monitor.getItem();
        const itemType = monitor.getItemType();

        const elementType = itemType === NativeTypes.FILE ? ElementType.FILE_TYPE : item?.element;

        const requiredPermissions = getRequiredSavePermission(elementType);
        return listCanDropFn(props, monitor) && hasPermission(requiredPermissions, permissions);
    },
    acceptDropTypes: NATIVE_TYPES,
    hover: listDropHoverFn,
    hoverType: DROP_TARGET_TYPES.LIST,
    collect: (monitor) => ({
        isHovered: monitor.canDrop() && monitor.isOver({ shallow: true }),
        isHoveredDeep: monitor.canDrop() && monitor.isOver({ shallow: false }),
        isOverShallow: monitor.isOver({ shallow: true }),
    }),
};

@listElementManager
@branch(({ enableDoubleClick = true }) => enableDoubleClick, listDoubleClickShortcutDecorator, identity)
@ElementDropTarget(listDropTargetConfig)
class ListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.offsetTop = null;
        this.offsetTopCacheTime = 0;
        this.boundingRectTop = null;
        this.boundingRectTopCacheTime = 0;

        this.state = {
            hoveredIndex: null,
            // Drag and Drop - Shallow hover hack
            // This tracks whether a hovered child drop target can actually accept the drop
            //  This is important because react-dnd returns "false" for monitor.isOver({ shallow: true }) even
            //  if the hovered child can't accept the drop.
            //  This would mean that the drag preview wouldn't get shown in this list, even though it's going
            //  to handle the drop
            hoveredChildAcceptsDrop: false,
        };
    }

    componentDidMount() {
        // Doing it this way because React DnD messes around with refs on the wrapped element
        this.listContainerElement = ReactDOM.findDOMNode(this);

        // Attach scroll listeners to clear the offsetTop and boundingRectTop properties on scroll
        this.scrollableElements = findScrollableAncestors(this.listContainerElement);
        this.scrollableElements.forEach((element) => {
            element && element.addEventListener('scroll', this.debouncedOnScroll);
        });

        // hack to make these functions visible to listDropHoverFn - see listDropHoverFn.js for more details
        // (optional, as it's not used by the decorated drop target, just the hooks one)
        const { setHoverCallbacks } = this.props;
        setHoverCallbacks?.({
            getOffsetTop: this.getOffsetTop,
            getBoundingRectTop: this.getBoundingRectTop,
            setHoveredIndex: this.setHoveredIndex,
            getHoveredIndex: this.getHoveredIndex,
        });
    }

    componentWillReceiveProps(nextProps) {
        const { setParentHoveredChildAcceptsDrop, isHovered } = nextProps;

        // Drag and Drop - Shallow hover hack
        // If this list is now shallowly hovered, tell a parent list whether this list will accept the drop or not
        if (setParentHoveredChildAcceptsDrop && nowOverShallow(this.props, nextProps)) {
            setParentHoveredChildAcceptsDrop(isHovered);
        }
    }

    componentDidUpdate(prevProps) {
        const { childElementIds } = this.props;

        if (!childElementIds || !prevProps.childElementIds) return;

        // Force a re-measure when the childElementIds change, but the number hasn't changed.
        // This will be a re-ordering of elements, so we force a re-measure which will in turn
        // re-measure all child elements.
        // NOTE: This was required once listIndex was removed from the list API for performance reasons.
        const forceReMeasure =
            childElementIds !== prevProps.childElementIds &&
            childElementIds.length === prevProps.childElementIds.length;

        if (forceReMeasure) {
            childElementIds.forEach((elId) => measurementsRegistry.forceElementMeasure(elId));
        }
    }

    componentWillUnmount() {
        this.scrollableElements.forEach((element) => {
            element && element.removeEventListener('scroll', this.debouncedOnScroll);
        });
    }

    onScroll = () => {
        this.boundingRectTop = null;
    };

    debouncedOnScroll = debounce(this.onScroll, 50); // eslint-disable-line react/sort-comp

    // This becomes out of date on scroll or if the element size changes.
    // The element's size won't change during a drag, so the dragStartTimestamp is used to set a minimum
    // age of the cache to ensure that it's kept up to date on each drag (but not recalculated on each hover).
    getBoundingRectTop = (minAge = Infinity) => {
        if (!this.boundingRectTop || this.boundingRectTopCacheTime < minAge) {
            this.boundingRectTop = this.listContainerElement.getBoundingClientRect().top;
            this.boundingRectTopCacheTime = getTimestamp();
        }

        return this.boundingRectTop;
    };

    // This becomes out of date when the element measurements change.
    // The element's size won't change during a drag, so the dragStartTimestamp is used to set a minimum
    // age of the cache to ensure that it's kept up to date on each drag (but not recalculated on each hover).
    getOffsetTop = (minAge = Infinity) => {
        if (this.offsetTop === null || this.offsetTopCacheTime < minAge) {
            // sometimes we need to add padding to the list component to make it
            // receive hover events in the correct way. (see Column.scss)
            // This messes up the offset values working out the hovered index.
            // Here, we work out how much padding is applied to the list,
            // so we can remove it from the calculated hoverY when dragging over the list
            const domNode = this.listContainerElement;

            const paddingTop = window.getComputedStyle(domNode) && window.getComputedStyle(domNode).paddingTop;

            this.offsetTop = paddingTop ? parseInt(paddingTop, 10) : 0;
            this.offsetTopCacheTime = getTimestamp();
        }

        return this.offsetTop;
    };

    getHoveredIndex = () => this.state.hoveredIndex;
    setHoveredIndex = (hoveredIndex) => this.setState({ hoveredIndex });

    /**
     * Drag and Drop - Shallow hover hack
     * React-dnd doesn't handle shallow hovers very well for our scenarios.
     * We want to know when an element is the hovered element that's going to accept the drop.
     * Unfortunately React-dnd only tells us whether it's the top element, regardless of whether it can accept
     * the drop or not.
     * So this function sets state and passes state to parent elements to let them know whether a child of theirs
     * is hovered and is going to handle the drop.
     */
    setHoveredChildAcceptsDrop = (hoveredChildAcceptsDrop) => {
        const { isHoveredDeep, setParentHoveredChildAcceptsDrop } = this.props;

        this.setState({ hoveredChildAcceptsDrop });

        const willAcceptDrop = isHoveredDeep && !hoveredChildAcceptsDrop;

        // The parent list will have a hovered child that accepts the drop, if this list accepts the drop
        // or a descendant of this list accepts the drop
        setParentHoveredChildAcceptsDrop && setParentHoveredChildAcceptsDrop(willAcceptDrop || hoveredChildAcceptsDrop);
    };

    /**
     * This function is used to prevent unnecessary prop updates when creating new elements
     * in lists.
     * The listIndex is currently only required when creating new elements, so we can explicitly
     * request the list index in these moments, rather than passing down the prop.
     */
    getListIndex = (elementId) => {
        const { childElementIds } = this.props;

        if (!childElementIds) return 0;

        return childElementIds.indexOf(elementId);
    };

    render() {
        const {
            listContainerRef,
            connectDropTarget,
            inListClass,
            onDoubleClick,
            onMouseDown,
            onMouseUp,
            isAttachMode,
        } = this.props;
        const { hoveredIndex, hoveredChildAcceptsDrop } = this.state;

        // never show the drop indicator in attach mode - we're attaching the
        // element to something else, not actually inserting it into the column
        const displayHoveredIndex = isAttachMode ? null : hoveredIndex;

        return (
            <div
                className={classNames('ListContainer', { 'is-empty': this.props.childElementIds.length === 0 })}
                // Long press delay on iPads
                data-long-press-delay="500"
                ref={multiRef([listContainerRef, connectDropTarget])}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onDoubleClick={onDoubleClick}
            >
                <ListPresentational
                    {...this.props}
                    getListIndex={this.getListIndex}
                    setParentHoveredChildAcceptsDrop={this.setHoveredChildAcceptsDrop}
                    inListClass={inListClass}
                    hoveredIndex={displayHoveredIndex}
                    hoveredChildAcceptsDrop={hoveredChildAcceptsDrop}
                />
            </div>
        );
    }
}

ListContainer.propTypes = {
    listId: PropTypes.string,
    childElementIds: PropTypes.array.isRequired,
    currentBoardId: PropTypes.string,
    connectDropTarget: PropTypes.func,
    isHovered: PropTypes.bool,
    isHoveredDeep: PropTypes.bool,
    isAttachMode: PropTypes.bool,
    createShortcutCard: PropTypes.func,
    inListClass: PropTypes.string,
    isEditable: PropTypes.bool,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    enableDoubleClick: PropTypes.bool,
    onDoubleClick: PropTypes.func,
    setHoverCallbacks: PropTypes.func,

    setParentHoveredChildAcceptsDrop: PropTypes.func,
    listContainerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default ListContainer;
