// Types
import { Action } from 'redux';
import { BaseVisibilityState } from './baseVisibleConnectionState';
import { SOCKET_CONNECT } from '../../utils/socket/socketConstants';
import { VisibleConnectionStatus } from './visibleConnectionStatusTypes';
import { LOGIN_SUCCESS, REGISTER_SUCCESS } from '../../auth/authConstants';

/**
 * The socket isn't connected - a "reconnecting" overlay & spinner will be shown.
 */
export class DisconnectedState extends BaseVisibilityState {
    static stateKey = VisibleConnectionStatus.disconnected;

    nextState(action: Action) {
        switch (action.type) {
            case SOCKET_CONNECT:
                return VisibleConnectionStatus.connected;
            case LOGIN_SUCCESS:
            case REGISTER_SUCCESS:
                return VisibleConnectionStatus.connecting;
            default:
                return undefined;
        }
    }
}
