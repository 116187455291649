import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import randomString from '../../../common/uid/randomString';
import { showTooltip, hideTooltip } from './tooltipActions';
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import { TooltipPositions } from './tooltipConstants';

// Styles
import './Tooltip.scss';

type TooltipSourceProps = {
    enabled?: boolean;
    className?: string;
    tooltipText?: React.ReactNode | React.ReactNode[];
    shortcut?: string[];
    position?: string;
    hideOnEvent?: string;
    delay?: number;
    forceDelay?: boolean;
    distance?: number;
    offset?: number;
    duration?: number;
    arrowOffset?: number;
    fade?: boolean;
    triggerOnMouseEnter?: boolean;
    keepOnMouseLeave?: boolean;
    triggerOnClick?: boolean;
    triggerOnDrag?: boolean;
    pollPosition?: boolean;
    children?: React.ReactNode;
    getTargetRectFn?: () => void;
};

const getActiveTooltipId = (state: any) => state.getIn(['app', 'tooltip', 'id']);
const getTooltipActiveTimestamp = (state: any) => state.getIn(['app', 'tooltip', 'activeTimestamp']);

const TooltipSource = ({
    enabled,
    className,
    children,
    tooltipText,
    triggerOnMouseEnter,
    keepOnMouseLeave,
    triggerOnClick,
    triggerOnDrag,
    shortcut,
    position,
    delay,
    forceDelay,
    distance,
    offset,
    duration,
    arrowOffset,
    fade,
    pollPosition,
    hideOnEvent,
    getTargetRectFn,
}: TooltipSourceProps) => {
    const stableTooltipId = useRef(`tip-${randomString(6)}`);

    const dispatch = useDispatch();

    const activeTooltipId = useSelector(getActiveTooltipId);
    const tooltipActiveTimestamp = useSelector(getTooltipActiveTimestamp);
    const activeTooltipIdRef = useRef(activeTooltipId);
    useEffect(() => {
        activeTooltipIdRef.current = activeTooltipId;
    }, [activeTooltipId]);

    const handleShowTooltip = () => {
        if (!enabled) return;

        // Ensure the delay has passed before considering a tooltip active
        const alreadyActive = !!activeTooltipId && getTimestamp() >= tooltipActiveTimestamp;
        const shouldDelay = forceDelay || (!alreadyActive && delay);

        dispatch(
            showTooltip({
                id: stableTooltipId.current,
                tooltip: {
                    selector: `#${stableTooltipId.current}`,
                    text: tooltipText,
                    shortcut,
                    position: position || TooltipPositions.BOTTOM,
                    distance,
                    offset,
                    duration,
                    arrowOffset,
                    fade,
                    className: classNames('Tooltip', 'small', className),
                    // Only delay the next tooltip if a tooltip isn't already active
                    delay: shouldDelay ? delay : 0,
                    targetRect: getTargetRectFn && getTargetRectFn(),
                    pollPosition,
                    hideOnEvent,
                },
            }),
        );
    };

    const handleHideTooltip = () => {
        if (activeTooltipIdRef.current !== stableTooltipId.current) return;
        dispatch(hideTooltip());
    };

    // if the tooltip source becomes disabled, hide the tooltip
    useEffect(() => {
        if (enabled) return;
        handleHideTooltip();
    }, [enabled]);

    // on unmount, hide the tooltip
    useEffect(() => {
        return () => {
            handleHideTooltip();
        };
    }, []);

    const eventHandlers: Partial<React.DOMAttributes<HTMLElement>> = {
        onClick: triggerOnClick ? handleShowTooltip : undefined,
        onPointerDown: activeTooltipId ? handleHideTooltip : undefined,
        onPointerEnter: triggerOnMouseEnter ? handleShowTooltip : undefined,
        onPointerLeave: triggerOnMouseEnter && !keepOnMouseLeave ? handleHideTooltip : undefined,
        onDragStart: triggerOnDrag ? handleShowTooltip : handleHideTooltip,
        onScroll: handleHideTooltip,
    };

    return (
        <div
            className={classNames('TooltipSource', className)}
            id={stableTooltipId.current}
            draggable={true}
            {...eventHandlers}
        >
            {children}
        </div>
    );
};

export default TooltipSource;
